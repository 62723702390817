import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

const SimpleModal = ({ open, handleClose, title, children }) => {
  // Function to prevent modal close if not desired
  const handleOnClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      handleClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleOnClose}>
      {/* <DialogTitle>{title}</DialogTitle> */}
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions> */}
    </Dialog>
  );
};

export default SimpleModal;
