import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActionArea,
  Box,
  CardMedia,
  Typography,
  Container,
  CircularProgress,
} from "@mui/material";

import './landing.css';

import useContentful from "../../contentful/useContentful";
import Navbar from "./Navbar";
import { Navigate, useNavigate } from "react-router-dom";

const Articles = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const { getAllArticles } = useContentful();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticles = async () => {
      setLoading(true);
      const allArticles = await getAllArticles();
      setArticles(allArticles);
      setLoading(false);
    };

    fetchArticles();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  const handleArticleClick = (id) => {
    navigate(`/article/${id}`);
  };

  const renderThumbnail = (article) => {

    let mediaUrl;
    const mediaType = article.media.fields.file.contentType;

    if (mediaType.startsWith("video")) {
      mediaUrl = article.thumbnail.fields.file.url;

    } else if (mediaType.startsWith("image")) {
      mediaUrl = article.media.fields.file.url;
    }

    return (
      <CardMedia
        component="img"
        sx={{maxWidth: 300, minWidth: 300}}
        image={mediaUrl}
        alt={article.title}
      />
    );
  };

  return (
    <Box sx={{ flex: "1 0 auto", pt: 4, minHeight: "calc(100vh - 24px)", background: "linear-gradient(to bottom, black, #074e70)" }}>
      <Navbar />

      <Container>
        <Typography
          variant="h3"
          component="h2"
          gutterBottom
          align="center"
          sx={{ color: "white", fontFamily: 'Open Sans, sans-serif' }}
        >
          Articles
        </Typography>

        <Grid container spacing={0.5}>
          {articles.length > 0 ? (
            articles.map((article, index) => (
              <Grid item xs={12} key={index}>
                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: '#2A2A38',
                    transition: 'transform 0.1s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.0125)',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
                      cursor: 'pointer'
                    },
                    height: '150px'
                  }}
                  onClick={() => handleArticleClick(article.id)}
                >
                  {renderThumbnail(article)}

                  <CardContent sx={{ display: 'flex', flexDirection: 'column', p: 1, width: '100%' }}>
                    <Typography variant="h6" component="h3" gutterBottom sx={{ textAlign: 'left', color: '#ffffff', pt: 0, fontFamily: 'Open Sans, sans-serif', lineHeight: 1.2 }}>
                      {article.title}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1, color: '#A4A4C4', textAlign: 'left', fontFamily: 'Open Sans, sans-serif' }}>
                      {article.media.sys.createdAt.split("T")[0]}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#ffffff', textAlign: 'left', fontFamily: 'Open Sans, sans-serif' }}>
                      {article.abstract.content[0].content[0].value}
                    </Typography>
                  </CardContent>

                </Card>
              </Grid>
            ))
          ) : (
            <Typography variant="h6" color="textSecondary" align="center">
              No articles available.
            </Typography>
          )}
        </Grid>

      </Container>
    </Box>
  );
};

export default Articles;
