import React from "react";
import { Container } from "@mui/material";
import UserProfilePanel from "./UserProfilePanel";

const UserProfile = ({ user }) => {
	return (
	  <Container>
		<UserProfilePanel user={user}/>
	  </Container>
	);
  };
  
  export default UserProfile;