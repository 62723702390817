import React, { useEffect, useState } from "react";
import { Container, CircularProgress } from "@mui/material";
import ProgressionPanel from "./ProgressionPanel"

import { useLocation, useParams } from "react-router-dom";
import { useAuth } from "../../AuthContext";

const apiKey = process.env.REACT_APP_APIGATEWAY_KEY;
const gateway = process.env.REACT_APP_APIGATEWAY_DEV;

const fetchProgressionData = async ({ user, jwtToken }) => {

  try {
    //console.log("trying")
    const endpoint = `${gateway}dynamo-get-user-progression`;
    const input = { userId: user.username };


    //console.log("Username:", user.username)

    const response = await fetch(endpoint, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "x-api-key": apiKey,
        "Authorization": jwtToken
       },
      body: JSON.stringify(input),
    });
    const data = await response.json();
    //console.log("data",data);
    return JSON.parse(data.body);

  } catch (error) {
    //console.log(error);
    return {};
  }
};


const Progression = ({ user }) => {
  const location = useLocation();
  const {jwtToken} = useAuth();

  // Set selected word if study panel has been accessed from the home page word list
  let selectedWord = null;
  try {
    const { selectedWordState } = location.state;
    selectedWord = selectedWordState;
  } catch {
    //console.log("no state!");
  }


  const [progressionData, setProgressionData] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      const data = await fetchProgressionData({ user, jwtToken});
      setProgressionData(data);
    };
    if (user) {
      loadData();
    }
  }, [user]);

  return (
    <Container sx={{ height: '90vh' }}>
      {progressionData ? (
        <>
          <ProgressionPanel progressionData={progressionData} selectedWord={selectedWord} />
        </>
      ) : (
        <>
          <CircularProgress />
        </>
      )}
    </Container>
  );
};

export default Progression;