import React, { useState, useEffect } from "react";

import { Container, Grid } from "@mui/material";

import StudyGroup from "./StudyGroup";
import StudyResources from "./StudyResources";

import StudyToolbar from "./StudyToolbar";



export default function StudyPanel({ selectedWord }) {
  // Variables for selected session and resource
  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [selectedResourceId, setSelectedResourceId] = useState(null);

  const handleSelectSession = (sessionId) => {
    if (sessionId == selectedSessionId) {
      setSelectedSessionId("none");
    } else {
      setSelectedSessionId(sessionId);
    }
    setSelectedResourceId(null);
  }

  const handleSelectResource = (resourceId, index) => {
    if (resourceId == selectedResourceId) {
      setSelectedResourceId(null);
    } else {
      setSelectedResourceId(resourceId);
    }
  }

  // variables for grouping and sorting in toolbar
  const [group, setGroup] = useState('session');
  const [sort, setSort] = useState('date');

  const handleGroupChange = (event) => {
    setGroup(event.target.value);
    setSelectedSessionId("none");
    setSelectedResourceId(null);

    // Only sort by date if no grouping
    if (event.target.value == "none") {
      setSort("date");
    }
  }

  const handleSortChange = (event) => {
    setSort(event.target.value);
  }

  const [readyToFetch, setReadyToFetch] = useState(false);
  useEffect(() => {
    // Check if selectedWord has some value
    if (selectedWord) {
      setGroup('word');
      setSelectedSessionId(selectedWord);
      setReadyToFetch(true);
    } else {
      //console.log("fetch")
      // If selectedWord is null, we want to immediately allow fetching
      setReadyToFetch(true);
    }
  }, [selectedWord]);

  return (
    <Container>
      <StudyToolbar group={group} handleGroupChange={handleGroupChange} sort={sort} handleSortChange={handleSortChange} />
      {group != "none" ? (
        <Grid container direction="row" spacing={1} sx={{mb:-5}}>
          <Grid item xs={6}>
            <StudyGroup selectedSessionId={selectedSessionId} handleSelectSession={handleSelectSession} group={group} sort={sort} readyToFetch={readyToFetch} />
          </Grid>

          <Grid item xs={6}>
            <StudyResources selectedSessionId={selectedSessionId} selectedResourceId={selectedResourceId} group={group} handleSelectResource={handleSelectResource} handleGroupChange={handleGroupChange} handleSelectSession={handleSelectSession} />
          </Grid>
        </Grid>
      ) : (
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <StudyResources selectedSessionId={selectedSessionId} selectedResourceId={selectedResourceId} group={group} handleSelectResource={handleSelectResource} handleGroupChange={handleGroupChange} handleSelectSession={handleSelectSession} />
          </Grid>
        </Grid>
      )}

    </Container>
  );
}