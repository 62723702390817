import React, { useState, useEffect } from "react";

import "reactjs-popup/dist/index.css";
import UserWord from "./UserWord";

// Styling

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Container, IconButton } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { CssBaseline } from "@mui/material";
import { TextField, Button, Box, Stack, Typography } from "@mui/material";

import { API, graphqlOperation } from "aws-amplify";
import { listUserWords, getUserWord } from "../../graphql/queries";

// > Icons
import AddBoxIcon from "@mui/icons-material/AddBox";
import AddUserWord from "./AddUserWord";



const defaultTheme = createTheme();

const UserWords = ({ user }) => {
  const [words, setWords] = useState([]);

  const details = {
    userId: user.username,
    //wordId: "bb036127-e00e-4255-8550-8e6f48723b0c",
    //wordStr: ""
  };

  useEffect(() => {
    fetchUserWords();
  }, []);

  const fetchUserWords = async () => {
    try {
      /*
      const wordData = await API.graphql(
        graphqlOperation(listUserWords, {
          filter: {
            userId: {
              eq: user.username,
            },
          },
        })
      );
      */

      /*
      const getUserWord_data = await API.graphql(
        graphqlOperation(getUserWord, details)
      );
      */

      const listUserWords_data = await API.graphql(
        graphqlOperation(listUserWords, {
          filter: {
            userId: {
              // eq: user.username,
              eq: "53997e18-2ccb-46cd-af12-819f60e88681",
            },
          },
        })
      );

      ////console.log("data r specific user word = >", getUserWord_data);
      ////console.log("data r list of user words = >", listUserWords_data);
      const wordList = listUserWords_data.data.listUserWords.items; // adjust based on your actual query structure
      ////console.log("inside try, word list = ", wordList);
      setWords(wordList);
    } catch (error) {
      console.error("Error fetching words", error);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />

      {/* <Container component="main" fullWidth sx={{border:2}}> */}
        <Box
          sx={{
            // Spacing
            p: 2,
            //marginTop: 3,

            // Display
            //display: "flex",
            //flexDirection: "row",
            //alignItems: "center",

            // Styling
            //border: 1,
            //borderRadius: 3,
            //bgcolor: "#f2f2f2",
          }}
        >
          <Grid container spacing={2} sx={{ border: 0 }}>
            <Grid xs={8}>
              <TextField
                //margin="normal"
                //required
                fullWidth
                //autoFocus
                id="search"
                label="Search"
                //value={email}
                //onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>

            <Grid xs={1}>
              <Button variant="contained" color="success" fullWidth sx={{minHeight:'56px', maxHeight:'100px'}}>
                Go!
              </Button>
            </Grid>

            <Grid xs={2}></Grid>

            <Grid xs={1}>
              <Button variant="contained" fullWidth sx={{minHeight:'56px', maxHeight:'100px'}}>
                Sort
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ border: 0 }}>
            {words.map((word, index) => (
              <Grid xs={3}>
                <UserWord userWord={word} />
              </Grid>
            ))}
          </Grid>
        </Box>
      {/* </Container> */}
    </ThemeProvider>
  );
};
export default UserWords;

/*
-- originally used this instead of grid
            <Stack spacing={2} direction="column">
              {words.map((word, index) => (
                <UserWord userWord={word} />
              ))}
            </Stack>

   */
