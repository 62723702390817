import React from "react";
import { Tooltip, IconButton, ListItemIcon, AppBar, Toolbar, Typography, Radio, RadioGroup, FormControlLabel, FormControl, Checkbox, FormGroup, Grid, Paper, Box, Slider } from '@mui/material';
import { format } from 'date-fns';

import { FaInfo } from "react-icons/fa";
import { MdAutoGraph } from "react-icons/md";

import { useTranslation } from "../../display_languages/UseTranslation";

const ProgressionToolbar = ({ allTopics, topic, handleTopicChange, timeRange, handleTimeRangeChange, customTimeRangeMin, customTimeRangeMax, customTimeRange, handleCustomTimeRangeChange, handleCustomTimeRangeCommittedChange }) => {
    const t = useTranslation();
    
    // Convert timestamp to readable date format
    const formatDate = (timestamp) => {
        return format(new Date(timestamp), 'yyyy-MM-dd');
    };

    return (
        <Box sx={{ flexGrow: 1, mb: 13.5 }}>
            <AppBar position="fixed" sx={{ "backgroundColor": "#ffffff", "boxShadow": 2, pl: '300px' }}>
                <Toolbar>
                    <Grid container direction="column" sx={{ width: '100%' }}>
                        {/* Title */}
                        <Grid item>
                            <Grid container alignItems="center" justifyContent="space-between" sx={{ mb: -3 }}>
                                {/* Left empty grid item for balance (optional) */}
                                <Grid item xs={false} sm={3} />

                                {/* Center content: Icon and Typography */}
                                <Grid item xs={6} sm={6} container alignItems="center" justifyContent="center">
                                    <ListItemIcon sx={{ fontSize: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <MdAutoGraph />
                                    </ListItemIcon>
                                    <Typography variant="h5" sx={{ color: 'black' }}>
                                        {t.progression?.title}
                                    </Typography>
                                </Grid>

                                {/* Right content: Info Icon */}
                                <Grid item xs={6} sm={3} container justifyContent="flex-end">
                                    <Tooltip title={t.progression?.info}>
                                        <IconButton sx={{ "&:hover": { color: "#1976d2" } }}>
                                            <FaInfo />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Control */}
                        <Grid item>
                            <Grid container spacing={0} justifyContent="center" direction="column" sx={{ width: '100%', py: 0, px: 2, pl: 'calc(50vw - 570px)' }}>
                                {/* Time range control */}
                                <Grid item container alignItems="center">
                                    <Box sx={{ minWidth: 120, textAlign: 'left' }}>
                                        <Typography variant="h6" sx={{ mr: 2, color: 'black', fontSize:18 }}>{t.progression?.timerange}</Typography>
                                    </Box>

                                    <Grid item xs>
                                        <FormControl component="fieldset" fullWidth>
                                            <RadioGroup row value={timeRange} onChange={handleTimeRangeChange}>
                                                <FormControlLabel sx={{ color: 'black' }} value="day" control={<Radio size="small"/>} label={t.progression?.timerange_day} />
                                                <FormControlLabel sx={{ color: 'black' }} value="week" control={<Radio size="small"/>} label={t.progression?.timerange_week} />
                                                <FormControlLabel sx={{ color: 'black' }} value="month" control={<Radio size="small"/>} label={t.progression?.timerange_month} />
                                                <FormControlLabel sx={{ color: 'black' }} value="year" control={<Radio size="small"/>} label={t.progression?.timerange_year} />
                                                <FormControlLabel sx={{ color: 'black', mr: 6 }} value="alltime" control={<Radio size="small"/>} label={t.progression?.timerange_alltime} />
                                                <FormControlLabel sx={{ color: 'black' }} value="custom" control={<Radio size="small"/>} label={t.progression?.timerange_custom} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box width="85%" maxWidth={750} pl={16} pr={0} mt={-0.75} mb={-1}>
                                            <Slider
                                                size="small"
                                                min={customTimeRangeMin.getTime()}
                                                max={customTimeRangeMax.getTime()}
                                                value={customTimeRange}
                                                onChange={handleCustomTimeRangeChange}
                                                onChangeCommitted={handleCustomTimeRangeCommittedChange}
                                                valueLabelDisplay="auto"
                                                valueLabelFormat={formatDate}
                                                step={24 * 60 * 60 * 1000}
                                                disabled={timeRange !== 'custom'}
                                                sx={{ p: 0, m: 0, mb: 1, }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>

                                {/* Topic control */}
                                <Grid item container alignItems="center">
                                    <Box sx={{ minWidth: 120, textAlign: 'left' }}>
                                        <Typography variant="h6" sx={{ color: 'black', mr: 2, fontSize:18 }}>{t.progression?.topics}</Typography>
                                    </Box>

                                    <FormGroup row>
                                        {allTopics.map((title) => (
                                            <FormControlLabel
                                                key={title}
                                                sx={{ color: 'black' }}
                                                control={
                                                    <Checkbox
                                                        checked={topic[title]}
                                                        onChange={handleTopicChange}
                                                        name={title}
                                                        size="small"
                                                    />
                                                }
                                                label={title}
                                            />
                                        ))}
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
export default ProgressionToolbar;