import React from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

// Import the useLanguage hook from the LanguageContext
import { useLanguage } from './LanguageContext';
import { useTranslation } from "./UseTranslation";


import en from "../media/images/uk.png";
import jp from "../media/images/jp.png";
import pl from "../media/images/pl.png";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const icons = {
    "en": en,
    "jp": jp,
    "pl": pl
};

export function DefinitionIcon({ language }) {
    return (
        <Box sx={{ display: 'flex', mb: 0.5, alignItems: 'center', justifyContent: 'right', mr: 1 }}>
            <img src={en} style={{ width: 18, height: 18, marginRight: 4 }} />
            <ArrowForwardIosIcon style={{ width: 12, height: 12, marginRight: 4 }} />
            <img src={icons[language]} style={{ width: 18, height: 18, marginRight: 4 }} />
        </Box>
    );
}

export function LanguageList({ handleLanguageChange, title, disabled, value }) {
    const t = useTranslation();
    const languageContext = useLanguage(); // Use the hook to get the context object

    const languages = [
        { code: 'en', name: t.language?.en, Icon: en },
        { code: 'jp', name: t.language?.jp, Icon: jp },
        { code: 'pl', name: t.language?.pl, Icon: pl },
    ];

    // Custom render function for the selected value
    const renderValue = (selectedValue) => {
        const selectedLanguage = languages.find(lang => lang.code === selectedValue);
        const Icon = selectedLanguage ? selectedLanguage.Icon : null;
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {Icon && <img src={Icon} alt={selectedLanguage ? selectedLanguage.name : ''} style={{ width: 24, height: 24, marginRight: 8 }} />}
                <Typography sx={{ ml: 1 }}>{selectedLanguage ? selectedLanguage.name : ''}</Typography>
            </Box>
        );
    };

    return (
        <FormControl fullWidth disabled={disabled}>
            <InputLabel>{title}</InputLabel>
            <Select
                value={value}
                label={t.profile_display_language}
                onChange={handleLanguageChange}
                renderValue={renderValue}
            >
                {languages.map(({ code, name, Icon }) => (
                    <MenuItem key={code} value={code}>
                        <ListItemIcon>
                            <img src={Icon} alt={name} style={{ width: 24, height: 24 }} />
                        </ListItemIcon>
                        <ListItemText primary={name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}