import React from "react";
import { useState, useEffect } from "react";

import { Box, Grid, Tooltip, Typography, Paper, Button, Slider, Switch, FormControlLabel } from "@mui/material";

import FormatSizeIcon from '@mui/icons-material/FormatSize';
import PsychologyIcon from '@mui/icons-material/Psychology';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';

import TopicData from "../../components/c_Topic/TopicData";

import { useTranslation } from "../../display_languages/UseTranslation";

const Session_Inference = ({ selectedResource, userWordData, selectedWord, updateSelectedWord, wordsForgot, wordsChecked, calculateColour }) => {
  const t = useTranslation();

  const topicData = TopicData("id", selectedResource.topic);

  const [fontSize, setFontSize] = useState(20);
  const [underlineToggle, setUnderlineToggle] = useState(true);
  const [scorelexToggle, setScorelexToggle] = useState(true);

  // This function constructs the full text from the keys of the attributes
  const renderTextFromAttributes = () => {

    return selectedResource.words.map((word, index) => {
      const wordData = userWordData[word.key]; // Access the data of the word

      const wordKnown = wordData.knowledge; // Determine if the word is known
      const wordForgot = wordsForgot.includes(word.key); // Determine if the word is forgotten
      const wordChecked = wordsChecked.includes(word.key); // Determine if the word is checked

      // Highlight words if toggled
      let underline = "none";
      if(underlineToggle && (!wordKnown || wordForgot || wordChecked )){
        underline="underline";
      }
      let underlineColour = "#FB696A"; // Red underline for forgotten words
      if(!wordKnown){
        underlineColour= "#6CCC8D"; // Green underline for new words
      } else if(wordChecked){
        underlineColour= "#FFB766"; // Yellow underline for checked words
      }

      let backgroundColour= "#FFFFFF";
      let boxShadow = "0";
      let scale = "scale(1)";;
      let zIndex = 1;
      if(word.key==selectedWord){
        backgroundColour= "#F5F8FA";
        boxShadow = "0 2px 2px rgba(0,0,0,0.2)";
        scale = "scale(1.1)";
        zIndex = 1000;
      }

      return (
        <React.Fragment key={index}>
          <Button
            onClick={() => updateSelectedWord(word.key)}
            sx={{
              textTransform: "none",
              minWidth: "auto",
              padding: "0 4px",
              margin: "0 0px",
              lineHeight: "40px",
              cursor: "pointer",
              transition:
                "transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out",
              "&:hover": {
                zIndex: 1000,
                transform: "scale(1.1)",
                boxShadow: "0 2px 2px rgba(0,0,0,0.2)",
                bgcolor: "#F5F8FA",
                textDecoration: underline,
                textDecorationColor: underlineColour,
                textDecorationThickness: `${fontSize/6}px`,
              },
              zIndex: zIndex,
              transform: scale,
              bgcolor: backgroundColour,
              boxShadow: boxShadow,
              color: scorelexToggle ? calculateColour(wordData, selectedResource) : 'rgb(0,0,0)',
              fontSize: `${fontSize}px`,
              textDecoration: underline,
              textDecorationColor: underlineColour,
              textDecorationThickness: `${fontSize/6}px`,
            }}
          >
            {word.display.trim()}
          </Button>{" "}
        </React.Fragment>
      );
    });
  };


  return (
    <React.Fragment>
      <Paper elevation={2} sx={{ p: 2, mb: 1 }}>
        {/* Image */}
        <img
          src={topicData.image}
          style={{
            float: "left",
            marginRight: "20px",
            width: "100px",
            height: "auto",
          }}
        />

        {/* Title */}
        <Typography variant="h5" sx={{ fontWeight: "bold", textAlign: "left" }}>
          {selectedResource.title}
        </Typography>

        {/* Topic */}
        <Typography variant="h6" sx={{ textAlign: "left", mb: 1, color: '#666666' }}>
          {topicData.name}
        </Typography>

        {/* Body */}
        <Box sx={{ textAlign: "justify", textIndent: "0px" }}>
          {renderTextFromAttributes()}
        </Box>
      </Paper>

      <Paper elevation={2} sx={{ py: 1, px: '5vw' }}>
        <Grid container spacing={2} alignItems="center">
          {/* Text Size Slider */}
          <Grid item xs={8}>
            <Tooltip title={t.session?.textsize} arrow placement="bottom">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormatSizeIcon />
                <Slider
                  sx={{ mx: '1vw' }}
                  aria-label="Font Size"
                  value={fontSize}
                  onChange={(event, newValue) => setFontSize(newValue)}
                  min={16}
                  max={36}
                />
              </Box>
            </Tooltip>
          </Grid>

          {/* Unknown Words Underline */}
          <Grid item xs={2}>
            <Tooltip title={t.session?.highlight} arrow placement="bottom">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormatUnderlinedIcon />
                <FormControlLabel
                  control={
                    <Switch
                      sx={{ mx: '0.5vw' }}
                      checked={underlineToggle}
                      onChange={(event) => setUnderlineToggle(event.target.checked)}
                    />
                  }
                />
              </Box>
            </Tooltip>
          </Grid>

          {/* Scorelex Underline */}
          <Grid item xs={2}>
            <Tooltip title={t.session?.colour} arrow placement="bottom">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <PsychologyIcon />
                <FormControlLabel
                  control={
                    <Switch
                      sx={{ mx: '0.5vw' }}
                      checked={scorelexToggle}
                      onChange={(event) => setScorelexToggle(event.target.checked)}
                    />
                  }
                />
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </Paper>

    </React.Fragment >
  );
};

export default Session_Inference;