import React, { useEffect } from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Button, Container, Typography } from "@mui/material";

import { Link } from 'react-router-dom';
import { ReactComponent as VocabuaLogo } from '../../media/images/vocabua-white.svg'

import './SplashPanel.css';
import '../../styling/bodywidth.css'

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    white: {
      main: '#FFFFFF',
      light: '#FFFFFF',
      dark: '#CFECF5',
      contrastText: '#57537F',
    },
  },
});

export default function SplashPanel() {
  React.useEffect(() => {
    document.body.style.background = "url('/vocabua-background.png')";
    document.body.style.backgroundSize = "cover";
    // Cleanup function to reset the background color when the component unmounts
    return () => {
      document.body.style.background = "";
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container className="splash-container">
        <VocabuaLogo className="logo" />

        <Typography variant="h2" className="title" gutterBottom>
          <b>acquire language</b>
        </Typography>
        <Typography variant="h5" className="subtitle" gutterBottom>
          trusted by 0 learners worldwide
        </Typography>

        <Link
          to="/signin_register"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Button
            color="white"
            type="submit"
            fullWidth
            variant="contained"
            className="splash-button"
          >
            Get Started
          </Button>
        </Link>
      </Container>
    </ThemeProvider>
  );
}

