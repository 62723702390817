import React from 'react';
import { Box, Container, Grid, Card, CardContent, CardActionArea, CardMedia, Typography, useMediaQuery, useTheme } from '@mui/material';

import { useNavigate } from "react-router-dom";

import './landing.css';

import vocabuaLM from './vocabuaLM.png';
import progression from './progression.png';
import topics from './topics.png';
import scorelex from './scorelex.png';

const Features = () => {
  const navigate = useNavigate();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const features = [
    {
      title: 'VocabuaLM',
      description: 'Vocabua generates reading material with a custom language model which understands your learning needs. Your reading material will contain specifically the words you know and need to study.',
      image: vocabuaLM,
      colour: '#1e88e5',
    },
    {
      title: 'Scorelex',
      description: 'Knowledge level and study need of every word in your vocabulary is calculated with a custom algorithm, making sure you are spending your time practicing the right things.',
      image: scorelex,
      colour: '#03a9f4',
    },
    {
      title: 'Topics',
      description: 'Progress through various topics and get content tailored to your interests, language certifications, study subjects, or daily life. Every topic has new words to learn.',
      image: topics,
      colour: '#26c6da',
    },
    {
      title: 'Progression Analytics',
      description: 'View data on every word you\'ve in your vocabulary and watch your language proficiency grow over time.',
      image: progression,
      colour: '#72dabd',
    },
  ];

  const handleFeaturesClick = () => {
    navigate('/features');
  };

  return (
    <Box sx={{
      pb: 16,
      background: "linear-gradient(to bottom, #164874, #287293)"
    }}>
      <Container className="fadein4">

        {/* Title */}
        <Typography
          variant="h4"
          component="h4"
          gutterBottom
          align="center"
          onClick={() => handleFeaturesClick()}
          sx={{
            color: "white",
            fontFamily: 'Open Sans, sans-serif',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
        >
          Our Features
        </Typography>

        <Grid container spacing={2}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={8} md={6} lg={3} key={index}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: '#2A2A38',
                  transition: 'transform 0.1s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.0125)',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)'
                  }
                }}
              >
                <CardActionArea sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }} onClick={() => handleFeaturesClick()}>
                  <CardMedia
                    component="img"
                    height="140"
                    image={feature.image}
                  />
                  <CardContent sx={{ p: 0, m: 0, flexGrow: 1 }}>
                    <Typography variant="h6" component="h3" gutterBottom sx={{ color: feature.colour, pt: 0.5, fontFamily: 'Open Sans, sans-serif' }}>
                      <strong>{feature.title}</strong>
                    </Typography>
                    <Typography variant="body2" sx={{ px: 1, mb: 2, color: '#ffffff', textAlign: 'center', fontFamily: 'Open Sans, sans-serif' }}>
                      {feature.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Features;
