import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardMedia,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import useContentful from "../../contentful/useContentful";
import Navbar from "./Navbar";
import ContentRenderer from "./ContentRenderer";

import { Navigate, useNavigate } from "react-router-dom";

import './landing.css';

const Article = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const { getArticle } = useContentful();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticle = async () => {
      setLoading(true);
      const fetchedArticle = await getArticle({ articleId: id });
      setArticle(fetchedArticle);
      setLoading(false);
    };

    fetchArticle();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!article) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography variant="h6">Article not found</Typography>
      </Box>
    );
  }

  const handleBackClick = () => {
    navigate(`/articles`);
  };

  const renderMedia = () => {
    const mediaUrl = article.media.fields.file.url;
    const mediaType = article.media.fields.file.contentType;

    if (mediaType.startsWith("video")) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <video
            controls
            style={{ maxHeight: 600, width: "100%", objectFit: "cover" }}
          >
            <source src={mediaUrl} type={mediaType} />
            Your browser does not support the video tag.
          </video>
        </Box>
      );
    } else if (mediaType.startsWith("image")) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img
            src={mediaUrl}
            alt={article.title}
            style={{ maxHeight: 600, width: "100%", objectFit: "cover" }}
          />
        </Box>
      );
    }
    return null;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "calc(100vh - 24px)",
        width: '100vw',
        pt: 6,
        pb: 2,
        background: "linear-gradient(to bottom, black, #0fa8f1)",
        overflowX: 'hidden'
      }}
    >
      <Navbar />

      <Box sx={{ flex: "1 0 auto", maxWidth: 950, margin: "0 auto" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Button
            variant="text"
            startIcon={<ArrowBack />}
            onClick={handleBackClick}
            sx={{ color: "#ffffff" }}
          >
            All articles
          </Button>
        </Box>

        <Card>
          {/* Image */}
          {renderMedia()}

          {/* Title */}
          <Box sx={{ px: 2, pt: 0.5 }}>
            <Typography variant="h3" component="div" gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'left' }}>
              {article.title}
            </Typography>

            {/* Date */}
            <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'left', fontFamily: 'Open Sans, sans-serif', mt: -2, pb: 2 }}>
              {article.media.sys.createdAt.split("T")[0]}
            </Typography>

            {/* Subtitle */}
            <Typography variant="h6" color="text.secondary" gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'left', pb: 6, lineHeight: 1.7 }}>
              {article.abstract.content[0].content[0].value}
            </Typography>

            {/* Content */}
            <ContentRenderer content={article.body.content} />
          </Box>

        </Card>
      </Box>
    </Box>
  );
};

export default Article;