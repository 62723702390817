import { WorkDocs } from "aws-sdk";
import React from "react";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import { Container, Button, Box, Typography, CssBaseline } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

function dateOnly(dateIn) {
  ////console.log("inside function", dateIn);
  let split = dateIn.indexOf("T");
  let date = dateIn.slice(0, split);
  ////console.log(date)
  return date;
}

const UserWord = ({ userWord }) => {
  const noteData = userWord.notes;
  const srsData = userWord.srs;
  const wordData = userWord.word;

  const contentStyle = { background: "#ffffff" };
  const overlayStyle = { background: "rgba(0,0,0,0.5)" };
  const arrowStyle = { color: "#000" };

  const lastReviewed = dateOnly(srsData.last_reviewed.toString());
  const nextReview = dateOnly(srsData.next_reviewed.toString());

  return (
    <Popup
      trigger={
        <Button variant="outlined" fullWidth>
          <Box>
            <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
              {userWord.wordStr}
            </Typography>
            <Typography variant="caption">{nextReview}</Typography>
          </Box>
        </Button>
      }
      {...{ contentStyle, overlayStyle, arrowStyle }}
      modal
      nested
    >
      <Container
        component="main"
        maxWidth="md"
        sx={{ border: 2, borderRadius: 3, p: 2 }}
      >
        <CssBaseline />
        <Grid container spacing={2}>
          <Grid xs={6}>
        <Box sx={{ p: 2 }}>
          <Typography variant="h3">{userWord.wordStr} </Typography>
          <Typography variant="subtitle2">
            {userWord.word.pronunciation}
          </Typography>
          <Typography variant="body1">{userWord.word.description}</Typography>
          <Typography variant="caption">
            {userWord.word.partOfSpeech}
          </Typography>
        </Box>
        </Grid>
        <Grid xs={4}></Grid>
        <Grid xs={2}>
          <Button variant="outlined" color="warning">Remove</Button>
        </Grid>

        
          <Grid xs={6} sx={{ border: 1, borderRadius: 3 }}>
            <Button variant="contained">Add Note</Button>
          </Grid>

          <Grid xs={6} sx={{ border: 1, borderRadius: 3 }}>
            <Typography variant="Subtitle2" sx={{ fontWeight: "bold" }}>
              Performance Scores (SRS)
            </Typography>
            <Typography variant="body1">
              <p>Last reviewed: {lastReviewed}</p>
              <p>Next Review: {nextReview}</p>
            </Typography>
          </Grid>

          <Grid xs={6} sx={{ border: 1, borderRadius: 3 }}>
            <Typography variant="Subtitle2" sx={{ fontWeight: "bold" }}>
              Previous Resources
            </Typography>
          </Grid>

          <Grid xs={6} sx={{ border: 1, borderRadius: 3 }}>
            <Typography variant="Subtitle2" sx={{ fontWeight: "bold" }}>
              Review History
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Popup>
  );
};

export default UserWord;

/*
put this back into 'performance scores'
              <p>Ease Factor: {srsData.ease_factor}</p>
              <p>Interval Index: {srsData.interval_index}</p>
              */