import React from "react";
import { API, graphqlOperation } from "aws-amplify";
//import createWord from "../graphql/mutations";

import { updateWord } from "../../graphql/mutations";
import { useState } from "react";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

// Styling

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Container } from "@mui/material";
import { CssBaseline } from "@mui/material";
import { TextField, Button, Box, Stack, Typography } from "@mui/material";

const defaultTheme = createTheme();

const UpdateWord = ({ wordObj }) => {
  ////console.log("inside updateword: ", wordObj);
  ////console.log(wordObj.id)
  //const id = wordObj.id;

  const [id, setId] = useState(wordObj.id);
  const [word, setWord] = useState(wordObj.word);
  const [partOfSpeech, setPartOfSpeech] = useState(wordObj.partOfSpeech);
  const [description, setDescription] = useState(wordObj.description);
  const [language, setLanguage] = useState(wordObj.language);
  const [pronunciation, setPronunciation] = useState(wordObj.pronunciation);
  const [imageUrl, setImageUrl] = useState(wordObj.imageUrl);
  //const createdAt = wordObj.createdAt
  //const typeName = wordObj._ty

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const wordDetails = {
        id,
        word,
        partOfSpeech,
        description,
        language,
        pronunciation,
        imageUrl,
      };

      //console.log("WORD DETAILS: ", wordDetails);
      ////console.log("word details: ", wordDetails)
      //console.log("BEFORE API CALL");
      await API.graphql(graphqlOperation(updateWord, { input: wordDetails }));
      //console.log("AFTER API CALL");
      alert("Word updated successfully!");

      // Reset state
      /*
      setWord("");
      setPartOfSpeech("");
      setDescription("");
      setLanguage("");
      setPronunciation("");
      setImageUrl("");
	  */
    } catch (error) {
      //console.log("RECIEVED ERROR > ", error);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>

        <Popup
          trigger={
            <Button variant="outlined" fullWidth>
              Update
            </Button>
          }
          modal
          nested
        >
          <Container
            component="main"
            maxWidth="xs"
            sx={{ border: 1, borderRadius: 3, p: 2 }}
          >
            <CssBaseline />
            <Box component="form" onSubmit={handleSubmit}>
              <Stack spacing={2} direction="column">
                <Typography variant="body1">
                  Update existing word in the DB
                </Typography>

                <TextField
                  type="text"
                  value={word}
                  onChange={(e) => setWord(e.target.value)}
                  placeholder="Word"
                />
                <TextField
                  type="text"
                  value={partOfSpeech}
                  onChange={(e) => setPartOfSpeech(e.target.value)}
                  placeholder="Part of Speech"
                />
                <TextField
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Description"
                />
                <TextField
                  type="text"
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                  placeholder="Language"
                />
                <TextField
                  type="text"
                  value={pronunciation}
                  onChange={(e) => setPronunciation(e.target.value)}
                  placeholder="Pronunciation"
                />
                <TextField
                  type="text"
                  value={imageUrl}
                  onChange={(e) => setImageUrl(e.target.value)}
                  placeholder="Image URL"
                />
                <Button type="submit" variant="contained" color="success">
                  Update Word
                </Button>
              </Stack>
            </Box>
          </Container>
        </Popup>

    </ThemeProvider>
  );
};

export default UpdateWord;
