import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import "./landing.css";

const CallToAction = () => {
  const [buttonText, setButtonText] = useState("Launch Beta");
  const [buttonFeedback, setButtonFeedback] = useState("Leave Feedback");
  const [warningDialog, setWarningDialog] = useState(false);
  const [feedbackDialog, setFeedbackDialog] = useState(false);
  const navigate = useNavigate();

  const handleAppClick = () => {
    if (window.innerWidth < 1004) {
      setWarningDialog(true);
    } else {
      navigate("/app");
    }
  };

  return (
    <>
      <Box
        sx={{
          py: 10,
          background: "linear-gradient(to bottom, #2c9dab, #65baa2)",
        }}
      >
        <Container className="fadein5">
          <Typography
            variant="h5"
            component="h5"
            gutterBottom
            align="center"
            sx={{ fontFamily: "Open Sans, sans-serif", color: "#ffffff" }}
          >
            Try it out…
          </Typography>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item>
              <Button
                variant="contained"
                size="large"
                href="#signin"
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  minWidth: 150,
                  transition: "all 0.3s ease",
                  "&:hover": {
                    backgroundSize: "300% 300%",
                    backgroundImage:
                      "linear-gradient(-45deg, #73d9bd, #29c6db 45%, #06a8f4 70%, #2488e4 100%)",
                    animation: "AnimateBG 5s ease infinite",
                    color: "white",
                  },
                  "@keyframes AnimateBG": {
                    "0%": { backgroundPosition: "0% 50%" },
                    "50%": { backgroundPosition: "100% 50%" },
                    "100%": { backgroundPosition: "0% 50%" },
                  },
                }}
                onMouseEnter={() => setButtonText("Launch Beta")}
                onMouseLeave={() => setButtonText("Launch Beta")}
                onClick={handleAppClick}
              >
                {buttonText}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <br />
        <Container className="fadein5">
          <Typography
            variant="h5"
            component="h5"
            gutterBottom
            align="center"
            sx={{ fontFamily: "Open Sans, sans-serif", color: "#ffffff" }}
          >
            Or leave us feedback!
          </Typography>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item>
              <Button
                variant="contained"
                size="large"
                href="#signin"
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  minWidth: 150,
                  transition: "all 0.3s ease",
                  "&:hover": {
                    backgroundSize: "300% 300%",
                    backgroundImage:
                      "linear-gradient(-45deg, #73d9bd, #29c6db 45%, #06a8f4 70%, #2488e4 100%)",
                    animation: "AnimateBG 5s ease infinite",
                    color: "white",
                  },
                  "@keyframes AnimateBG": {
                    "0%": { backgroundPosition: "0% 50%" },
                    "50%": { backgroundPosition: "100% 50%" },
                    "100%": { backgroundPosition: "0% 50%" },
                  },
                }}
                onMouseEnter={() => setButtonFeedback("Feedback Leave")}
                onMouseLeave={() => setButtonFeedback("Leave Feedback")}
                onClick={() => setFeedbackDialog(true)}
              >
                {buttonFeedback}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Dialog
        open={feedbackDialog}
        onClose={() => setFeedbackDialog(false)}
        PaperProps={{
          style: {
            width: "640px",
            height: "80vh",
            maxWidth: "none",
          },
        }}
      >
        {/* <DialogTitle sx={{ textAlign: "center" }}>Feedback!</DialogTitle> */}
        <DialogContent sx={{ padding: 0 }}>
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLScBwl4mTwmHoSrWOG8leK1L8dcImS5hOfmX0Opkw0YkU-VcfA/viewform?embedded=true"
            width="640"
            height="640"
          >
            Loading…
          </iframe>
        </DialogContent>
      </Dialog>

      <Dialog open={warningDialog} onClose={() => setWarningDialog(false)}>
        <DialogTitle sx={{ textAlign: "center" }}>Warning!</DialogTitle>
        <DialogContent>
          <Typography sx={{ textAlign: "center", mb: 2 }}>
            Your screen resolution is smaller than what we currently support.
            This will make Vocabua difficult to use.
          </Typography>
          <Typography sx={{ textAlign: "center", mb: 4 }}>
            We are working on making Vocabua compatible with your device soon.
          </Typography>

          <Button
            fullWidth
            variant="contained"
            onClick={() => navigate("/app")}
            sx={{ backgroundColor: "#1E88E5" }}
          >
            Proceed Anyway
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CallToAction;
