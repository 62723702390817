import React, { useState, useEffect } from "react";

import Word from "./Word";
import AddWord from "./AddWord";
import UpdateWord from "./UpdateWord";
import DeleteWord from "./DeleteWord";

import AddUserWord from "../c_UserWord/AddUserWord";

import Modal from "@mui/material/Modal";

import ModalComponent from "../../components/c_Utils/ModalComponent";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

// Styling

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Container, IconButton } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { CssBaseline } from "@mui/material";
import {
  TextField,
  Button,
  Box,
  Stack,
  Typography,
  Paper,
} from "@mui/material";

import { API, graphqlOperation } from "aws-amplify";
import { listWords } from "../../graphql/queries";

// > Icons
import AddBoxIcon from "@mui/icons-material/AddBox";
//import AddUserWord from "../dynamoUserWord/AddUserWord";

const defaultTheme = createTheme();

const Words = ({ loggedIn, user }) => {
  const [words, setWords] = useState([]);

  ////console.log("within WORDS: > ", user)

  useEffect(() => {
    fetchWords();
  }, []);

  const fetchWords = async () => {
    try {
      const wordData = await API.graphql(graphqlOperation(listWords));
      // const wordData = await API.graphql({
      //   query: listWords,
      //   variables: {},
      //   authMode: "AMAZON_COGNITO_USER_POOLS",
      // });
      //console.log("API DATA:", wordData)
      const wordList = wordData.data.listWords.items; // adjust based on your actual query structure
      setWords(wordList);
    } catch (error) {
      console.error("Error fetching words", error);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />

      <Paper
        sx={{
          p: 1,
          display: "flex",
          flexDirection: "column",
          //height: 1,
          boxShadow: 5,
          marginBottom: 2,
          border:1
        }}
      >
        {" "}
        Multifunction Words Component
      </Paper>

      {/* <Container sx={{ border: 1, borderRadius: 3 }}> */}
      <Container
        component="main"
        sx={{
          border: 0,
          borderRadius: 1,
          backgroundColor: "#ffffff",
          boxShadow: 10,
        }}
      >
        <Box sx={{ border: 0, borderRadius: 3 }}>
          <Stack spacing={2} direction="column">
            <Grid container spacing={2}>
              <Grid xs={10}></Grid>
              <Grid xs={2}>
                <Grid xs={2}>
                  <AddWord />
                </Grid>
              </Grid>
            </Grid>
            {words.map((word, index) => (
              <Grid container spacing={2} key={index}>
                <Grid xs={4}>
                  <Word word={word} />
                </Grid>
                {loggedIn ? (
                  <>
                    <Grid xs={0.5}>
                      {/* <AddUserWord user={user} wordObj={word} /> */}
                      <Button>Add to vocab (UserWord) will go here</Button>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                <Grid xs={3.5}>
                  <UpdateWord wordObj={word} />
                </Grid>
                <Grid xs={3.5}>
                  <DeleteWord wordObj={word} />
                </Grid>
                <Grid xs={1}>

                  <AddUserWord user={user} wordObj={word} />
                </Grid>
              </Grid>
            ))}
          </Stack>
        </Box>
      </Container>
      {/* </Container> */}
    </ThemeProvider>
  );
};
export default Words;

/*

                <Stack key={index} direction="row" spacing={2}>
                  <Word word={word} />
                  <UpdateWord wordObj={word} />
                  <DeleteWord wordObj={word} />
                </Stack>

                  <Popup trigger={<Button variant="contained">Update</Button>} modal nested>
                    <UpdateWord wordObj={word} />
                  </Popup>

      <div>
        <button onClick={openModal}>Add New Word</button>
        <ModalComponent isOpen={isModalOpen} onClose={closeModal}>
          <AddWord />
        </ModalComponent>
      </div>
	  */
