import React, { useEffect, useState } from "react";
import { Container, CircularProgress } from "@mui/material";
import { useAuth } from "../../AuthContext";

import LearnPanel from "./LearnPanel";

const apiKey = process.env.REACT_APP_APIGATEWAY_KEY;
const gateway = process.env.REACT_APP_APIGATEWAY_DEV;

const dailyUpdate = async ({ user, jwtToken }) => {
  try {
    const endpoint = `${gateway}scorelex-daily-update`;
    const input = { userId: user.username };

    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apiKey,
        "Authorization": jwtToken,
      },
      body: JSON.stringify(input),
    });
    const data = await response.json();
    //console.log(data);
    return JSON.parse(data.body);
  } catch (error) {
    //console.log(error);
    return {};
  }
};

// Get all topic data to display in LearnPanel
const fetchTopicData = async (user, jwtToken) => {
  try {
    const endpoint =
      `${gateway}dynamo-get-explore-topics`;
    const input = { userId: user.username };

    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apiKey,
        "Authorization": jwtToken,
      },
      body: JSON.stringify(input),
    });
    const data = await response.json();
    //console.log(data);
    return JSON.parse(data.body);
  } catch (error) {
    //console.log("error", error);
    return {};
  }
};

const Learn = ({}) => {
  const { user, jwtToken } = useAuth();
  const [topicData, setTopicData] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      if (user) {
        await dailyUpdate({ user, jwtToken });
        const data = await fetchTopicData(user, jwtToken);
        setTopicData(data);
      }
    };
    loadData();
  }, [user]);

  return (
    <Container>
      {topicData ? (
        <>
          <LearnPanel topicData={topicData} user={user} />
        </>
      ) : (
        <>
          <CircularProgress />
        </>
      )}
    </Container>
  );
};

export default Learn;
