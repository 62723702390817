import React, { useState, useEffect } from "react";

import { useTranslation } from "../../display_languages/UseTranslation";

import { Paper, Card, CardActionArea, Grid, Stack, Typography, Box, CircularProgress } from '@mui/material';

import { useAuth } from "../../AuthContext";

const apiKey = process.env.REACT_APP_APIGATEWAY_KEY;
const gateway = process.env.REACT_APP_APIGATEWAY_DEV;

const GroupCard = ({ t, session, index, selectedSessionId, handleSelectSession }) => {
  let backgroundColor = '#ffffff';
  if (index % 2 != 0) {
    backgroundColor = '#f5f8fa';
  }
  if (session.id == selectedSessionId) {
    backgroundColor = '#e7ecf3';
  }

  return (
    <Card variant="outlined" sx={{ m: 0.5, mt: 0, overflow: 'hidden', border: 1, borderColor: '#e4e7ec', backgroundColor: backgroundColor }}>
      <CardActionArea onClick={() => handleSelectSession(session.id)}>
        <Grid container alignItems="center" justifyContent="left" sx={{ py: 0 }}>
          {/* Item index */}
          <Grid item xs={1} sx={{ textAlign: 'left', pl: 1 }}>
            <Typography variant='caption' sx={{ color: '#b4cad9' }} >{index+1}</Typography>
          </Grid>
          {/* Item content */}
          <Grid item xs={11} sx={{ml:-1}}>
            <Typography variant="h6" component="h3">{session.name}</Typography>
            <Typography variant="body2">
              {session.createdAt} - {t.study?.resources}: {session.totalResources}
            </Typography>
          </Grid>
        </Grid>

      </CardActionArea>
    </Card>
  );
}


export default function StudyGroup({ selectedSessionId, handleSelectSession, group, sort, readyToFetch }) {
  const { user, jwtToken } = useAuth();
  const t = useTranslation();
  const [sessions, setSessions] = useState({});

  useEffect(() => {
    const fetchSessions = async () => {
      setSessions({});

      const parameters = {
        userId: user.username,
        group: group,
        sort: sort,
        new: true
      };

      const endpoint = `${gateway}dynamo-get-sessions-overview`;
      const requestBody = {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          "x-api-key": apiKey,
          "Authorization": jwtToken
         },
        body: JSON.stringify(parameters),
      };

      try {
        const response = await fetch(endpoint, requestBody);
        const data = await response.json();

        //console.log("Data from endpoint", data)

        setSessions(data.body);

      } catch (err) {
        //console.log("error:", err.message);
      }
    };

    if (user && readyToFetch) {
      fetchSessions();
    }
  }, [user, group, sort, readyToFetch]);

  const title = {
    "session": t.study?.sessions,
    "word": t.study?.words,
    "topic": t.study?.study_topics,
    "none": ""
  };
  const emptyMessage = t.study?.none;

  // Helper function to check if object is empty
  const isEmptyObject = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;

  return (
    <Paper elevation={2}>
      {/* Header */}
      <Grid container alignItems="left" justifyContent="left">
        <Grid item xs={12}>
          <Typography sx={{ mt: 0.5 }} variant="h5">{title[group]}</Typography>
          <Typography sx={{ mb: 0.5 }} variant="body2">{t.misc?.total}{sessions.length}</Typography>
        </Grid>
      </Grid>

      {/* Body */}
      {isEmptyObject(sessions) ?
        // Not loaded - show loading
        <CircularProgress />
        :
        sessions.length === 0 ?
          // Loaded and returned nothing - show empty message
          <Typography variant="caption">{emptyMessage}</Typography>
          :
          // Show all sessions
          <Box sx={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto', pt: 0.5 }}>
            <Stack spacing={0} direction="column">
              {sessions.map((session, index) => (
                <GroupCard t={t} session={session} index={index} selectedSessionId={selectedSessionId} handleSelectSession={handleSelectSession} />
              ))}
            </Stack>
          </Box>
      }

    </Paper>
  );
}