import React, { useState } from "react";
import { Container, Typography, Radio, RadioGroup, FormControlLabel, FormControl, Tooltip, IconButton, Grid } from "@mui/material";
import { ResponsivePie } from '@nivo/pie'
import { FaInfo } from "react-icons/fa";

import { useTranslation } from "../../display_languages/UseTranslation";

const Chart = ({data, handlePieSliceClick}) => (
    <ResponsivePie
        data={data}
        onClick={(data) => { handlePieSliceClick(data.id); }}

        margin={{ top: 40, right: 0, bottom: 30, left: 0 }}
        sortByValue={false}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={2}
        activeOuterRadiusOffset={8}
        colors={({ data }) => data.color}
        borderWidth={1}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.2
                ]
            ]
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    2
                ]
            ]
        }}
    />
)



const LearningByTopic = ({ data, handlePieSliceClick, learningByTopicDisplay, handleLearningByTopicDisplayChange }) => {
    const t = useTranslation();

    return (
        <Container sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <style>
                {`
                    .pie-container path:hover {
                        cursor: pointer;
                    }
                `}
            </style>

            <Grid container alignItems="left" justifyContent="left">
                <Grid item xs={11}>
                    <Typography sx={{ ml: 6, mt: 0.5 }} component="h1" variant="h5">{t.progression?.learningbytopic.title}</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Tooltip title={t.progression?.learningbytopic.info}>
                        <IconButton sx={{ "&:hover": { color: "#1976d2" } }}>
                            <FaInfo />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>

            <div className="pie-container" style={{ width: '100%', paddingBottom: '80%', position: 'relative'}}>
                <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
                    <Chart data={data} handlePieSliceClick={handlePieSliceClick} />
                </div>
            </div>

            <div style={{ marginTop: 'auto' }}>
                <FormControl component="fieldset">
                    <RadioGroup
                        row
                        aria-label="learning-options"
                        name="learning-options"
                        value={learningByTopicDisplay}
                        onChange={handleLearningByTopicDisplayChange}
                        sx={{ justifyContent: 'center' }}
                    >
                        <FormControlLabel value="resources" control={<Radio />} label={t.progression?.resources} />
                        <FormControlLabel value="words_read" control={<Radio />} label={t.progression?.wordsread} />
                        <FormControlLabel value="words_learned" control={<Radio />} label={t.progression?.wordslearned} />
                    </RadioGroup>
                </FormControl>
            </div>
        </Container>
    );
}

export default LearningByTopic;