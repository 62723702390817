import React from "react";
import { API, graphqlOperation } from "aws-amplify";
//import createWord from "../graphql/mutations";

import { createWord } from "../../graphql/mutations";
import { useState } from "react";
import { createUserWord } from "../../graphql/mutations";
import {listProjects } from "../../graphql/queries";

// Styling

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Container } from "@mui/material";
import { CssBaseline } from "@mui/material";
import { TextField, Button, Box, Stack, Typography } from "@mui/material";

import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { IconButton } from "@mui/material";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const defaultTheme = createTheme();
const now = new Date();
const isoString = now.toISOString();
const todaysDate = isoString.split('T')[0];

const AddUserWord = ({ user, wordObj }) => {
  ////console.log("USER: >", user);
  ////console.log("Word: >", wordObj);

  const[projects, setProjects] = useState("")

  const handleSubmit = async (event) => {
    event.preventDefault();

    const userWordDetails = {
      userId: user.username,
      wordId: wordObj.id,
      // word: wordObj,
      wordStr: wordObj.word,
      notes: "", // If you have a default value for notes or it's passed in props, adjust this line
      srs: {
        alpha: 1,
        lowest_alpha:1,
        last_reviewed: todaysDate,
        first_reviewed: todaysDate,
      },
      reviewHistory: [
        {
          reviewDate: todaysDate,
          alpha_at_review: 1, 
          correct: true
        },
      ],
    };

    try {
      //console.log("BEFORE API CALL");
      await API.graphql(
        graphqlOperation(createUserWord, { input: userWordDetails })
      );
      //console.log("AFTER API CALL");
    } catch (error) {
      //console.log("RECIEVED ERROR > ", error);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Popup
        trigger={
          <IconButton aria-label="Add Word to Vocab">
            <AddBoxOutlinedIcon />
          </IconButton>
        }
        modal
        nested
      >
        <Container
          component="main"
          maxWidth="xs"
          sx={{ border: 1, borderRadius: 3, p: 2 }}
        >
          Add {wordObj.word} to your Vocab? -
          <Button
            type="submit"
            onClick={handleSubmit}
            variant="contained"
            color="success"
          >
            YEAh BUDDAY!
          </Button>
        </Container>
      </Popup>
    </ThemeProvider>
  );
};

export default AddUserWord;

/*
          <Box onSubmit={handleSubmit}>
            Add {word.word} to your Vocab? -
            <Button type="submit" variant="contained" color="success">
              YEAh BUDDAY!
            </Button>
          </Box>

<CssBaseline />
          <Box component="form" onSubmit={handleSubmit}>
            <Stack spacing={2} direction="column">
              <Typography variant="body1">Add a new word to the DB</Typography>

              <TextField
                type="text"
                value={word}
                onChange={(e) => setWord(e.target.value)}
                placeholder="Word"
              />
              <TextField
                type="text"
                value={partOfSpeech}
                onChange={(e) => setPartOfSpeech(e.target.value)}
                placeholder="Part of Speech"
              />
              <TextField
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Description"
              />
              <TextField
                type="text"
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                placeholder="Language"
              />
              <TextField
                type="text"
                value={pronunciation}
                onChange={(e) => setPronunciation(e.target.value)}
                placeholder="Pronunciation"
              />
              <TextField
                type="text"
                value={imageUrl}
                onChange={(e) => setImageUrl(e.target.value)}
                placeholder="Image URL"
              />
              <Button type="submit" variant="contained" color="success">
                Add Word
              </Button>
            </Stack>
          </Box>
*/

/* 

original code 

  const [testingVar, setTestingVar] = useState("");

  /* idk why i am doing this theoretically i should be able to place
  a 'word' object in the 'userWordDetails' object later for addition
  but its not working for some reason <endcomment here>
  const userWordState = {
	id: wordObj.id, 
	word: wordObj.word,
	partOfSpeech: wordObj.partOfSpeech, 
	description: wordObj.description, 
	language: wordObj.language, 
	pronunciation: wordObj.pronunciation, 
	imageUrl: wordObj.imageUrl
  };

  const srsState = {
    ease_factor: 0,
    interval_index: 0,
    last_reviewed: "",
    next_reviewed: "",
  };

  const reviewHistoryState = [
    {
      reviewDate: "",
      performance: 0,
    },
  ];

  const [userId, setUserId] = useState(user.username);
  const [wordId, setWordId] = useState(wordObj.id);
  const [word, setWord] = useState(userWordState);
  const [notes, setNotes] = useState("");
  const [srs, setSRS] = useState(srsState);
  const [reviewHistory, setReviewHistory] = useState(reviewHistoryState);


*/

/*
previously inside TRY
      const userWordDetails = {
        userId,
        wordId,
        word,
        notes,
        srs,
        reviewHistory,
      };

	  */
