import React from "react";
import { API, graphqlOperation } from "aws-amplify";
//import createWord from "../graphql/mutations";

import { createWord } from "../../graphql/mutations";
import { useState } from "react";

// Styling

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Container } from "@mui/material";
import { CssBaseline } from "@mui/material";
import { TextField, Button, Box, Stack, Typography } from "@mui/material";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const defaultTheme = createTheme();

const AddWord = () => {
  const [word, setWord] = useState("");
  const [partOfSpeech, setPartOfSpeech] = useState("");
  const [description, setDescription] = useState("");
  const [language, setLanguage] = useState("");
  const [pronunciation, setPronunciation] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const wordDetails = {
        word,
        partOfSpeech,
        description,
        language,
        pronunciation,
        imageUrl,
      };
      //console.log("BEFORE API CALL");
      await API.graphql(graphqlOperation(createWord, { input: wordDetails }));
      //console.log("AFTER API CALL");

      // Reset state
      setWord("");
      setPartOfSpeech("");
      setDescription("");
      setLanguage("");
      setPronunciation("");
      setImageUrl("");
    } catch (error) {
      //console.log("RECIEVED ERROR > ", error);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Popup
        trigger={
          <Button variant="outlined" fullWidth>
            Add Word
          </Button>
        }
        modal
        nested
      >
        <Container
          component="main"
          maxWidth="xs"
          sx={{ border: 1, borderRadius: 3, p: 2 }}
        >
          <CssBaseline />
          <Box component="form" onSubmit={handleSubmit}>
            <Stack spacing={2} direction="column">
              <Typography variant="body1">Add a new word to the DB</Typography>

              <TextField
                type="text"
                value={word}
                onChange={(e) => setWord(e.target.value)}
                placeholder="Word"
              />
              <TextField
                type="text"
                value={partOfSpeech}
                onChange={(e) => setPartOfSpeech(e.target.value)}
                placeholder="Part of Speech"
              />
              <TextField
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Description"
              />
              <TextField
                type="text"
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                placeholder="Language"
              />
              <TextField
                type="text"
                value={pronunciation}
                onChange={(e) => setPronunciation(e.target.value)}
                placeholder="Pronunciation"
              />
              <TextField
                type="text"
                value={imageUrl}
                onChange={(e) => setImageUrl(e.target.value)}
                placeholder="Image URL"
              />
              <Button type="submit" variant="contained" color="success">
                Add Word
              </Button>
            </Stack>
          </Box>
        </Container>
      </Popup>
    </ThemeProvider>
  );
};

export default AddWord;
