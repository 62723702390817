import { useState, useEffect } from 'react';
import { useLanguage } from './LanguageContext';

export function useTranslation() {
  const { language } = useLanguage();
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    async function loadTranslations() {
      try {
        const loadedTranslations = await import(`./${language}.json`);
        setTranslations(loadedTranslations);
      } catch (error) {
        console.error("Could not load translations", error);
      }
    }

    loadTranslations();
  }, [language]);

  return translations;
}
