import * as React from "react";

import { Box, Button, Stack, Paper, Typography, Grid } from "@mui/material";
import Topic_Card from "./Topic_Card";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../display_languages/UseTranslation";

export default function TopicList({ topics, setUserSessionTopic, addUserTopic, added, title, emptyMessage, moreTopics }) {
    const navigate = useNavigate();
    const t = useTranslation();

    return (
        <Paper elevation={2}>
            {/* Header */}
            <Grid container alignItems="left" justifyContent="left">
                <Grid item xs={12}>
                    <Typography sx={{ my: 0.5 }} component="h1" variant="h5">{title}</Typography>
                </Grid>
            </Grid>

            {/* Body */}
            {topics.length > 0 ?
                <Stack spacing={0} direction="column">
                    {topics.map((topic, index) => (
                        <Topic_Card topic={topic} setUserSessionTopic={setUserSessionTopic} addUserTopic={addUserTopic} added={added} />
                    ))}
                </Stack>
                :
                <Box sx={{ m: 2 }}>
                    <Typography variant="caption">{emptyMessage}</Typography>
                </Box>
            }

            {moreTopics &&
                <Button
                    variant="text"
                    size="small"
                    onClick={() => { navigate('/explore') }}
                >
                    {t.explore?.moretopics}
                </Button>
            }
        </Paper>
    );
}