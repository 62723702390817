import React from "react";
import { Container, Paper, Typography, Box} from "@mui/material";

import AdminPanel from "./AdminPanel";
import ToolBar from "../../components/c_Navigation/ToolBar";

const Admin = ({ user }) => {
  const panelType = "home";

  return (
    <Container sx={{border:0}}>
      {/* <Paper><Typography>Admin Home</Typography> </Paper> */}
      {/* <ToolBar/> */}
      {/* <Box display="flex" sx={{color:"#ffffff"}}><h1>Admin Home</h1></Box> */}

      <AdminPanel user={user}/>
    </Container>
  );
};

export default Admin;
