import React, { useState } from "react";
import { Container, Typography, Radio, RadioGroup, FormControlLabel, FormControl, Tooltip, IconButton, Grid } from "@mui/material";

import { ResponsiveTimeRange } from '@nivo/calendar'
import { ResponsiveLine } from '@nivo/line'

import { FaInfo } from "react-icons/fa";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TimelineIcon from '@mui/icons-material/Timeline';

import { useTranslation } from "../../display_languages/UseTranslation";

const Chart = ({ data, learningCalendarType, handleCalendarClick }) => {

  if (learningCalendarType == "calendar") {
    // Abort if the data is in line format- not yet updated
    if (!data.from) {
      return;
    }

    return (
      <ResponsiveTimeRange
        data={data.data}
        onClick={(data) => { handleCalendarClick(data.date); }}

        from={new Date(data.from)}
        to={new Date(data.to)}
        emptyColor="rgb(240,240,240)"
        firstWeekday="monday"
        colors={data.colors}
        margin={{ top: 20, right: 0, bottom: 0, left: 0 }}
        dayBorderWidth={2}
        dayBorderColor="#ffffff"
        dayRadius="5px"
        weekdayLegendOffset={0}
        weekdayTicks={[]}
      />
    );
  }
  if (learningCalendarType == "line") {

    // Abort if the data is in calendar format- not yet updated
    if (data.from) {
      return;
    }

    //console.log('data',data)

    return (
      <ResponsiveLine
        data={[data]}
        margin={{ top: 10, right: 10, bottom: 20, left: 35 }}
        xScale={{
          type: 'time',
          format: "%Y-%m-%d",
          precision: 'day',
        }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: false,
          reverse: false
        }}
        xFormat="time:%Y-%m-%d"
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 2,
          tickPadding: 0,
          format: "%d %b",
          legend: '',
          legendOffset: 24,
          legendPosition: 'middle'
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          legend: data.id,
          legendOffset: -30,
          legendPosition: 'middle',
          truncateTickAt: 0
        }}
        colors={{ datum: 'color' }}
        pointSize={10}
        pointBorderWidth={2}
        pointLabelYOffset={-12}
        enableTouchCrosshair={true}
        useMesh={true}
      />
    );
  }

}



const LearningCalendar = ({ data, handleCalendarClick, learningCalendarType, handleLearningCalendarTypeChange, learningCalendarDisplay, handleLearningCalendarDisplayChange }) => {
  const t = useTranslation();

  return (
    <Container sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      <style>
        {`
            .calendar-container rect:hover {
                cursor: pointer;
            }
        `}
      </style>

      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={11}>
          <Typography sx={{ ml: 6, mt: 0.5 }} component="h1" variant="h5">{t.progression?.learningcalendar.title}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Tooltip title={t.progression?.learningcalendar.info}>
            <IconButton sx={{ "&:hover": { color: "#1976d2" } }}>
              <FaInfo />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <div className="calendar-container" style={{ width: '100%', paddingBottom: '70%', position: 'relative' }}>
        <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
          <Chart data={data} learningCalendarType={learningCalendarType} handleCalendarClick={handleCalendarClick} />
        </div>
      </div>

      <div style={{ marginTop: 'auto' }}>
        <FormControl component="fieldset">
          {/* Learning timeline type (calendar or type) */}
          <RadioGroup
            row
            aria-label="learning-options"
            name="learning-options"
            value={learningCalendarType}
            onChange={handleLearningCalendarTypeChange}
            sx={{ justifyContent: 'center', mb: -1 }}
          >
            <FormControlLabel value="calendar" control={<Radio />} label={<CalendarMonthIcon sx={{ color: '#555' }} />} />
            <FormControlLabel value="line" control={<Radio />} label={<TimelineIcon sx={{ color: '#555' }} />} />
          </RadioGroup>

          {/* Data to display */}
          <RadioGroup
            row
            aria-label="learning-options"
            name="learning-options"
            value={learningCalendarDisplay}
            onChange={handleLearningCalendarDisplayChange}
            sx={{ justifyContent: 'center' }}
          >
            <FormControlLabel value="resources" control={<Radio />} label={t.progression?.resources} />
            <FormControlLabel value="words_read" control={<Radio />} label={t.progression?.wordsread} />
            <FormControlLabel value="words_learned" control={<Radio />} label={t.progression?.wordslearned} />
          </RadioGroup>
        </FormControl>
      </div>
    </Container>
  );
}

export default LearningCalendar;