import React from "react";
import { AppBar, Toolbar, Typography, Grid, Box } from '@mui/material';

import { useTranslation } from "../../display_languages/UseTranslation";


const HomeToolbar = ({username}) => {
    const t = useTranslation();

    const now = new Date()
    const currentHour = now.getHours();

    let welcomeMessage;
    if (currentHour >= 3 && currentHour < 12) {
        welcomeMessage = t.home?.welcome_morning;  // From 3:00 AM to 11:59 AM
    } else if (currentHour >= 12 && currentHour < 18) {
        welcomeMessage = t.home?.welcome_afternoon;  // From 12:00 PM to 5:59 PM
    } else {
        welcomeMessage = t.home?.welcome_evening;  // From 6:00 PM to 2:59 AM
    }

    return (
        <Box sx={{ flexGrow: 1, mb: 3 }}>
            <AppBar position="fixed" sx={{ "backgroundColor": "#ffffff", "boxShadow": 2, pl: '220px' }}>
                <Toolbar sx={{ my: -1.5 }}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        {/* Center content: Typography */}
                        <Grid item xs={12} sm={12} container alignItems="center" justifyContent="center">
                            <Typography variant="h5" sx={{ color: 'black' }}>
                                {welcomeMessage}{username}
                            </Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
export default HomeToolbar;