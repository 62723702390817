import React, { useEffect, useState } from "react";
import { Grid, Paper, Container } from "@mui/material";
import { format } from "date-fns";

import LearningCalendar from "../../components/c_Progression/LearningCalendar";
import LearningByTopic from "../../components/c_Progression/LearningByTopic";
import Words from "../../components/c_Progression/Words";
import WordStatistics from "../../components/c_Progression/WordStatistics";

import ProgressionToolbar from "./ProgressionToolbar";

import "../../styling/bodywidth.css";

import { useAuth } from "../../AuthContext";

const apiKey = process.env.REACT_APP_APIGATEWAY_KEY;
const gateway = process.env.REACT_APP_APIGATEWAY_DEV;

// convert all raw progression data into the data which will be visualised, based on display settings
const processProgressionData = async ({
  progressionData,
  timeRange,
  topic,
  learningByTopicDisplay,
  learningCalendarType,
  learningCalendarDisplay,
  customTimeRange,
  searchText,
  displayWords,
  jwtToken
}) => {
  
  try {
    const endpoint = `${gateway}process_progression`;

    const input = {
      data: progressionData,
      settings: {
        time_range: timeRange,
        topic: topic,
        learning_by_topic_display: learningByTopicDisplay,
        learning_calendar_type: learningCalendarType,
        learning_calendar_display: learningCalendarDisplay,
        custom_time_range: [
          format(new Date(customTimeRange[0]), "yyyy-MM-dd"),
          format(new Date(customTimeRange[1]), "yyyy-MM-dd"),
        ],
        search_text: searchText,
        display_words: displayWords,
      },
    };

    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apiKey,
        "Authorization": jwtToken,
      },
      body: JSON.stringify(input),
    });
    const data = await response.json();
    return data["body"];
  } catch (error) {
    //console.log(error);
    return {};
  }
};

export default function ProgressionPanel({ progressionData, selectedWord }) {
  // variables for time range
  const [timeRange, setTimeRange] = useState("alltime");
  const {jwtToken} = useAuth();

  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
    // reset custom time range if changing away from it
    if (timeRange != "custom") {
      setCustomTimeRange([
        customTimeRangeMin.getTime(),
        customTimeRangeMax.getTime(),
      ]);
    }
  };

  // variables for topics
  const [topic, setTopic] = useState(
    progressionData.topics.reduce(
      (acc, title) => ({ ...acc, [title]: true }),
      {}
    )
  );

  const handleTopicChange = (event) => {
    setTopic({ ...topic, [event.target.name]: event.target.checked });
  };

  // solo/unsolo topics when pie slices are clicked
  const handlePieSliceClick = (pieSliceId) => {
    const allOtherFalse = Object.keys(topic).every(
      (key) => key === pieSliceId || !topic[key]
    );

    if (allOtherFalse) {
      // If all other topics are false, set all topics to true
      setTopic(
        Object.fromEntries(Object.keys(topic).map((key) => [key, true]))
      );
    } else {
      // Otherwise, set all topics but pieSliceId to false
      setTopic(
        Object.fromEntries(
          Object.keys(topic).map((key) => [key, key === pieSliceId])
        )
      );
    }
  };

  // focus in on specific day when calendar day clicked
  const handleCalendarClick = (date) => {
    // convert date into number to set sliders
    const timestamp = [new Date(date).getTime(), new Date(date).getTime()];

    // revert time range if clicking again
    if (
      timeRange == "custom" &&
      JSON.stringify(customTimeRange) == JSON.stringify(timestamp)
    ) {
      setTimeRange("alltime");
    }
    // set time range to day clicked
    else {
      setTimeRange("custom");
      setCustomTimeRange(timestamp);
      setCustomTimeRangeCommitted(timestamp);
    }
  };

  // variables for "Learning by Topic" vis display type
  const [learningByTopicDisplay, setLearningByTopicDisplay] =
    useState("resources");
  const handleLearningByTopicDisplayChange = (event) => {
    setLearningByTopicDisplay(event.target.value);
  };

  // variables for "Learning Calendar" vis type
  const [learningCalendarType, setLearningCalendarType] = useState("calendar");
  const handleLearningCalendarTypeChange = (event) => {
    setLearningCalendarType(event.target.value);
  };

  // variables for "Learning Calendar" vis display data
  const [learningCalendarDisplay, setLearningCalendarDisplay] =
    useState("resources");
  const handleLearningCalendarDisplayChange = (event) => {
    setLearningCalendarDisplay(event.target.value);
  };

  // variables for custom date ranges
  const customTimeRangeMin = new Date(progressionData.dates.min);
  const customTimeRangeMax = new Date(progressionData.dates.max);
  const [customTimeRange, setCustomTimeRange] = useState([
    customTimeRangeMin.getTime(),
    customTimeRangeMax.getTime(),
  ]);
  const [customTimeRangeCommitted, setCustomTimeRangeCommitted] = useState([
    customTimeRangeMin.getTime(),
    customTimeRangeMax.getTime(),
  ]);
  const handleCustomTimeRangeChange = (event, newValue) => {
    setCustomTimeRange(newValue);
  };
  const handleCustomTimeRangeCommittedChange = (event, newValue) => {
    setCustomTimeRangeCommitted(newValue);
  };

  // variables for word search box
  const [searchText, setSearchText] = useState("");
  const handlePieSliceSearch = (searchTerm) => {
    // Reset search is clicking pie slice again
    if (searchText == searchTerm) {
      setSearchText("");
    } else {
      setSearchText(searchTerm);
    }
  };

  // variables for word statistics
  const [displayWords, setDisplayWords] = useState([]);

  // Convert raw progression data to display data
  const [displayData, setDisplayData] = useState(null);
  useEffect(() => {
    const loadData = async () => {
      const data = await processProgressionData({
        progressionData,
        timeRange,
        topic,
        learningByTopicDisplay,
        learningCalendarType,
        learningCalendarDisplay,
        customTimeRange,
        searchText,
        displayWords,
        jwtToken
      });
      setDisplayData(data);
    };
    loadData();
  }, [
    progressionData,
    timeRange,
    topic,
    learningByTopicDisplay,
    learningCalendarType,
    learningCalendarDisplay,
    customTimeRangeCommitted,
    searchText,
    displayWords,
  ]);

  const [boxShadow, setBoxShadow] = useState("1px");
  useEffect(() => {
    if (selectedWord) {
      setBoxShadow("0px 0px 5px 5px #72DABD");
      setDisplayWords([selectedWord]);
      const timer = setTimeout(() => {
        setBoxShadow("1px");
      }, 2000);
    }
  }, [selectedWord]);

  return (
    <Container>
      <ProgressionToolbar
        allTopics={progressionData.topics}
        topic={topic}
        handleTopicChange={handleTopicChange}
        timeRange={timeRange}
        handleTimeRangeChange={handleTimeRangeChange}
        customTimeRangeMin={customTimeRangeMin}
        customTimeRangeMax={customTimeRangeMax}
        customTimeRange={customTimeRange}
        handleCustomTimeRangeChange={handleCustomTimeRangeChange}
        handleCustomTimeRangeCommittedChange={
          handleCustomTimeRangeCommittedChange
        }
      />

      <Grid container spacing={1} sx={{ flexGrow: 1, width: "auto", pb: 1 }}>
        {/* Row 1 */}
        <Grid item xs={6}>
          <Paper elevation={2} sx={{ height: `500px` }}>
            {displayData && (
              <LearningByTopic
                data={displayData.learning_by_topic}
                handlePieSliceClick={handlePieSliceClick}
                learningByTopicDisplay={learningByTopicDisplay}
                handleLearningByTopicDisplayChange={
                  handleLearningByTopicDisplayChange
                }
              />
            )}
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper elevation={2} sx={{ height: `500px` }}>
            {displayData && (
              <LearningCalendar
                data={displayData.learning_calendar}
                handleCalendarClick={handleCalendarClick}
                learningCalendarType={learningCalendarType}
                handleLearningCalendarTypeChange={
                  handleLearningCalendarTypeChange
                }
                learningCalendarDisplay={learningCalendarDisplay}
                handleLearningCalendarDisplayChange={
                  handleLearningCalendarDisplayChange
                }
              />
            )}
          </Paper>
        </Grid>

        {/* Row 2 */}
        <Grid item xs={6}>
          <Paper elevation={2} sx={{ height: `500px` }}>
            {displayData && (
              <Words
                words={displayData.words}
                wordProgress={displayData.word_progress}
                setSearchText={setSearchText}
                searchText={searchText}
                setDisplayWords={setDisplayWords}
                handlePieSliceSearch={handlePieSliceSearch}
              />
            )}
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper elevation={2} sx={{ height: `500px`, boxShadow: boxShadow }}>
            {displayData && (
              <WordStatistics
                data={displayData.word_statistics}
                displayWords={displayWords}
                setDisplayWords={setDisplayWords}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
