import React from "react";

import chroma from 'chroma-js';
import "reactjs-popup/dist/index.css";
import { GrAdd } from "react-icons/gr";
import LockIcon from '@mui/icons-material/Lock';
import { Card, CardActionArea, Grid, IconButton, LinearProgress, Box, Typography } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';


import Stories from "../../media/images/stories.png";
import Greetings from "../../media/images/greetings.png";
import Space from "../../media/images/space.png";

import { useTranslation } from "../../display_languages/UseTranslation";

const images = {
  "Stories": Stories,
  "Greetings": Greetings,
  "Space": Space,
}

const ProgressBar = ({ t, added, totalWords, learnedWords, minimumWords, completeColor, minimumWordsTooltip }) => {

  const vocabuaBackground = chroma.scale(['rgba(30, 136, 229, 0.3)', 'rgba(3, 169, 244, 0.3)', 'rgba(38, 198, 218, 0.3)', 'rgba(114, 218, 189, 0.3)']);
  const vocabuaMain = chroma.scale(['rgb(30, 136, 229)', 'rgb(3, 169, 244)', 'rgb(38, 198, 218)', 'rgb(114, 218, 189)']);
  const border = '0 0 1px #4D4F6D, '.repeat(20).slice(0, -2)
  const positionPercentage = ((minimumWords/totalWords)*100).toString().concat('%');
  const lockPosition = 'calc('.concat(positionPercentage, ' + 2px)');

  const wordTooltip = (
    <>
      {`${t.explore?.words_learned}${learnedWords}`}
      <br />
      {`${t.explore?.words_total}${totalWords}`}
    </>
  );

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      {/* Progress Bar */}
      <LinearProgress variant="determinate" value={(learnedWords / totalWords) * 100}
        sx={{
          height: 14,
          m: 0.5,
          mt: 0,
          borderRadius: 10,
          backgroundColor: vocabuaBackground(learnedWords / totalWords).hex(),
          '& .MuiLinearProgress-bar': { backgroundColor: learnedWords != totalWords ? vocabuaMain(learnedWords / totalWords).hex() : completeColor }
        }} />

      {/* Minimum Words Marker */}
      {!added && learnedWords < minimumWords && minimumWords != 0 && (
        <Tooltip title={minimumWordsTooltip} followCursor arrow placement="top">
          <Box sx={{
            position: 'absolute',
            left: positionPercentage,
            top: 0,
            bottom: 4,
            width: '2px',
            backgroundColor: 'rgba(0,0,0,0.5)'
          }} />
          {/* Lock Icon above the Line */}
          <Box sx={{
            position: 'absolute',
            left: lockPosition, // Center the icon above the line (adjust based on icon size)
            top: '-4px', // Adjust based on the desired position relative to the line
            zIndex: 10 // Ensures the icon stays on top of other elements
          }}>
            <LockIcon sx={{ color: 'rgba(0,0,0,0.5)', fontSize: 14 }} />
          </Box>
        </Tooltip>
      )}

      {/* Word Count */}
      <Tooltip title={wordTooltip} followCursor arrow placement="top">
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 2,
            right: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            fontSize: '0.75rem',
            textShadow: border,
          }}
        >
          {learnedWords}/{totalWords}
        </Box>
      </Tooltip>
    </Box>
  );
}



const Topic_Card = ({ topic, setUserSessionTopic, sessionSettings, addUserTopic, added }) => {
  const t = useTranslation();

  const learnedWords = topic.learned_words;
  const totalWords = topic.total_words;
  const minimumWords = topic.minimumWords;

  const minimumWordsTooltip = (
    <>
      {`${t.explore?.words_min}${minimumWords}`}
    </>
  );

  const completeColor = '#efd981'

  let borderSize = 1;
  let borderColor = '#f4f4f4';
  if (learnedWords == totalWords) {
    borderSize = 2;
    borderColor = completeColor;
  }

  // If the topic card is clicked from the session settings, remove topic. otherwise, add topic
  let clickTopic = topic;
  if(sessionSettings){
    clickTopic = {}
  }

  const cardContent = (
    <Grid container>
      {/* Image */}
      <Grid item xs={2.25} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 0.5 }}>
        <img src={images[topic.name]} alt={topic.name} style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
      </Grid>

      <Grid item xs={9.75} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', mt: 0.5 }}>
        <Grid container sx={{ flexGrow: 1, ml: 1 }}>

          {/* Title and description */}
          <Grid item xs={10} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <Typography variant="h5" gutterBottom sx={{ mb: 0 }}>
              {topic.name}
            </Typography>
            <Typography variant="caption" sx={{ ml: 0.5, textAlign: 'left' }} gutterBottom>
              {topic.description}
            </Typography>
          </Grid>

          {/* Add topic button */}
          <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {!added && (
              learnedWords < minimumWords ? (
                <Tooltip title={minimumWordsTooltip} followCursor arrow placement="right">
                  <LockIcon sx={{ color: '#777777' }} />
                </Tooltip>
              ) : (
                <IconButton onClick={() => addUserTopic(topic)}>
                  <GrAdd />
                </IconButton>
              )
            )}
          </Grid>
        </Grid>

        {/* Progress Bar */}
        <ProgressBar t={t} added={added} totalWords={totalWords} learnedWords={learnedWords} minimumWords={minimumWords} completeColor={completeColor} minimumWordsTooltip={minimumWordsTooltip} />
      </Grid>
    </Grid>
  );



  return (
    <Card variant="elevation" sx={{ m: 0.5, mt: 0, overflow: 'hidden', border: borderSize, borderColor: borderColor }}>
      {setUserSessionTopic ? (
        <CardActionArea onClick={() => setUserSessionTopic(clickTopic)}>
          {cardContent}
        </CardActionArea>
      ) : (
        <>
          {cardContent}
        </>
      )}
    </Card>
  );
};

export default Topic_Card;
