import React, { useEffect, useState } from "react";
import Dashboard from "../../components/c_Dashboard/Dashboard";
import { Container, CircularProgress } from "@mui/material";
import ExplorePanel from "../p_Explore/ExplorePanel";
import { useAuth } from "../../AuthContext";

import { API, graphqlOperation } from "aws-amplify";
import { createUserTopic } from "../../graphql/mutations";

const apiKey = process.env.REACT_APP_APIGATEWAY_KEY;
const gateway = process.env.REACT_APP_APIGATEWAY_DEV;

const fetchTopicData = async (user, jwtToken) => {
  try {
    const endpoint = `${gateway}dynamo-get-explore-topics`;
    const input = { userId: user.username };

    const response = await fetch(endpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json", "x-api-key": apiKey, "Authorization": jwtToken},
      body: JSON.stringify(input),
    });
    const data = await response.json();
    return JSON.parse(data.body);

  } catch (error) {
    //console.log("error", error);
    return {};
  }
};



const Explore = ({ }) => {
  const { user, jwtToken } = useAuth();

  const [exploreData, setExploreData] = useState(null);
  useEffect(() => {
    if (user) {  // Check if the user object is not null
      const loadData = async () => {
        const data = await fetchTopicData(user, jwtToken); // Assuming user.username is the desired identifier
        setExploreData(data);
      };
      loadData();
    }
  }, [user]); // Depend on user to re-run the effect when user object updates

  
  const addUserTopic = async (topic) => {
    try {
        const userTopicDetails = {
            userId: user.username,
            topicId: topic.id,
            topicStr: topic.name,
            progress: 0,
            vocabularyLink: topic.vocabularyLink,
        };

        // Make the POST request to your API Gateway endpoint
        const response = await fetch(`${gateway}dynamo-create-user-topic`, {
            method: 'POST',
            headers: { 
              "Content-Type": "application/json",
              "x-api-key": apiKey,
              "Authorization": jwtToken
             },
            body: JSON.stringify(userTopicDetails),
        });

        // Check if the response is okay (status code 2xx)
        if (!response.ok) {
            throw new Error('Failed to add user topic');
        }

        const data = await response.json();

        // Update the exploreData to show changes without pulling from database
        const newAvailableTopics = exploreData.available_topics.filter(t => t.id !== topic.id);
        const newUserTopics = [...exploreData.user_topics, topic];
        setExploreData({ ...exploreData, available_topics: newAvailableTopics, user_topics: newUserTopics });

        //console.log('User topic added successfully:', data);

    } catch (error) {
        //console.log("Received error...", error);
    }
};


  return (
    <Container>
      {exploreData ? (
        <>
          <ExplorePanel exploreData={exploreData} addUserTopic={addUserTopic} />
        </>
      ) : (
        <>
          <CircularProgress />
        </>
      )}
    </Container>
  );
};

export default Explore;
