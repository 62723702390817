import React from "react";
import { Container } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";


import StudyPanel from "./StudyPanel";

const Study = ({ }) => {
  const location = useLocation();

  // Set selected word if study panel has been accessed from the home page word list
  let selectedWord = null;
  try {
    const { selectedWordState } = location.state;
    selectedWord = selectedWordState;
  }catch{
    //console.log("no state!");
  }

  return (
    <Container>
      <StudyPanel selectedWord={selectedWord} />
    </Container>
  );
};

export default Study;