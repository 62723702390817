import React, { useState, useEffect } from "react";
import "reactjs-popup/dist/index.css";

import { Typography, Paper, Button, Box, Tooltip } from "@mui/material";
import { DefinitionIcon } from "../../display_languages/LanguageDisplay"

import { useAuth } from "../../AuthContext";
import { useTranslation } from "../../display_languages/UseTranslation";

import StudyResources from "../p_Study/StudyResources";

const apiKey = process.env.REACT_APP_APIGATEWAY_KEY;
const gateway = process.env.REACT_APP_APIGATEWAY_DEV;

export default function Session_Helper({ word, userWordData, addWordsForgot, wordsForgot, calculateColour, selectedResource }) {

  const t = useTranslation();
  const {jwtToken} = useAuth();

  const { user, displayLanguage } = useAuth();

  const [definition, setDefinition] = useState("");
  useEffect(() => {
    const fetchDefinition = async () => {
      setDefinition("");

      const parameters = {
        word: userWordData[word]?.id,
        displayLanguage: displayLanguage
      };

      const endpoint = `${gateway}inference-get-definition`;
      const requestBody = {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          "x-api-key": apiKey,
          "Authorization": jwtToken
         },
        body: JSON.stringify(parameters),
      };

      try {
        const response = await fetch(endpoint, requestBody);
        const data = await response.json();

        setDefinition(data.body);

      } catch (err) {
        //console.log("error:", err.message);
      }
    };

    if (user) {
      fetchDefinition();
    }
  }, [user, word]);

  const wordForgot = wordsForgot.includes(word);
  const wordData = userWordData[word];
  const wordKnown = wordData?.knowledge;
  
  const ScoreText = ({ }) => {
    if (wordForgot) {
      return (
        <Typography variant="body2" sx={{ mb: 2, color: '#FB696A' }}>
          {t.session?.forgot}
        </Typography>
      );

    } else if (wordKnown) {
      return (
        <Tooltip title="The learning urgency of the word. Higher values mean the word is less known and needs more review." arrow placement="bottom">
          <Typography variant="body2" sx={{ mb: 2, color: '#000000' }}>
            Scorelex: <span style={{color:calculateColour(wordData, selectedResource)}}><strong>{wordData?.scorelex.scorelex.toFixed(2)}</strong></span>
          </Typography>
        </Tooltip>
      );

    } else {
      return (
        <Typography variant="body2" sx={{ mb: 2, color: '#6CCC8D' }}>
          {t.session?.new}
        </Typography>
      );
    }
  }



  let formattedDefinition;
  // Split the definition into lines
  if (definition) {
    const lines = definition.split('\n');
    // Render each line, followed by a <br />, except the last line
    formattedDefinition = lines.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < lines.length - 1 && <br />}
      </React.Fragment>
    ));
  } else {
    formattedDefinition = t.session?.definition_none;
  }


  const [selectedResourceId, setSelectedResourceId] = useState(null);
  const handleSelectResource = (resourceId, index) => {
    if (resourceId == selectedResourceId) {
      setSelectedResourceId(null);
    } else {
      setSelectedResourceId(resourceId);
    }
  }

  return (
    <Paper elevation={2} sx={{ p: 1 }}>
      {word ?
        <>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {word}
          </Typography>

          <ScoreText />

          {/* Definition */}
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {t.session?.definition}
            </Typography>
            <DefinitionIcon language={displayLanguage} />
          </Box>
          <Box sx={{ border: 1, borderColor: '#e4e7ec', px: 1, py: 2, borderRadius: 1, backgroundColor: '#F5F8FA', overflowY: 'auto', maxHeight: 400 }}>
            <Typography variant="body1" sx={{ textAlign: 'left' }}>
              {formattedDefinition}
            </Typography>
          </Box>

          {wordKnown &&
            <>
              <Typography variant="body1" sx={{ fontWeight: "bold", textAlign: 'left', mt: 2 }}>
                {t.session?.resources}
              </Typography>
              <Box sx={{ mb: 2 }}>
                <StudyResources small selectedSessionId={wordData.id} seselctedSessionResource={selectedResource.id} selectedResourceId={selectedResourceId} group={"word"} handleSelectResource={handleSelectResource} handleGroupChange={null} handleSelectSession={null} />
              </Box>

              <Button disabled={wordForgot} variant="contained" fullWidth className={wordForgot ? "btn-disabled" : "btn-evil"} onClick={() => addWordsForgot(word)}>
                {t.session?.forgot_button}
              </Button>
            </>
          }
        </>
        :
        <Typography variant="body2">
          {t.session?.no_word}
        </Typography>
      }
    </Paper>

  );
};