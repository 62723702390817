import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Typography,
  Container,
  Button
} from "@mui/material";

import './landing.css';



import useContentful from "../../contentful/useContentful";

const ArticleScroll = () => {
  const [articles, setArticles] = useState([]);
  const { getArticlesLimit } = useContentful();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticles = async () => {
      const recentArticles = await getArticlesLimit({ limit: 4 });
      setArticles(recentArticles);
    };

    fetchArticles();
  }, []);

  const handleArticleClick = (id) => {
    navigate(`/article/${id}`);
  };

  const handleViewAllClick = () => {
    navigate('/articles');
  };

  
  const renderThumbnail = (article) => {

    let mediaUrl;
    const mediaType = article.media.fields.file.contentType;

    if (mediaType.startsWith("video")) {
      mediaUrl = article.thumbnail.fields.file.url;

    } else if (mediaType.startsWith("image")) {
      mediaUrl = article.media.fields.file.url;
    }

    return (
      <CardMedia
        component="img"
        sx={{height: 140}}
        image={mediaUrl}
        alt={article.title}
      />
    );
  };
  
  return (
    <Box sx={{
      pb: 4,
      background: "linear-gradient(to bottom, #287293, #2c9dab)"
    }}>
      <Container className="fadein5">

        {/* Title */}
        <Typography
          variant="h4"
          component="h4"
          gutterBottom
          align="center"
          onClick={() => handleViewAllClick()}
          sx={{
            color: "white",
            fontFamily: 'Open Sans, sans-serif',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
        >
          Latest Articles
        </Typography>

        <Grid container spacing={2}>
          {articles.length > 0 ? (
            articles.map((article, index) => (
              <Grid item xs={12} sm={8} md={6} lg={3} key={index}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#2A2A38',
                    transition: 'transform 0.1s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.0125)',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)'
                    }
                  }}
                  onClick={() => handleArticleClick(article.id)}
                >
                  <CardActionArea sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    {renderThumbnail(article)}
                    <CardContent sx={{ px: 1, py: 0, m: 0, flexGrow: 1, width: '100%' }}>
                      <Typography variant="h6" component="h3" gutterBottom sx={{ textAlign: 'left', color: '#ffffff', pt: 0.5, fontFamily: 'Open Sans, sans-serif', lineHeight: 1.2 }}>
                        {article.title}
                      </Typography>

                      <Typography variant="body2" sx={{ mb: 1, color: '#A4A4C4', textAlign: 'left', fontFamily: 'Open Sans, sans-serif' }}>
                        {article.media.sys.createdAt.split("T")[0]}
                      </Typography>

                      <Typography variant="body2" sx={{ mb: 2, color: '#ffffff', textAlign: 'left', fontFamily: 'Open Sans, sans-serif' }}>
                        {article.abstract.content[0].content[0].value}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))) : (
            <Typography variant="h6" color="textSecondary" align="center">
              No articles available.
            </Typography>
          )}
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="text"
            size="large"
            sx={{ color: 'white' }}
            onClick={() => handleViewAllClick()}
          >
            view all
          </Button>
        </Box>

      </Container>
    </Box>
  );
};

export default ArticleScroll;
