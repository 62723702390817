import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActionArea,
  Box,
  CardMedia,
  Typography,
  Container,
  CircularProgress,
} from "@mui/material";

import './landing.css';

import Navbar from "./Navbar";

import vocabuaLM from './vocabuaLM.png';
import progression from './progression.png';
import topics from './topics.png';
import scorelex from './scorelex.png';

const features = [
  {
    title: "VocabuaLM",
    image: vocabuaLM,
    description: "Vocabua generates reading material with a custom language model which understands your learning needs. Your reading material will contain specifically the words you know and need to study.",
    content: "Vocabua teaches language by generating comprehensible reading material with our custom language model. Through a combination of supervised fine-tuning and a custom inference algorithm, VocabuaLM generates cohesive text only containing words known to the learner, while encouraging the inclusion of words due for study. Generated content is suited to learner interests. Our method intentionally forgoes the rigid accuracy of traditional word forcing and blocking techniques with constrained beam search. Instead, we prioritise text cohesiveness and inference speed, even with a highly restricted user lexicon. Combined with our Scorelex algorithm for tracking word knowledge and study need, this system underpins the language learning experience provided by Vocabua.",
    colour: '#1e88e5',
    colourSecondary: '#bcdcf7',
  },
  {
    title: "Scorelex",
    image: scorelex,
    description: "Knowledge level and study need of every word in your vocabulary is calculated with a custom algorithm, making sure you are spending your time practicing the right things.",
    content: "Based on the traditional spaced repetition system, the Scorelex algorithm assigns a learning need value to every word in your vocabulary. Fully integrated with VocabuaLM, newly learned and forgotten words will show up more often in your reading material, while well-known words become less prioritised. Unlike traditional spaced repetition flashcard systems, you will encounter words in various contexts always suited to your level, helping you gain a deeper understanding of each word. As you keep using Vocabua, new words are gradually introduced, naturally expanding your vocabulary and enabling you to read increasingly complex material.",
    colour: '#03a9f4',
    colourSecondary: '#bdeafe',
  },
  {
    title: "Topics",
    image: topics,
    description: "Progress through various topics and get content tailored to your interests, language certifications, study subjects, or daily life. Every topic has new words to learn.",
    content: "Topics dictate the type of reading material you learn with. Various topics are available, allowing you to tailor your learning to your interests, ranging from fictional stories to space. Each topic has a list of words to learn, enabling you to specialize your language ability to your personal needs. As you progress through your topics and complete their word lists, you will unlock progressively more difficult topics. Vocabua also offers topics based on language certifications, helping you practice the specific material required for your exams.",
    colour: '#26c6da',
    colourSecondary: '#bdeef4',
  },
  {
    title: "Progression Analytics",
    image: progression,
    description: "View data on every word you\'ve in your vocabulary and watch your language proficiency grow over time.",
    content: "Vocabua comes equipped with various interactive analytics visualising your language progression. With multiple ways to see a breakdown your daily learning, the progress of your topics, and how well you know each individual word, you will never feel burnt out from a percieved lack of progress.",
    colour: '#72dabd',
    colourSecondary: '#c0eee1',
  },
];

const FeaturesPage = () => {

  return (
    <Box sx={{ flex: "1 0 auto", pt: 4, minHeight: "calc(100vh - 24px)", background: "linear-gradient(to bottom, black, #074e70)" }}>
      <Navbar />

      <Container>
        <Typography
          variant="h3"
          component="h2"
          gutterBottom
          align="center"
          sx={{ color: "white", fontFamily: 'Open Sans, sans-serif' }}
        >
          Features
        </Typography>

        {features.map((feature, index) => (
          <Grid container sx={{ mb: 12 }} key={index} alignItems="center">
            {index % 2 === 0 ? (
              <>
                <Box sx={{ backgroundColor: 'rgba(255,255,255,0.05)', display: 'flex' }}>
                  <Grid container>
                    <Grid item xs={6}>
                      {/* Image */}
                      <CardMedia
                        component="img"
                        image={feature.image}
                        alt={feature.title}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Box>
                        <CardContent>
                          {/* Title */}
                          <Typography variant="h4" component="h3" gutterBottom sx={{ color: feature.colour, fontFamily: 'Open Sans, sans-serif', textAlign: 'left' }}>
                            <strong>{feature.title}</strong>
                          </Typography>
                          {/* Description */}
                          <Typography variant="h6" component="p" sx={{ color: feature.colourSecondary, fontFamily: 'Open Sans, sans-serif', textAlign: 'left' }}>
                            <i>{feature.description}</i>
                          </Typography>
                        </CardContent>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Grid container>
                  <Grid item xs={12}>
                    {/* Content */}
                    <Typography variant="body1" component="p" sx={{ color: "white", fontFamily: 'Open Sans, sans-serif', textAlign: 'left', mt: 2 }}>
                      {feature.content}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Box sx={{ backgroundColor: 'rgba(255,255,255,0.05)', display: 'flex' }}>
                  <Grid container>
                    <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Box>
                        <CardContent>
                          {/* Title */}
                          <Typography variant="h4" component="h3" gutterBottom sx={{ color: feature.colour, fontFamily: 'Open Sans, sans-serif', textAlign: 'right' }}>
                            <strong>{feature.title}</strong>
                          </Typography>
                          {/* Description */}
                          <Typography variant="h6" component="p" sx={{ color: feature.colourSecondary, fontFamily: 'Open Sans, sans-serif', textAlign: 'right' }}>
                            <i>{feature.description}</i>
                          </Typography>
                        </CardContent>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      {/* Image */}
                      <CardMedia
                        component="img"
                        image={feature.image}
                        alt={feature.title}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Grid container>
                  <Grid item xs={12}>
                    {/* Content */}
                    <Typography variant="body1" component="p" sx={{ color: "white", fontFamily: 'Open Sans, sans-serif', textAlign: 'left', mt: 2 }}>
                      {feature.content}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        ))}
      </Container>
    </Box>
  );
};

export default FeaturesPage;
