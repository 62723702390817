import React, { useState } from "react";
import { AppBar, Toolbar, Button, Box, Typography, Dialog, DialogTitle, DialogContent } from "@mui/material";
import { ReactComponent as Vocabua_White } from "../../media/images/vocabua-white.svg";
import { Navigate, useNavigate, Link } from "react-router-dom";
import { AuthProvider } from "../../AuthContext";
import "../../styling/btn_gradient.css";
import VocabuaGradient2 from "../../components/c_Styling/VocabuaLogo_Gradient2";

const Navbar = () => {
  const [buttonText, setButtonText] = useState("Launch Beta");
  const [warningDialog, setWarningDialog] = useState(false);

  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleFeaturesClick = () => {
    navigate("/features");
  };

  const handleArticlesClick = () => {
    navigate("/articles");
  };

  const handleAppClick = () => {
    if (window.innerWidth < 1004) {
      setWarningDialog(true);
    } else {
      navigate("/app")
    }
  };

  return (
    <>
      <AppBar position="fixed" sx={{ width: "100%", backgroundColor: "black" }}>
        <Toolbar>
          <Typography variant="h6">
            <Link to="/">
              <Vocabua_White width="200px" height="auto" onClick={handleLogoClick} />
            </Link>
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button color="inherit" onClick={() => handleFeaturesClick()}>Features</Button>
            <Button color="inherit" onClick={() => handleArticlesClick()}>
              Articles
            </Button>
            {/* <Button color="inherit">Pricing</Button> */}

            {/* Change to register / sign in / launch app based on auth context */}
            {/* <Button color="inherit">Sign In</Button> */}
            <Button
              variant="contained"
              size="large"
              href="#signin"
              sx={{
                backgroundColor: "white",
                color: "black",
                minWidth: 150,
                transition: "all 0.3s ease",
                "&:hover": {
                  backgroundSize: "300% 300%",
                  backgroundImage:
                    "linear-gradient(-45deg, #73d9bd, #29c6db 45%, #06a8f4 70%, #2488e4 100%)",
                  animation: "AnimateBG 5s ease infinite",
                  color: "white",
                },
                "@keyframes AnimateBG": {
                  "0%": { backgroundPosition: "0% 50%" },
                  "50%": { backgroundPosition: "100% 50%" },
                  "100%": { backgroundPosition: "0% 50%" },
                },
              }}
              onMouseEnter={() => setButtonText("Launch Beta")}
              onMouseLeave={() => setButtonText("Launch Beta")}
              onClick={handleAppClick}
            >
              {buttonText}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      <Dialog open={warningDialog} onClose={() => setWarningDialog(false)}>
        <DialogTitle sx={{ textAlign: "center" }}>Warning!</DialogTitle>
        <DialogContent>
          <Typography sx={{ textAlign: "center", mb: 2 }}>
            Your screen resolution is smaller than what we currently support. This will make Vocabua difficult to use.
          </Typography>
          <Typography sx={{ textAlign: "center", mb: 4 }}>
            We are working on making Vocabua compatible with your device soon.
          </Typography>

          <Button
            fullWidth
            variant="contained"
            onClick={() => navigate("/app")}
            sx={{ backgroundColor: "#1E88E5" }}
          >
            Proceed Anyway
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Navbar;
