import Stories from "../../media/images/stories.png";
import Greetings from "../../media/images/greetings.png";
import Space from "../../media/images/space.png";

const images = {
    "Stories": Stories,
    "Greetings": Greetings,
    "Space": Space,
}

const names = {
    "123": "Stories",
    "223": "Greetings",
    "323": "Space"
}

export default function TopicData(query, key) {
    let result;
    if (query === "name") {
        result = {
            "name": key,
            "image": images[key]
        };
    } else if (query === "id") {
        result = {
            "name": names[key],
            "image": images[names[key]]
        };
    }

    return result;
}