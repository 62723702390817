import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Paper, Typography, Box, Button } from "@mui/material";

import { useTranslation } from "../../display_languages/UseTranslation";
import { useAuth } from "../../AuthContext";

const apiKey = process.env.REACT_APP_APIGATEWAY_KEY;
const gateway = process.env.REACT_APP_APIGATEWAY_DEV;

const Session_Finished = ({userId, sessionData, userWordData, wordsNew, wordsForgot, wordsChecked, wordsViewed, updateSelectedWord}) => {
  const navigate = useNavigate();

  const t = useTranslation();
  const {jwtToken} = useAuth();

  const [buttonContent, setButtonContent] = useState(t.session?.submit);
  useEffect(() => {
    setButtonContent(t.session?.submit);
  }, [t.session?.submit]);

  const handleFinishSessionClick = async () => {
  

    setButtonContent(
      <>
        <CircularProgress size={25} sx={{mr:1}}/>{t.session?.saving}
      </>
    )
    
    const input = {
      'userId': userId,
      'sessionData': sessionData,
      'userWordData': userWordData,
      'wordsNew': wordsNew,
      'wordsForgot': wordsForgot,
      'wordsChecked': wordsChecked,
      'wordsViewed': wordsViewed
    }
    //console.log(input);

    try {
      const response = await fetch(
        `${gateway}scorelex-finish-session`,
        {
          method: "POST",
          headers: { 
            "Content-Type": "application/json",
            "x-api-key": apiKey,
            "Authorization": jwtToken
           },
          body: JSON.stringify(input),
        }
      );

      const data = await response.json();
      //console.log("Data from endpoint:", data);
      navigate("/app");
    } catch (error) {
      console.error("Error submitting session Data:", error);
    }
  };

  const WordList = ({ words, colour, emptymessage }) => {
    if (words.length == 0) {
      return (
        // Empty message
        <Typography variant="caption">
          {emptymessage}
        </Typography>
      )
    };

    return (
      words.map((word) => (
        <Button
          onClick={() => updateSelectedWord(word)}
          key={word}
          variant="contained"
          size="small"
          sx={{ backgroundColor: colour, mx: 0.5, my: 0.5, textTransform: 'none' }}
        >
          {word}
        </Button>
      ))
    );
  }

  return (
    <React.Fragment>

      <Paper elevation={2} sx={{ p: 1 }}>

        {/* New Words Learned */}
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="subtitle1" fontWeight="bold">
            {t.session?.finish_new}
          </Typography>
          <Box sx={{ border: 1, borderColor: '#e4e7ec', px: 0.5, py: 0.5, borderRadius: 1, backgroundColor: '#F5F8FA', overflowY: 'auto', maxHeight: 400 }}>
            <WordList words={wordsNew} colour={"#6CCC8D"} emptymessage={t.session?.finish_new_none} />
          </Box>
        </Box>

        {/* Words Got Wrong */}
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="subtitle1" fontWeight="bold">
            {t.session?.finish_forgotten}
          </Typography>
          <Box sx={{ border: 1, borderColor: '#e4e7ec', px: 0.5, py: 0.5, borderRadius: 1, backgroundColor: '#F5F8FA', overflowY: 'auto', maxHeight: 400 }}>
            <WordList words={wordsForgot} colour={"#FB696A"} emptymessage={t.session?.finish_forgotten_none} />
          </Box>
        </Box>

        {/* Words Looked Up */}
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="subtitle1" fontWeight="bold">
            {t.session?.finish_checked}
          </Typography>
          <Box sx={{ border: 1, borderColor: '#e4e7ec', px: 0.5, py: 0.5, borderRadius: 1, backgroundColor: '#F5F8FA', overflowY: 'auto', maxHeight: 400 }}>
            <WordList words={wordsChecked} colour={"#FFB766"} emptymessage={t.session?.finish_checked_none} />
          </Box>
        </Box>

        {/* Total Words Seen */}
        <Box sx={{ marginBottom: 0 }}>
          <Typography variant="subtitle1" fontWeight="bold">
            {t.session?.finish_remaining}
          </Typography>
          <Box sx={{ border: 1, borderColor: '#e4e7ec', px: 0.5, py: 0.5, borderRadius: 1, backgroundColor: '#F5F8FA', overflowY: 'auto', maxHeight: 400 }}>
            <WordList words={wordsViewed} colour={"#1E88E5"} emptymessage={t.session?.finish_remaining_none} />
          </Box>
        </Box>
      </Paper>

      {/* Finish Button */}
      <Box>
        <Button variant="contained" className="btn" onClick={() => handleFinishSessionClick()} sx={{ mt: 1, py: 2, width: '100%', textTransform: 'none', fontWeight: 'bold' }}>
          {buttonContent}
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default Session_Finished;