import React, { useState, useEffect } from "react";
import { CircularProgress, Container, Typography, Stack, List, ListItem, ListItemText, Box, Tooltip, IconButton, Grid, Card } from "@mui/material";
import { FaInfo } from "react-icons/fa";

import HomeWordList from "./HomeWordList";
import { useTranslation } from "../../display_languages/UseTranslation";


export default function NewWords({ homepageData, containerHeight, loading }) {
    const t = useTranslation();

    const [words, setWords] = useState(null);
    useEffect(() => {
        if (homepageData != null) {
            setWords(homepageData['new_words']);
        }
    }, [homepageData]);

    return (
        <Container sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <Grid container alignItems="left" justifyContent="left">
                <Grid item xs={11}>
                    <Typography sx={{ ml: 6, mt: 0.5 }} component="h1" variant="h5">{t.home?.newwords}</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Tooltip title={t.home?.newwords_info}>
                        <IconButton sx={{ "&:hover": { color: "#1976d2" } }}>
                            <FaInfo />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            
            {loading ?
                <Box>
                    <CircularProgress />
                </Box>
                :
                words ?
                    words.length === 0 ?
                        <Typography variant="caption">{t.home?.newwords_none}</Typography>
                        :
                        <HomeWordList words={words} containerHeight={containerHeight} />
                    :
                    <Box>
                        <CircularProgress />
                    </Box>

            }
        </Container>
    );
}