import React, { useEffect, useState } from "react";

import { useTranslation } from "../../display_languages/UseTranslation";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, TextField, Typography, Alert, Snackbar } from '@mui/material';

import SnackbarComponent from "../c_Utils/Snackbar";

import { useNavigate } from "react-router-dom";

import { Amplify } from "aws-amplify";

import { useAuth } from "../../AuthContext";

const SignIn = () => {

  const t = useTranslation();
  const { checkCurrentUser } = useAuth();

  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showForgotPasswordDialog, setShowForgotPasswordDialog] = useState(false);
  const handleOpenForgotPasswordDialog = () => setShowForgotPasswordDialog(true);
  const handleCloseForgotPasswordDialog = () => setShowForgotPasswordDialog(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const signIn = async () => {
    try {
      await Amplify.Auth.signIn(email, password);
      checkCurrentUser();
      navigate("/app", {});

    } catch (error) {
      if (error.message === "User is not confirmed.") {
        navigate("/authentication", {
          state: {
            userEmail: email,
          },
        });
      } else {
        setSnackbarInfo({
          open: true,
          message: t.profile?.signin_failure,
          severity: "error"
        });
      }
    }
  };

  const handleForgotPasswordCode = async () => {
    if (email == "") {
      setSnackbarInfo({
        open: true,
        message: t.register?.forgotpassword_email,
        severity: "error"
      });
      return;
    }

    try {
      await Amplify.Auth.forgotPassword(email);
      handleOpenForgotPasswordDialog(); // Close the dialog
    }
    catch (error) {
      setSnackbarInfo({
        open: true,
        message: t.forgotpassword_fail,
        severity: "error"
      });
    }
  };

  const handlePasswordUpdate = async () => {
    if (newPassword !== confirmPassword) {
      setSnackbarInfo({
        open: true,
        message: t.profile?.password_nomatch,
        severity: "error"
      });
      return;
    }

    try {
      await Amplify.Auth.forgotPasswordSubmit(email, code, newPassword);
      setSnackbarInfo({
        open: true,
        message: t.profile?.password_success,
        severity: "success"
      });
      handleCloseForgotPasswordDialog(); // Close the dialog
    }
    catch (error) {
      setSnackbarInfo({
        open: true,
        message: t.profile?.password_failure,
        severity: "error"
      });
    }
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            //marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            autoFocus
            id="email"
            label={t.profile?.email}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            autoFocus
            id="password"
            label={t.profile?.password}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end", // Aligns the button to the right
              width: "100%", // Ensures the Box takes full width
            }}
          >
            <Button
              type="submit"
              variant="text"
              onClick={handleForgotPasswordCode}
            >
              {t.register?.forgotpassword}
            </Button>
          </Box>


          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={signIn}
          >
            {t.register?.signin}
          </Button>
        </Box>
      </Container>


      {/* Forgot Password Dialog */}
      <Dialog open={showForgotPasswordDialog} onClose={handleCloseForgotPasswordDialog}>
        <DialogTitle>{t.register?.forgotpassword}</DialogTitle>
        <Typography variant="body1" ml={3} gutterBottom>
          {t.register?.forgotpassword_info}
        </Typography>

        <DialogContent>
          <TextField
            margin="normal"
            required
            fullWidth
            label={t.register?.code}
            value={code}
            onChange={(e) => setCode(e.target.value)}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label={t.profile?.password_new}
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label={t.profile?.password_confirm}
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForgotPasswordDialog}>{t.misc?.cancel}</Button>
          <Button variant="contained" onClick={handlePasswordUpdate}>
            {t.misc?.update}
          </Button>
        </DialogActions>
      </Dialog>

      <SnackbarComponent snackbarInfo={snackbarInfo} setSnackbarInfo={setSnackbarInfo} />
    </>
  );
};

export default SignIn;