import React from "react";
import { AppBar, ListItemIcon, Tooltip, Toolbar, Typography, Radio, RadioGroup, FormControlLabel, FormControl, Checkbox, FormGroup, IconButton, Grid, Paper, Box, Slider } from '@mui/material';

import { FaInfo } from "react-icons/fa";

const BasicToolbar = ({icon, title, info}) => {
    return (
        <Box sx={{ flexGrow: 1, mb: 2.25 }}>
            <AppBar position="fixed" sx={{ "backgroundColor": "#ffffff", "boxShadow": 2, pl: '220px' }}>
                <Toolbar sx={{ my: -1.5 }}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        {/* Left empty grid item for balance (optional) */}
                        <Grid item xs={false} sm={3} />

                        {/* Center content: Icon and Typography */}
                        <Grid item xs={6} sm={6} container alignItems="center" justifyContent="center">
                            <ListItemIcon sx={{ fontSize: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {icon}
                            </ListItemIcon>
                            <Typography variant="h5" sx={{ color: 'black' }}>
                                {title}
                            </Typography>
                        </Grid>

                        {/* Right content: Info Icon */}
                        <Grid item xs={6} sm={3} container justifyContent="flex-end">
                            <Tooltip title={info}>
                                <IconButton sx={{ "&:hover": { color: "#1976d2" } }}>
                                    <FaInfo />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
export default BasicToolbar;