import React, { useEffect, useState } from "react";

import { useTranslation } from "../../display_languages/UseTranslation";

import { Button, TextField } from "@mui/material";
import { Amplify } from "aws-amplify";
import { useNavigate, useLocation } from "react-router-dom";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

const UserAuthenticate = () => {

  const t = useTranslation();

  const [code, setCode] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  const email = location.state.userEmail;

  const authenticate = async () => {
    try {
      await Amplify.Auth.confirmSignUp(email, code);
      navigate("/signin_register");
    } catch (error) {
      //console.log("Authentication error > ", error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          autoFocus
          id="authenticationCode"
          label={t.register?.authentication}
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />

        <Button
          id="authenticateButton"
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={authenticate}
        >
          {t.register?.authentication_button}
        </Button>
      </Box>
    </Container>
  );
};

export default UserAuthenticate;