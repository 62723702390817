import logo from "./logo.svg";
import "./App.css";

// REACT IMPORTS
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { LanguageProvider } from "./display_languages/LanguageContext";


// AMPLIFY IMPORTS
import { Amplify, Auth } from "aws-amplify";
import awsconfig from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";

// PAGE IMPORTS
import SignInRegister from "./components/c_UserAuthentication/SignIn_Register";
import Home from "./pages/p_Home/home";
import UserProfile from "./pages/p_Profile/UserProfile";
import UserAuthenticate from "./components/c_UserAuthentication/UserAuthenticate";
import Learn from "./pages/p_Learn/learn";
import Study from "./pages/p_Study/study";
import Explore from "./pages/p_Explore/explore";
import Admin from "./pages/p_Admin/admin";
import Session from "./pages/p_Session/session";
import Progression from "./pages/p_Progression/progression";
import LandingPage from "./frontend_website/LandingPage";
import Article from "./frontend_website/components/Article";
import Articles from "./frontend_website/components/Articles";
import FeaturesPage from "./frontend_website/components/FeaturesPage";

import { useAuth } from "./AuthContext";

import Box from "@mui/material/Box";

// SYLING IMPORTS
import "./styling/background.css";
import SideNavPanel from "./components/c_Navigation/SideNavPanel";

Amplify.configure(awsconfig);


const AppContent = ({ user, loggedIn, checkCurrentUser, setLoggedIn }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const hideSidebarPaths = ["/", "/article/", "/articles", "/features", "/"];
  const isArticlePath = location.pathname.match(/^\/article\/[a-zA-Z0-9_-]+$/);
  const showSidebar = !hideSidebarPaths.includes(location.pathname) && !isArticlePath;

  const signOut = async () => {
    try {
      //console.log("Attempting to sign out user...", { user });
      await Auth.signOut();
      checkCurrentUser();
      setLoggedIn(false);
      navigate('/')
      //console.log("Successfully signed out...");
    } catch (error) {
      //console.log("Error signing out... ", error);
    }
  };
  
  return (
    <div className="App">
      <br />
      <Box sx={{ display: "flex" }}>
        {showSidebar && user && <SideNavPanel signOut={signOut} />}
        <Routes>
          <Route path="/app" element={<Home />} />
          <Route path="/learn" element={<Learn user={user} />} />
          <Route path="/study" element={<Study user={user} />} />
          <Route
            path="/explore"
            element={<Explore loggedIn={loggedIn} user={user} />}
          />
          <Route path="/admin" element={<Admin user={user} />} />
          <Route path="/userprofile" element={<UserProfile user={user} />} />
          <Route path="/signin_register" element={<SignInRegister />} />
          <Route path="/authentication" element={<UserAuthenticate />} />
          <Route path="/session/:sessionId" element={<Session />} />
          <Route path="/progression" element={<Progression user={user} />} />

          <Route path="/" element={<LandingPage />} />
          <Route path="/article/:id" element={<Article />} />
          <Route path="/articles" element={<Articles />} />

          <Route path="/features" element={<FeaturesPage />} />
        </Routes>
      </Box>
    </div>
  );
};

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const { user, checkCurrentUser } = useAuth();
  

  useEffect(() => {
    assessLoggedInState();
  }, []);

  const assessLoggedInState = () => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        setLoggedIn(true);
      })
      .catch(() => {
        setLoggedIn(false);
      });
  };



  return (
    <LanguageProvider>
      <Router>
        <AppContent user={user}loggedIn={loggedIn} checkCurrentUser={checkCurrentUser} setLoggedIn={setLoggedIn}/>
      </Router>
    </LanguageProvider>
  );
}

export default App;