import React, { useState } from "react";
import { Box, Typography, Button, Container, Dialog, DialogTitle, DialogContent } from "@mui/material";
import { ReactComponent as Vocabua_White } from "../../media/images/vocabua-white.svg";
import { useNavigate } from "react-router-dom";

import "./landing.css";

const Hero = () => {
  const [buttonText, setButtonText] = useState("Launch Beta");
  const [warningDialog, setWarningDialog] = useState(false);
  const navigate = useNavigate();

  const handleAppClick = () => {
    if (window.innerWidth < 1004) {
      setWarningDialog(true);
    } else {
      navigate("/app")
    }
  };

  return (
    <>
    <Box
      sx={{
        height: "60vh",
        width: "100vw",
        position: "relative",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        textAlign: "center",
        padding: 2,
        background: "linear-gradient(to bottom, #000000, #164874)",
      }}
    >
      <Container>
        <br />
        <br />

        {/* Title */}
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          className="fadein1"
          sx={{ fontFamily: "Open Sans, sans-serif" }}
        >
          Welcome to{" "}
          <Box
            component="span"
            sx={{
              display: "inline-flex",
              alignItems: "center",
              position: "relative",
              top: "0.1em", // Adjust this value as needed
            }}
          >
            <Vocabua_White width="auto" height="auto" />
          </Box>
        </Typography>
        {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScBwl4mTwmHoSrWOG8leK1L8dcImS5hOfmX0Opkw0YkU-VcfA/viewform?embedded=true" width="640" height="530" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}

        {/* Subtitle */}
        <Typography
          variant="h5"
          component="p"
          gutterBottom
          className="fadein2"
          sx={{ fontFamily: "Open Sans, sans-serif" }}
        >
          Your language acquisition journey begins here.
        </Typography>
        <br />

        {/* Button */}
        <Container className="fadein3">
          <Button
            variant="contained"
            size="large"
            href="#signin"
            sx={{
              backgroundColor: "white",
              color: "black",
              minWidth: 150,
              transition: "all 0.3s ease",
              "&:hover": {
                backgroundSize: "300% 300%",
                backgroundImage:
                  "linear-gradient(-45deg, #73d9bd, #29c6db 45%, #06a8f4 70%, #2488e4 100%)",
                animation: "AnimateBG 5s ease infinite",
                color: "white",
              },
              "@keyframes AnimateBG": {
                "0%": { backgroundPosition: "0% 50%" },
                "50%": { backgroundPosition: "100% 50%" },
                "100%": { backgroundPosition: "0% 50%" },
              },
            }}
            onMouseEnter={() => setButtonText("Launch Beta")}
            onMouseLeave={() => setButtonText("Launch Beta")}
            onClick={handleAppClick}
          >
            {buttonText}
          </Button>
        </Container>
      </Container>
    </Box>
    <Dialog open={warningDialog} onClose={() => setWarningDialog(false)}>
    <DialogTitle sx={{ textAlign: "center" }}>Warning!</DialogTitle>
    <DialogContent>
      <Typography sx={{ textAlign: "center", mb: 2 }}>
        Your screen resolution is smaller than what we currently support. This will make Vocabua difficult to use.
      </Typography>
      <Typography sx={{ textAlign: "center", mb: 4 }}>
        We are working on making Vocabua compatible with your device soon.
      </Typography>

      <Button
        fullWidth
        variant="contained"
        onClick={() => navigate("/app")}
        sx={{ backgroundColor: "#1E88E5" }}
      >
        Proceed Anyway
      </Button>
    </DialogContent>
  </Dialog>
  </>
  );
};

export default Hero;
