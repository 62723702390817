import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import chroma from 'chroma-js';

import { Grid } from "@mui/material";

import Session_Inference from "./Session_Inference";
import Session_Finished from "./SessionFinished";
import Session_Helper from "./Session_Helper";
import Resource_List from "./Resource_List";

export default function SessionPanel({ userId, sessionData, userWordData, finishedLoading }) {

  const resources = sessionData.resources;

  const [selectedResource, setSelectedResource] = useState(resources[0]);
  const [finishSession, setFinishSession] = useState(false);
  const [selectedWord, setSelectedWord] = useState("");

  const [wordsViewed, setWordsViewed] = useState([]);
  const [wordsChecked, setWordsChecked] = useState([]);
  const [wordsForgot, setWordsForgot] = useState([]);
  const [wordsNew, setWordsNew] = useState([]);

  // First open resource is viewed by default
  resources[0].viewed = true;

  // Calculate the colour based on the scorelex. min scorelex = black, max scorelex = blue.
  const calculateColour = (wordData, resource) => {
    const scorelexColour = chroma.scale(['rgb(0, 0, 0)', 'rgb(30, 136, 229)', 'rgb(3, 169, 244)']);

    if (wordData.knowledge) {
      const scorelex = wordData.scorelex.scorelex
      const minScorelex = resource.scorelex.min;
      const maxScorelex = resource.scorelex.max;
      if (minScorelex == maxScorelex) {
        return 'rgb(0,0,0)';
      }
      const normalised = (scorelex - minScorelex) / (maxScorelex - minScorelex)
      return scorelexColour(normalised).hex()
    } else {
      return 'rgb(0,0,0)';
    }
  }

  // Split all words into arrays based on whether they are known or unknown
  const filterWords = () => {
    const knowledgeWords = [];
    const newWords = [];

    Object.keys(userWordData).forEach(word => {
      if (userWordData[word].knowledge) {
        knowledgeWords.push(word);
      } else {
        newWords.push(word);
      }
    });

    setWordsNew(newWords);
    setWordsViewed(knowledgeWords);
  }

  useEffect(() => {
    filterWords();
  }, [resources]);

  const addWordsChecked = (word) => {
    const wordKnowledge = userWordData[word].knowledge;
    // Add to words checked if word is known
    if (wordKnowledge) {
      // Only add if not already added
      setWordsChecked((currentList) => {
        if (!wordsChecked.includes(word)) {
          return [...currentList, word];
        } else {
          return currentList;
        }
      });

      // Remove from words viewed
      const filteredWords = wordsViewed.filter(viewedWord => viewedWord !== word);
      setWordsViewed(filteredWords);
    }
  };

  const addWordsForgot = (word) => {
    const wordKnowledge = userWordData[word].knowledge;
    // Add to words checked if word is known
    if (wordKnowledge) {
      // Only add if not already added
      setWordsForgot((currentList) => {
        if (!wordsForgot.includes(word)) {
          return [...currentList, word];
        } else {
          return currentList;
        }
      });

      // Remove from words checked
      const filteredWords = wordsChecked.filter(checkedWord => checkedWord !== word);
      setWordsChecked(filteredWords);
    }
  };

  const updateSessionStatus = useCallback((status) => {
    setFinishSession(status);
  });

  const updateSelectedResource = useCallback((resourceObj) => {
    resourceObj.viewed = true;
    setSelectedResource(resourceObj);
  });

  const updateSelectedWord = useCallback((word) => {
    if (word == selectedWord) {
      setSelectedWord("");
    } else {
      setSelectedWord(word);
    }

    addWordsChecked(word);
  });

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={2}>
        <Resource_List
          selectedResource={selectedResource}
          resources={resources}
          updateSelectedResource={updateSelectedResource}
          updateSessionStatus={updateSessionStatus}
          finishedLoading={finishedLoading}
        />
      </Grid>

      <Grid item xs={7}>
        {finishSession ? (
          <Session_Finished
            userId={userId}
            sessionData={sessionData}
            userWordData={userWordData}
            wordsNew={wordsNew}
            wordsForgot={wordsForgot}
            wordsChecked={wordsChecked}
            wordsViewed={wordsViewed}
            updateSelectedWord={updateSelectedWord}
          />
        ) : (
          <Session_Inference
            selectedResource={selectedResource}
            userWordData={userWordData}
            selectedWord={selectedWord}
            updateSelectedWord={updateSelectedWord}
            wordsForgot={wordsForgot}
            wordsChecked={wordsChecked}
            calculateColour={calculateColour}
          />
        )}
      </Grid>

      <Grid item xs={3}>
        <Session_Helper
          word={selectedWord}
          userWordData={userWordData}
          addWordsForgot={addWordsForgot}
          wordsForgot={wordsForgot}
          calculateColour={calculateColour}
          selectedResource={selectedResource}
        />
      </Grid>
    </Grid>
  );
}
