import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { Container, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, TextField, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import SnackbarComponent from "../../components/c_Utils/Snackbar";
import { useAuth } from '../../AuthContext';


// Import the useLanguage hook from the LanguageContext
import { useLanguage } from '../../display_languages/LanguageContext';
import { useTranslation } from "../../display_languages/UseTranslation";

import BasicToolbar from "../../components/c_Navigation/BasicToolbar";
import { CgProfile } from "react-icons/cg";

import { LanguageList } from "../../display_languages/LanguageDisplay"

const apiKey = process.env.REACT_APP_APIGATEWAY_KEY;
const gateway = process.env.REACT_APP_APIGATEWAY_DEV;

export default function UserProfilePanel() {
    const { user, username, setDisplayLanguage } = useAuth();

    const t = useTranslation();
    const {jwtToken} = useAuth();

    const [showUpdatePasswordDialog, setShowUpdatePasswordDialog] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const handleOpenUpdatePasswordDialog = () => setShowUpdatePasswordDialog(true);
    const handleCloseUpdatePasswordDialog = () => setShowUpdatePasswordDialog(false);
    const [snackbarInfo, setSnackbarInfo] = useState({
        open: false,
        message: '',
        severity: 'success',
    });

    const languageContext = useLanguage(); // Use the hook to get the context object


    const updateLanguage = async (displayLanguage) => {
        const parameters = { 
            userId: user.username,
            displayLanguage: displayLanguage
        }

        const requestBody = {
            method: "POST",
            headers: { 
                "Content-Type": "application/json",
                "x-api-key": apiKey,
                "Authorization": jwtToken
               },
            body: JSON.stringify(parameters),
        };

        try {
            const response = await fetch(
                `${gateway}dynamo-update-profile-language`,
                requestBody
            );
            const data = await response.json();
            //console.log(data);
        } catch (err) {
            //console.log("error:", err.message);
        }
    };

    const handleLanguageChange = (event) => {
        languageContext.setLanguage(event.target.value);
        updateLanguage(event.target.value);
        setDisplayLanguage(event.target.value);
    };


    const handlePasswordUpdate = async () => {
        if (newPassword !== confirmPassword) {
            setSnackbarInfo({
                open: true,
                message: t.profile?.password_nomatch,
                severity: "error"
            });
            return;
        }

        try {
            await Auth.changePassword(user, oldPassword, newPassword);
            setSnackbarInfo({
                open: true,
                message: t.profile?.password_success,
                severity: "success"
            });
            handleCloseUpdatePasswordDialog(); // Close the dialog
        }
        catch (error) {
            setSnackbarInfo({
                open: true,
                message: t.profile?.password_failure,
                severity: "error"
            });
        }
    };


    return (
        <Container>
            <BasicToolbar icon={<CgProfile />} title="Profile" info={t.profile?.info} />
            <Box sx={{ display: 'flex', justifyContent: 'center', }}>
                <Paper elevation={2} sx={{ p: 2, maxWidth: 600 }}>
                    {user && (
                        <>
                            <Grid container spacing={2} alignItems="flex-start">
                                {/* User Info Display */}
                                <Grid item xs={12} container alignItems="center" wrap="nowrap">
                                    <Box sx={{ minWidth: 120, textAlign: 'left' }}>
                                        <Typography variant="body1"><b>{t.profile?.username}:</b></Typography>
                                    </Box>
                                    <Typography variant="body1" sx={{ ml: 1 }}>{username}</Typography>
                                </Grid>

                                <Grid item xs={12} container alignItems="center" wrap="nowrap">
                                    <Box sx={{ minWidth: 120, textAlign: 'left' }}>
                                        <Typography variant="body1"><b>{t.profile?.email}:</b></Typography>
                                    </Box>
                                    <Typography variant="body1" sx={{ ml: 1 }}>{user.attributes.email}</Typography>
                                </Grid>

                                <Grid item xs={12} container alignItems="center" wrap="nowrap" mt={-1}>
                                    <Box sx={{ minWidth: 120, textAlign: 'left' }}>
                                        <Typography variant="body1"><b>{t.profile?.password}:</b></Typography>
                                    </Box>
                                    {/* Update Password Button */}
                                    <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                                        <Typography variant="body1">●●●●</Typography>
                                        <Button variant="text" sx={{ ml: 2 }} onClick={handleOpenUpdatePasswordDialog}>
                                            {t.misc?.update}
                                        </Button>
                                    </Box>

                                    {/* Update Password Dialog */}
                                    <Dialog open={showUpdatePasswordDialog} onClose={handleCloseUpdatePasswordDialog}>
                                        <DialogTitle>{t.profile?.password_update}</DialogTitle>
                                        <DialogContent>
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                label={t.profile?.password_old}
                                                type="password"
                                                value={oldPassword}
                                                onChange={(e) => setOldPassword(e.target.value)}
                                                autoFocus
                                            />
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                label={t.profile?.password_new}
                                                type="password"
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                                autoFocus
                                            />
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                label={t.profile?.password_confirm}
                                                type="password"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleCloseUpdatePasswordDialog}>{t.misc?.cancel}</Button>
                                            <Button variant="contained" onClick={handlePasswordUpdate}>
                                                {t.misc?.update}
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Grid>
                            </Grid>

                            <Box sx={{ minWidth: 120, mt: 3 }}>
                                <LanguageList handleLanguageChange={handleLanguageChange} title={t.profile?.displaylanguage} value={languageContext.language} />
                            </Box>
                        </>
                    )}
                    <SnackbarComponent snackbarInfo={snackbarInfo} setSnackbarInfo={setSnackbarInfo} />
                </Paper>
            </Box>
        </Container>
    );
}