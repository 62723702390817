import React, { useState, useEffect, useRef } from "react";
import { ResponsivePie } from '@nivo/pie';
import { Container, Typography, IconButton, Grid, Button, TextField } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { FaInfo } from "react-icons/fa";
import { FixedSizeList } from 'react-window';
import SearchIcon from '@mui/icons-material/Search';

import { useTranslation } from "../../display_languages/UseTranslation";

const Chart = ({ wordProgress, handlePieSliceClick }) => {
    const customCenteredText = (props) => (
        <CenteredText {...props} wordProgress={wordProgress} />
    );

    return (
        <ResponsivePie
            data={wordProgress.pie}
            onClick={(data) => { handlePieSliceClick(data.id); }}

            margin={{ top: 0, right: 45, bottom: 20, left: 20 }}
            sortByValue={false}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={2}
            activeOuterRadiusOffset={8}
            colors={({ data }) => data.color}
            borderWidth={1}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.2
                    ]
                ]
            }}
            enableArcLinkLabels={false}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        2
                    ]
                ]
            }}
            layers={['arcs', 'arcLabels', 'legends', customCenteredText]}
        />
    );
};

const CenteredText = ({ centerX, centerY, wordProgress }) => {
    return (
        <text
            x={centerX}
            y={centerY}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
                fontSize: '20px',
                fontWeight: 'bold',
                fill: '#555',
            }}
        >
            <tspan x={centerX} dy="-15">{wordProgress.number}</tspan>
            <tspan x={centerX} dy="30">{wordProgress.percentage}</tspan>
        </text>
    );
};

const Words = ({ words, wordProgress, setSearchText, searchText, setDisplayWords, handlePieSliceSearch }) => {
    const t = useTranslation();
    
    <style>
        {`
            .pie-container path:hover {
            cursor: pointer;
            }
        `}
    </style>

    const [localSearchText, setLocalSearchText] = useState("");

    // search the word list for the clicked category
    const handlePieSliceClick = (pieSliceId) => {
        const searchText = "{" + pieSliceId + "}";
        handlePieSliceSearch(searchText);
    };

    const listRef = useRef(null);
    useEffect(() => {
        // Reset the scroll position to the top whenever 'words' changes
        if (listRef.current) {
            listRef.current.scrollToItem(0);
        }
    }, [words]); // Depend on the 'words' prop or state

    const handleSearch = () => {
        setSearchText(localSearchText);
    };
    // Update local state when pieSearchTerm changes
    useEffect(() => {
        setLocalSearchText(searchText || "");
    }, [searchText]);

    const addDisplayWord = (word, status) => {
        setDisplayWords(prevDisplayWords => {
            if (prevDisplayWords.length < 3 && !prevDisplayWords.includes(word) && status != "Unknown") { // Use < 3 to allow only up to 3 words
                return prevDisplayWords.concat(word);
            }
            return prevDisplayWords; // Return the current state if 3 words are already added
        });
    }

    // Component to render each item in the list
    const Row = ({ index, style }) => {
        const wordObject = words[index];
        const word = Object.keys(wordObject)[0];
        const status = wordObject[word]['status'];
        const appears = wordObject[word]['appears']
        let backgroundColor, color;

        const statusDisplay = {
            'Known': t.progression?.words.known,
            'Newly Learned': t.progression?.words.newlylearned,
            'Unknown': t.progression?.words.unknown
        }

        switch (status) {
            case 'Known':
                color = 'rgb(255,255,255)';
                backgroundColor = 'rgb(30,136,229)';
                break;
            case 'Newly Learned':
                color = 'rgb(255,255,255)';
                backgroundColor = 'rgb(114,218,189)';
                break;
            case 'Unknown':
                color = 'rgb(190,190,190)';
                backgroundColor = 'rgb(255,255,255)';
                break;
        }

        const tooltipText = (
            <>
                <Typography color="inherit" sx={{ fontSize: '0.8rem' }}><b>{t.progression?.words.status}</b> {statusDisplay[status]}</Typography>
                <Typography color="inherit" sx={{ fontSize: '0.8rem' }}><b>{t.progression?.words.appearsin}</b> {appears}</Typography>
            </>
        );

        return (
            <div style={style}>
                <Tooltip title={tooltipText} followCursor arrow placement="right">
                    <Button
                        onClick={() => addDisplayWord(word, wordObject[word]['status'])}
                        sx={{
                            backgroundColor,
                            color,
                            justifyContent: 'flex-start',
                            width: '100%',
                            height: '100%',
                            textTransform: 'none',
                            '&:hover': {
                                backgroundColor, // Maintain background color on hover
                                opacity: 0.8 // Slightly decrease opacity for visual effect
                            },
                            '&:focus, &:active': {
                                backgroundColor, // Maintain background color on focus/active
                                color // Maintain text color on focus/active
                            },
                        }}
                        variant="contained"
                    >
                        {word}
                    </Button>
                </Tooltip>
            </div>
        );
    };

    return (
        <Container sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden', px: 0, mx: 0 }}>
            {/* Header */}
            <Grid container width="100%" alignItems="center" justifyContent="center" spacing={0}>
                <Grid item xs={11}>
                    <Typography sx={{ ml: 0, mt: 0.5 }} component="h1" variant="h5">{t.progression?.words.title}</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Tooltip title={t.progression?.words.info}>
                        <IconButton sx={{ "&:hover": { color: "#1976d2" } }}>
                            <FaInfo />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>


            <Grid container width="calc(100% + 26px)" alignItems="center" justifyContent="center" spacing={0} sx={{ml:-3}}>
                {/* Pie Chart */}
                <Grid item xs={8}>
                    <Grid container alignItems="center" spacing={0} sx={{ width: '100%' }}>
                        <Grid className="pie-container" item xs={12} style={{ height: '450px', position: 'relative' }}>
                            <Chart wordProgress={wordProgress} handlePieSliceClick={handlePieSliceClick} />
                        </Grid>
                    </Grid>
                </Grid>

                {/* Word List */}
                <Grid item xs={4}>
                    {/* Search bar */}
                    <Grid container alignItems="center" justifyContent="center" spacing={0} sx={{ mx: -3, width: 'calc(100% + 48px)' }}>
                        <Grid item xs={10}>
                            <TextField
                                fullWidth
                                hiddenLabel
                                value={localSearchText}
                                onChange={(e) => setLocalSearchText(e.target.value)}
                                id="filled-hidden-label-small"
                                defaultValue=""
                                size="small"
                                variant="standard"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch();
                                    }
                                }}
                                inputProps={{
                                    placeholder: t.misc?.search,
                                    sx: {
                                        paddingTop: '4px',
                                        paddingBottom: '4px',
                                        paddingLeft: '12px',
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Button onClick={handleSearch} variant="text" sx={{ color: "#555555", minWidth: 0, px: 0, py: 0.3, width: '100%' }}>
                                <SearchIcon />
                            </Button>
                        </Grid>
                    </Grid>

                    {/* List */}
                    <Grid container sx={{ mx: -3, px: 0, width: 'calc(100% + 48px)' }} spacing={0}>
                        <Grid item xs={12} sx={{ maxHeight: '460px', overflow: 'auto', m: 0, p: 0 }}>
                            <FixedSizeList
                                ref={listRef}
                                height={430} // Adjust based on your container's height
                                width="100%"
                                itemSize={28} // Adjust based on your button height + margin/padding
                                itemCount={words.length}
                                overscanCount={5}
                            >
                                {Row}
                            </FixedSizeList>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Words;