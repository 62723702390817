import React from "react";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Features from "./components/Features";
import ArticleScroll from "./components/ArticlesScroll";
import Pricing from "./components/Pricing";
import CallToAction from "./components/CallToAction";
import Footer from "./components/Footer";
import { Box } from "@mui/material";

import { useEffect, useState } from "react";
import useContentful from "../contentful/useContentful";

const LandingPage = () => {

  const [articles, setArticles] = useState([])
  const { getArticles } = useContentful()

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh", overflowX: "hidden"}}>
      <Navbar />
      <Box sx={{ flex: "1 0 auto" }}>
        <Hero />
        <Features />
        <ArticleScroll articles={articles} />
        {/* <Pricing /> */}
        <CallToAction />
      </Box>
      <Footer sx={{ flexShrink: 0 }} />
    </Box>
  );
};

export default LandingPage;
