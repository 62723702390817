import React, { createContext, useContext, useState, useEffect } from "react";
import { Auth } from "aws-amplify";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);
const apiKey = process.env.REACT_APP_APIGATEWAY_KEY;
const gateway = process.env.REACT_APP_APIGATEWAY_DEV;

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [username, setUsername] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [onboarded, setOnboarded] = useState(null);
  const [displayLanguage, setDisplayLanguage] = useState(null);
  const [jwtToken, setJwtToken] = useState(null);

  useEffect(() => {
    checkCurrentUser();
  }, []);

  const checkCurrentUser = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      setUser(currentUser);

      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      setJwtToken(token);

      //console.log("Session token:", token)

      // Fetch username after setting JWT token
      fetchUsername(currentUser, token);
    } catch {
      setUser(null);
      setUsername(null);
      setJwtToken(null);
    }
  };

  const fetchUsername = async (currentUser, token) => {
    //console.log("hello")
    try {
      const endpoint = `${gateway}dynamo-get-user-profile`;

      const input = {
        userId: currentUser.username,
      };

      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
          "Authorization": token,
        },
        body: JSON.stringify(input),
      });

      //console.log("Response from idk:", response)

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const data = await response.json();

      //console.log("Data from endpoint in auth context:", data);

      setUsername(data.body.username);
      setAdmin(data.body.admin);
      setOnboarded(data.body.onboarded);
      setDisplayLanguage(data.body.displayLanguage);
    } catch (error) {
      //console.log(error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        username,
        admin,
        onboarded,
        displayLanguage,
        setOnboarded,
        checkCurrentUser,
        setDisplayLanguage,
        jwtToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};