import React, { useState, useEffect } from "react";

import { useTranslation } from "../../display_languages/UseTranslation";

import { Card, CardActionArea, Box, Stack, Grid, Button, Typography, Paper, CircularProgress } from "@mui/material";

import { useAuth } from "../../AuthContext";
import { useNavigate } from "react-router-dom";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const apiKey = process.env.REACT_APP_APIGATEWAY_KEY;
const gateway = process.env.REACT_APP_APIGATEWAY_DEV;

const ResourceCard = ({ small, t, resource, index, selectedResourceId, handleSelectResource, user, isEmptyObject, group, handleGroupChange, handleSelectSession }) => {
  const [resourceDetails, setResourceDetails] = useState({});
  const {jwtToken} = useAuth();

  useEffect(() => {
    const fetchResourceDetails = async () => {
      // set resourceDetails to an empty object, signifying it is currently loading
      setResourceDetails({});

      const parameters = { userId: user.username, resourceId: resource.id }

      const requestBody = {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          "x-api-key": apiKey,
          "Authorization": jwtToken
         },
        body: JSON.stringify(parameters),
      };

      try {
        const response = await fetch(
          `${gateway}dynamo-get-resource-data`,
          requestBody
        );
        const data = await response.json();
        setResourceDetails(data.body);

      } catch (err) {
        //console.log("error:", err.message);
      }
    };
    if (selectedResourceId == resource.id) {
      fetchResourceDetails();
    }
  }, [selectedResourceId, resource.id]);

  let backgroundColor = '#ffffff';
  if (index % 2 != 0) {
    backgroundColor = '#f5f8fa';
  }



  const CardHeader = ({ icon }) => {
    //console.log("i",index)
    const lengths = {
      "short": t.learn?.length_0,
      "medium": t.learn?.length_1,
      "long": t.learn?.length_2,
    }

    return (
      <>
        {small ?
          <CardActionArea onClick={() => handleSelectResource(resource.id)}>
            <Grid container alignItems="center" justifyContent="left">
              {/* Item index */}
              <Grid item xs={1} sx={{ textAlign: 'left', pl: 1 }}>
                <Typography variant='caption'>{index+1}</Typography>
              </Grid>
              {/* Item content */}
              <Grid item xs={10}>
                <Typography variant="subtitle1">{resource.title}</Typography>
              </Grid>
              <Grid item xs={1}>
                {icon}
              </Grid>
            </Grid>
          </CardActionArea>
          :
          <CardActionArea onClick={() => handleSelectResource(resource.id)}>
            <Grid container alignItems="center" justifyContent="left">
              {/* Item index */}
              <Grid item xs={1} sx={{ textAlign: 'left', pl: 1 }}>
                <Typography variant='caption' sx={{ color: '#b4cad9' }} >{index+1}</Typography>
              </Grid>
              {/* Item content */}
              <Grid item xs={10}>
                <Typography variant="h6">{resource.title}</Typography>
                <Typography variant="body2">{lengths[resource.length]}</Typography>
              </Grid>
              <Grid item xs={1}>
                {icon}
              </Grid>
            </Grid>
          </CardActionArea>
        }
      </>
    );
  }

  let margin = 1;
  if (small) {
    margin = 0.25;
  }

  // Card content for unopened resource
  let cardContent = (
    <CardHeader icon={<ExpandMoreIcon />} />
  );

  // Navigate to parent session of clicked resource
  const parentSession = ({ }) => {
    handleGroupChange({ "target": { "value": "session" } });
    handleSelectSession(resource.userSessionId);
  };

  if (selectedResourceId == resource.id) {
    backgroundColor = '#e7ecf3';
    cardContent = (
      <>
        {isEmptyObject(resourceDetails) ?
          // Not loaded - show loading
          <>
            <CardHeader icon={<ExpandLessIcon />} />
            <CircularProgress sx={{ my: 0.4 }} />
          </>
          :
          // Card content for opened resource
          <>
            <CardHeader icon={<ExpandLessIcon />} />
            <Box sx={{ border: 1, borderColor: '#e4e7ec', mx: margin, mb: margin, p: 1, borderRadius: 1, backgroundColor: '#ffffff' }}>
              <Typography variant="body">{resourceDetails}</Typography>
            </Box>
            <Box sx={{ width: '100%', px: 1 }}>
              {group != "session" && !small &&
                <Button
                  size="small"
                  variant="contained"
                  onClick={parentSession}
                  sx={{ textTransform: "none", mb: 1, width: '100%' }}
                  className="btn"
                >
                  <b>{t.study?.parentsession}</b>
                </Button>
              }
            </Box>
          </>
        }
      </>
    );
  }

  return (
    <Card variant="outlined" sx={{ m: 0.5, mt: 0, overflow: 'hidden', border: 1, borderColor: '#e4e7ec', backgroundColor: backgroundColor }}>
      {cardContent}
    </Card>
  );
}




export default function StudyResources({ small, selectedSessionId, seselctedSessionResource, selectedResourceId, group, handleSelectResource, handleGroupChange, handleSelectSession }) {
  const { user } = useAuth();
  const t = useTranslation();
  const {jwtToken} = useAuth();

  const [resources, setResources] = useState([]);
  const [topicId, setTopicId] = useState([]);

  const navigate = useNavigate();

  // Helper function to check if object is empty
  const isEmptyObject = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;

  let maxHeight = 'calc(100vh - 200px)'
  if (small) {
    maxHeight = '16vh'
  }

  const ResourceCards = ({ }) => {
    //console.log(resources);
    return (
      <>
        {isEmptyObject(resources) ?
          // Not loaded - show loading
          <CircularProgress size={30} />
          :
          resources.length === 0 ?
            // Loaded and returned nothing - show empty message
            <Typography variant="caption">{t.study?.resources_none}</Typography>
            :
            // Show all resources
            <Box sx={{ maxHeight: maxHeight, overflowY: 'auto', pt: 0.5 }}>
              <Stack spacing={0} direction="column">
                {resources.map((resource, index) => (
                  // If rendered from session, do not show resources which belong to the currently viewed session
                  resource.id != seselctedSessionResource && (
                    <ResourceCard small={small} t={t} resource={resource} index={index} selectedResourceId={selectedResourceId} handleSelectResource={handleSelectResource} user={user} isEmptyObject={isEmptyObject} group={group} handleGroupChange={handleGroupChange} handleSelectSession={handleSelectSession} />
                  )
                ))}

                {group == "session" &&
                  <Box sx={{ px: 1 }}>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => { navigate(`/session/${resources[0].userSessionId}`, { state: { userId: user.username, sessionId: resources[0].userSessionId, topicId: topicId } }) }}
                      sx={{ textTransform: "none", mb: 1, mt: 0.5, width: '100%' }}
                      className="btn"
                    >
                      <b>{t.study?.redosession}</b>
                    </Button>
                  </Box>
                }
              </Stack>
            </Box>
        }
      </>
    );
  }


  useEffect(() => {
    const fetchSessionResources = async () => {
      // Do not hit lambda if no session ID selected. if grouping by none, lambda will be hit even with no ID
      if (selectedSessionId == "none" && group != "none") {
        setResources([]);
        return
      }

      // set resources to an empty object, signifying it is currently loading
      setResources({});

      const parameters = {
        userId: user.username,
        sessionId: selectedSessionId,
        group: group,
      };
      const queryString = new URLSearchParams(parameters).toString();
      const endpoint = `${gateway}dynamo-get-session-resources?${queryString}`;
      const requestBody = {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          "x-api-key": apiKey,
          "Authorization": jwtToken
         },
        body: JSON.stringify(parameters),
      };

      try {
        const response = await fetch(endpoint, requestBody);
        const data = await response.json();
        setResources(data.body);
        setTopicId(data.body[0].topicId)

      } catch (err) {
        //console.log("error:", err.message);
      }
    };

    if (selectedSessionId && user) {
      fetchSessionResources();
    }
  }, [selectedSessionId, group]);



  return (
    <>
      {small ?
        <Box sx={{ border: 1, borderColor: '#e4e7ec', pt: 0, pb: 0, borderRadius: 1, backgroundColor: '#ffffff', mb: 2, overflowY: 'auto' }}>
          <ResourceCards />
        </Box>
        :
        <Paper elevation={2}>
          {/* Header */}
          <Grid container alignItems="left" justifyContent="left">
            <Grid item xs={12}>
              <Typography sx={{ mt: 0.5 }} variant="h5">{t.study?.resources}</Typography>
              <Typography sx={{ mb: 0.5 }} variant="body2">{t.misc?.total}{resources.length}</Typography>
            </Grid>
          </Grid>

          {/* Body */}
          <ResourceCards />
        </Paper>
      }
    </>
  );
}