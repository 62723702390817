import React from "react";
import { useState, useEffect } from "react";

import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Stack, Paper } from "@mui/material";
//import CssBaseline from "@mui/material/CssBaseline";
import { Button, TextField, Link, Grid } from "@mui/material";

import SendIcon from "@mui/icons-material/Send";
import CircularProgress from "@mui/material/CircularProgress";
import SaveIcon from "@mui/icons-material/Save";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const defaultTheme = createTheme({
  palette: {
    white: createColor("#ffffff"),
    blue: createColor("#d2d8f7"),
  },
});

const WordsLambda = () => {
  const [textFromApi, setTextFromApi] = useState("");
  const [prompt, setPrompt] = useState("Generate prompt above and save...");
  const [words, setWords] = useState("");
  const [loading, setLoading] = useState("");
  const [model, setModel] = useState("");
  const [length, setLength] = useState("");

  const [promptObj, setPromptObj] = useState("");

  useEffect(() => {
	fetchTextFromApi();
  }, [])

  const fetchTextFromApi = async () => {
    setLoading(true);


    try {
      const response = await fetch(
        `https://iih7s4w0g9.execute-api.eu-west-2.amazonaws.com/DEV/dynamodb-test`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            //"Access-Control-Allow-Origin": "*",
            //"Accept":'application/json'
          },
        //   body: JSON.stringify(promptObj),
        }
      );
      if (!response.ok) {
        throw new Error("API call failed");
      }

      const data = await response.json(); // or response.json() if your API returns JSON
	  //console.log("DATA FROM ENDPOINT: ", data);
	  setLoading(false);

	  if(data.errorMessage){
		setTextFromApi("ERROR OCCURRED");
	  }
	  else{
		setTextFromApi(data);
	  }
      


    } catch (error) {
      setTextFromApi("Hello World");
      //console.error("Error fetching data:", error);
    }

    ////console.log("HELLO WORLD: > ", textFromApi);
  };

  /*
  useEffect(() => {
    fetchTextFromApi();
  }, []);*/

  return (

	<div>
		HEllo wOrld
	</div>

  );
};

export default WordsLambda;