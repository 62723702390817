import React from "react";
import { ReactComponent as VocabuaStencil2 } from "../../media/images/vocabua-stencil2.svg";
import "../../styling/background.css";

const VocabuaGradient2 = () => {
  return (
    <div>
      <div class="floating-div2"></div>
      <div class="floating-div">
        <VocabuaStencil2 />
      </div>
    </div>
  );
};

export default VocabuaGradient2;