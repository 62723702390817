import * as React from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

//import AddWord from "../../components/c_Word/AddWord";
import Words from "../../components/c_Word/Words";
import UserWords from "../../components/c_UserWord/UsersWords";

import Inference_DD from "../../components/c_Inference/Inference_DD";
import Inference_S3 from "../../components/c_Inference/Inference_S3";
import WordsLambda from "../../components/c_Word/WordsLambda";

import {
  blue,
  green,
  grey,
  orange,
  purple,
  red,
  yellow,
} from "@mui/material/colors";

export default function AdminPanel({user}) {
  return (
    <Grid container direction="row" spacing={1} sx={{ height: 1, border: 0 }}>
      {/* <Grid
        item
        xs={12}
        sx={{
          borderRadius: 3,
          p: 0,
          border: 0,
          //boxShadow: 10,
          height: 1,
        }}
      >
        <Inference_S3 user={user}/> 
      </Grid> */}

      <Grid
        item
        xs={12}
        sx={{
          p: 0,
          borderRadius: 3,
          border: 0,
        }}
      >
        hello!
      </Grid> 

    </Grid>
  );
}
