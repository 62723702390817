import React from "react";
import { Alert, Snackbar } from '@mui/material';

const SnackbarComponent = ({ snackbarInfo, setSnackbarInfo }) => { // Destructure `setSnackbarInfo` from props here
    const handleClose = () => {
        setSnackbarInfo({ ...snackbarInfo, open: false }); // Use `setSnackbarInfo` from props
    };

    return (
        <Snackbar
            open={snackbarInfo.open}
            autoHideDuration={2000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
            <Alert onClose={handleClose} severity={snackbarInfo.severity} sx={{ width: '100%' }}>
                {snackbarInfo.message}
            </Alert>
        </Snackbar>
    );
};

export default SnackbarComponent;
