import React from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box
} from "@mui/material";

import './landing.css';

const renderNode = (node) => {
  switch (node.nodeType) {
    case "heading-3":
      return (
        <Typography variant="h5" component="h3" gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'left', pt:2 }}>
          {node.content[0].value}
        </Typography>
      );
    case "heading-4":
      return (
        <Typography variant="h6" component="h4" gutterBottom sx={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'left', pt:2 }}>
          <strong>{node.content[0].value}</strong>
        </Typography>
      );
    case "paragraph":
      return node.content.map((content, index) => (
        <Typography variant="body1" component="p" key={index} sx={{ fontFamily: 'Open Sans, sans-serif', textAlign: 'left', pb:2, lineHeight:1.8 }}>
          {content.value}
        </Typography>
      ));
    case "unordered-list":
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            width: "100%",
          }}
        >
          <TableContainer component={Paper} sx={{ maxWidth: "100%", mb: 2 }}>
            <Table>
              <TableBody>
                {node.content.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{renderNode(item)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      );
    case "list-item":
      return node.content.map((content, index) => renderNode(content));
    default:
      return null;
  }
};

const ContentRenderer = ({ content }) => {
  return content.map((node, index) => (
    <div key={index}>{renderNode(node)}</div>
  ));
};

export default ContentRenderer;
