import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  IconButton,
} from "@mui/material";
import { Facebook, Twitter, Instagram, LinkedIn } from "@mui/icons-material";

import "./landing.css";

const Footer = () => {
  return (
    <>
      <Box sx={{ py: 0, backgroundColor: "#65baa2", color: "black" }}>
        <Container>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={6} sm={2}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontFamily: "Open Sans, sans-serif" }}
              >
                Contact Us
              </Typography>
              <Box sx={{ gap: 1 }}>
                <Link
                  href="mailto:admin@vocabua.com"
                  sx={{ color: "inherit", textDecoration: "none" }}
                >
                  admin@vocabua.com
                </Link>
              </Box>
            </Grid>

            <Grid item xs={6} sm={2}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontFamily: "Open Sans, sans-serif" }}
              >
                Follow Us
              </Typography>
              <Box sx={{ gap: 1 }}>
                <IconButton
                  href="https://www.instagram.com/vocabua/"
                  target="_blank"
                  color="inherit"
                >
                  <Instagram />
                </IconButton>
                <IconButton
                  href="www.linkedin.com/company/vocabua"
                  target="_blank"
                  color="inherit"
                >
                  <LinkedIn />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <Box my={2} textAlign="center">
            <Typography
              variant="body2"
              sx={{ fontFamily: "Open Sans, sans-serif" }}
            >
              &copy; {new Date().getFullYear()} Vocabua. All rights reserved.
            </Typography>
          </Box>
        </Container>
      </Box>

    </>
  );
};

export default Footer;
