import React from "react";
import { useState, useEffect } from "react";

import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Stack, Paper } from "@mui/material";
//import CssBaseline from "@mui/material/CssBaseline";
import { Button, TextField, Link, Grid } from "@mui/material";

import SendIcon from "@mui/icons-material/Send";
import CircularProgress from "@mui/material/CircularProgress";
import SaveIcon from "@mui/icons-material/Save";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const defaultTheme = createTheme({
  palette: {
    white: createColor("#ffffff"),
    blue: createColor("#d2d8f7"),
  },
});

const Inference_S3 = ({ user }) => {
  const [textFromApi, setTextFromApi] = useState("");
  const [prompt, setPrompt] = useState("Generate prompt above and save...");
  const [words, setWords] = useState("");
  const [loading, setLoading] = useState("");
  const [model, setModel] = useState("");
  const [length, setLength] = useState("");

  const [promptObj, setPromptObj] = useState("");

  const create_inference_to_s3 = async () => {
    setLoading(true);

    const wordArray = words.split(" ");

    setPromptObj({
      model: model,
      length: length,
      words: wordArray,
      id: user.username,
    });


    const promptString =
      "Using " +
      model +
      " model to generate " +
      length +
      " story containing the words: \n" +
      words;
    setPrompt(promptString);
    //console.log("PROMPT OBJECT >>>>> ", promptObj);

    try {
      const response = await fetch(
        // `https://iih7s4w0g9.execute-api.eu-west-2.amazonaws.com/DEV/invoke-endpoint?${promptObj}`,
        `https://iih7s4w0g9.execute-api.eu-west-2.amazonaws.com/DEV/inference-to-s3?${promptObj}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(promptObj),
        }
      );
      if (!response.ok) {
        throw new Error("API call failed");
      }

      const data = await response.json(); // or response.json() if your API returns JSON
      //console.log("DATA FROM ENDPOINT S3 CREATE: ", data);
      setLoading(false);

      if (data.errorMessage) {
        setTextFromApi("ERROR OCCURRED");
      }
      else if (data === 'null'){
        setTextFromApi("NULL RESPONSE: GOOD??")
      }
      // } else {
      //   const strData =
      //     "BUCEKT: " + data.bucket + "FILE_PATH: " + data.file_path;
      //   setTextFromApi(strData);
      // }
    } catch (error) {
      setTextFromApi("Hello World : ", error);
    }
  };


  const read_inference_from_s3 = async () => {

    const userObj = {
      "id": user.username
    }

    try {
      const response = await fetch(
        // `https://iih7s4w0g9.execute-api.eu-west-2.amazonaws.com/DEV/invoke-endpoint?${promptObj}`,
        `https://iih7s4w0g9.execute-api.eu-west-2.amazonaws.com/DEV/inference-from-s3?${userObj}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userObj),
        }
      );
      if (!response.ok) {
        throw new Error("ERROR RECIEVING FROM S3");
      }

      const data = await response.json(); // or response.json() if your API returns JSON
      //console.log("DATA FROM ENDPOINT S3 READ: ", data);
      setLoading(false);

      //console.log("DATA AGAIN ", data.outputs[0].output)
      setTextFromApi(data.outputs[0].output)

      if (data.errorMessage) {
        setTextFromApi("ERROR OCCURRED AT GET");
      }
      else if(data === 'null') {
        setTextFromApi("NULL RESPONSE: GOOD??")
      }


    } catch (error) {
      setTextFromApi("Hello World : ", error);
    }


  }


  return (
    <React.Fragment>
      {/* <Paper
        sx={{
          p: 1,
          display: "flex",
          flexDirection: "column",
          height: 1,
          boxShadow: 5,
          marginBottom: 2,
        }}
      >
        {" "}
        Inference Component
      </Paper> */}
      <ThemeProvider theme={defaultTheme} sx={{ border: 0 }}>
        <CssBaseline />

        <Container component="main" fullWidth sx={{ border: 0, marginTop: 1 }}>
          <Stack
            container
            direction="column"
            spacing={2}
            sx={{ border: 0 }}
            fullWidth
          >
            <Grid container spacing={1} direction="row" sx={{ border: 0 }}>
              {/* <Box display="flex" justifyContent="center" alignItems="center" fullWidth sx={{border:1}}> */}

              <Grid item xs={4} sx={{ marginRight: 0 }}>
                <FormControl fullWidth>
                  <InputLabel id="model-select-label">Model</InputLabel>
                  <Select
                    labelId="model-select-label"
                    id="model-simple-select"
                    value={model}
                    label="Model"
                    onChange={(e) => setModel(e.target.value)}
                  >
                    <MenuItem value={"space"}>Space</MenuItem>
                    <MenuItem value={"stories"}>Stories</MenuItem>
                    {/* <MenuItem value={30}>Thirty</MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={4} sx={{ marginRight: 0 }}>
                <FormControl fullWidth>
                  <InputLabel id="length-select-label">Length</InputLabel>
                  <Select
                    labelId="length-select-label"
                    id="length-simple-select"
                    value={length}
                    label="Length"
                    onChange={(e) => setLength(e.target.value)}
                  >
                    <MenuItem value={"short"}>Short</MenuItem>
                    <MenuItem value={"medium"}>Medium</MenuItem>
                    <MenuItem value={"long"}>Long</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={4} sx={{ marginRight: 0 }}>
                <TextField
                  sx={{ bgcolor: "#ffffff", border: 0 }}
                  variant="outlined"
                  //color="white"
                  //margin="normal"
                  fullWidth
                  autoFocus
                  id="words"
                  label="Containing Words"
                  type="text"
                  name="words"
                  value={words}
                  onChange={(e) => setWords(e.target.value)}
                />
              </Grid>
              {/* <Grid item xs={1}></Grid> */}

              <Grid item xs={2} sx={{ marginRight: 0 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="warning"
                  endIcon={<SaveIcon />}
                  onClick={create_inference_to_s3}
                  fullWidth
                ></Button>
              </Grid>

              <Grid item xs={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  endIcon={<SendIcon />}
                  onClick={read_inference_from_s3}
                  fullWidth
                ></Button>
              </Grid>

              {/* </Box> */}
            </Grid>

            <Card>
              <CardContent>
                <Typography variant="h5">{prompt}</Typography>
              </CardContent>
            </Card>

            {loading ? (
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            ) : (
              <Card>
                <CardContent>
                  <Typography variant="body1">
                    {textFromApi /*.errorMessage*/}
                  </Typography>
                  {/* <Typography variant="body1">Loading</Typography> */}
                </CardContent>
              </Card>
            )}

            <Button variant="contained" color="success">
              <Link to="/" color="#ffffff">
                Save
              </Link>
            </Button>
          </Stack>

          {/* </Box> */}
        </Container>
      </ThemeProvider>

      <br />
    </React.Fragment>
  );
};

export default Inference_S3;



// MORE STUFF 


// // const fetchTextFromApi = async () => {
// //   setLoading(true);

// //   const wordArray = words.split(" ");

// //   setPromptObj({
// //     model: model,
// //     length: length,
// //     words: wordArray,
// //     id: user.username,
// //   });

// //   const promptString =
// //     "Using " +
// //     model +
// //     " model to generate " +
// //     length +
// //     " story containing the words: \n" +
// //     words;
// //   setPrompt(promptString);
// //   //console.log("PROMPT OBJECT >>>>> ", promptObj);

// //   /**
// //    * CALLING THE INFERENCE
// //    *
// //    *
// //    **/

// //   try {
// //     const response = await fetch(
// //       // `https://iih7s4w0g9.execute-api.eu-west-2.amazonaws.com/DEV/invoke-endpoint?${promptObj}`,
// //       `https://iih7s4w0g9.execute-api.eu-west-2.amazonaws.com/DEV/inference-to-s3?${promptObj}`,
// //       {
// //         method: "POST",
// //         headers: {
// //           "Content-Type": "application/json",
// //           //"Access-Control-Allow-Origin": "*",
// //           //"Accept":'application/json'
// //         },
// //         body: JSON.stringify(promptObj),
// //       }
// //     );
// //     if (!response.ok) {
// //       throw new Error("API call failed");
// //     }

// //     const data = await response.json(); // or response.json() if your API returns JSON
// //     //console.log("DATA FROM ENDPOINT: ", data);
// //     setLoading(false);

// //     if (data.errorMessage) {
// //       setTextFromApi("ERROR OCCURRED");
// //     } else {
// //       const strData =
// //         "BUCEKT: " + data.bucket + "FILE_PATH: " + data.file_path;
// //       setTextFromApi(strData);
// //     }
// //   } catch (error) {
// //     setTextFromApi("Hello World");
// //   }

// //   /**
// //    * READING THE INFERENCE
// //    *
// //    **/
// //   try {
// //     const response = await fetch(
// //       `https://iih7s4w0g9.execute-api.eu-west-2.amazonaws.com/DEV/inference-from-s3?${promptObj}`,
// //       {
// //         method: "POST",
// //         headers: {
// //           "Content-Type": "application/json",
// //           //"Access-Control-Allow-Origin": "*",
// //           //"Accept":'application/json'
// //         },
// //         body: JSON.stringify(promptObj),
// //       }
// //     );
// //     if (!response.ok) {
// //       throw new Error("API call failed");
// //     }
// //   } catch (e) {
// //     //console.log(e);
// //   }

//   // //console.log("AWS CONFIG")
//   // const AWS = require('aws-sdk')
//   // AWS.config.update({
//   // 	accessKeyId: 'AKIAX2XE6ENJBBPW4VDM',
//   // 	secretAccessKey: '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',
//   // 	region: 'eu-west-2'
//   // });

//   // //console.log("AWS NEW S3")
//   // const s3 = new AWS.S3();

//   // const paramKey = user.username + "/1.json"
//   // const params = {
//   // 	Bucket: "vocabua-inference",
//   // 	Key: paramKey
//   // }
//   // //console.log("PARAMS:", params)

//   // s3.getObject(params, function(err, data) {
//   // 	if(err) {
//   // 		//console.log("INSIDE GETOBJECT ERROR")
//   // 		//console.log(err, err.stack);
//   // 	}
//   // 	else {
//   // 		//console.log(data)
//   // 		//console.log(data.Body)
//   // 	}
//   // })
// };

/*
useEffect(() => {
  fetchTextFromApi();
}, []);*/

// function generatePrompt() {
//     // start by splitting words
//     const wordArray = words.split(" ");

//     setPromptObj({
//       model: model,
//       length: length,
//       words: wordArray,
//     });

//     const promptString =
//       "Using " +
//       model +
//       " model to generate " +
//       length +
//       " story containing the words: \n" +
//       words;
//     setPrompt(promptString);
//     //console.log("PROMPT OBJECT >>>>> ", promptObj);
//     ////console.log(promptString)
//   }
