
import React, { useCallback, useState } from "react";

import { useTranslation } from "../../display_languages/UseTranslation";

import { useNavigate } from "react-router-dom";

import { CircularProgress, Grid, Paper, Button, Typography, Card } from "@mui/material";

import "reactjs-popup/dist/index.css";
import "../../styling/btn_gradient.css";
import Topic_Card from "../../components/c_Topic/Topic_Card";
import SessionLength from "./SessionLength";
import SessionNewWords from "./SessionNewWords";
import SessionTotalResources from "./SessionTotalResources";

import { useAuth } from "../../AuthContext";

const SettingsNoTopic = ({t}) => {
  return (
    <Card variant="outline" sx={{
      boxShadow: 'inset 0px 2px 4px -1px rgba(0,0,0,0.1), inset 0px 4px 8px -1px rgba(0,0,0,0.06)',
      backgroundColor: '#F5F8FA',
      mt: -0.5,
      overflow: 'hidden',
      border: 0,
      height: 110,
      mb: 0.5,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Typography variant="caption">{t.learn?.settings_none}</Typography>
    </Card>
  );
}

const apiKey = process.env.REACT_APP_APIGATEWAY_KEY;
const gateway = process.env.REACT_APP_APIGATEWAY_DEV;

const SettingsTopic = ({ topic, t, setUserSessionTopic, navigate }) => {
  const [userSessionLength, setUserSessionLength] = useState("short");
  const [userSessionNueDuelex, setUserSessionNueDuelex] = useState(1);
  const [userSessionTotalGen, setUserSessionTotalGen] = useState(1);
  const [loading, setLoading] = useState(false);

  const { user, jwtToken } = useAuth();

  const updateSliderLength = useCallback((length) => {
    setUserSessionLength(length);
  });
  const updateSliderNueDuelex = useCallback((value) => {
    setUserSessionNueDuelex(value);
  });
  const updateSliderTotalGen = useCallback((total) => {
    setUserSessionTotalGen(total);
  });



  const startInferenceSession = async () => {
    setLoading(true); // Start loading
    const inferenceObj = {
      topic: topic.name,
      topic_id: topic.id,
      length: userSessionLength,
      userId: user.username,
      nuelex: userSessionNueDuelex,
      total_resources: userSessionTotalGen,
    };
    //console.log(inferenceObj);

    const endpoint = `${gateway}inference-start-session`;

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          "x-api-key": apiKey,
          "Authorization": jwtToken
         },
        body: JSON.stringify(inferenceObj),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      //console.log(data);
      if(data.body.endpoint==false){
        // Notify the user if endpoint is off
        navigate(`/session/offline`, { state: { endpoint: false } });
      
      } else{
        // Navigate to the session screen and begin waiting for session generation if endpoint is on
        navigate(`/session/${data.body.session_id}`, { state: { userId: user.username, expectedResources: userSessionTotalGen, topicId: topic.id } });
      }
      
    } catch (error) {
      console.error("Failed to start inference session:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <>
      <Card variant="outline"  className="btn" sx={{
        boxShadow: 'inset 0px 2px 4px -1px rgba(0,0,0,0.1), inset 0px 4px 8px -1px rgba(0,0,0,0.06)',
        backgroundColor: '#eeeeee',
        mt: -0.5,
        overflow: 'hidden',
        border: 0,
        height: 110,
        mb: 1.5,
        pt: 0.5
      }}>
        <Topic_Card topic={topic} added={true} setUserSessionTopic={setUserSessionTopic} sessionSettings={true} />
      </Card>
      <SessionLength updateSliderLength={updateSliderLength} />
      <SessionNewWords updateSliderNueDuelex={updateSliderNueDuelex} />
      <SessionTotalResources updateSliderTotalGen={updateSliderTotalGen} />
      {
        loading ? (
          <CircularProgress /> // Show loading wheel
        ) : (
          <Button
            variant="contained"
            className="btn"
            sx={{ width:'100%', mt: 1, pt: 1, mb:0.5, textTransform: "none" }}
            onClick={startInferenceSession}
          >
            {t.learn?.start}
          </Button>
        )
      }
    </>
  );
}


export default function SessionSettings ({ topic, setUserSessionTopic }) {
  const t = useTranslation();
  const navigate = useNavigate();

  return (
    <Paper elevation={2} sx={{ pb: 0.1, px:0.5 }}>
      {/* Header */}
      <Grid container alignItems="left" justifyContent="left">
        <Grid item xs={12}>
          <Typography sx={{ my: 0.5 }} component="h1" variant="h5">{t.learn?.settings}</Typography>
        </Grid>
      </Grid>
      {topic.description ? (
        <SettingsTopic topic={topic} t={t} setUserSessionTopic={setUserSessionTopic} navigate={navigate} />
      ) : (
        <SettingsNoTopic t={t} />
      )}
    </Paper>


  );
};



