import React, { useState } from "react";
import { Button, ToolTip, Container, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, IconButton, Grid } from "@mui/material";
import { ResponsiveLine } from '@nivo/line'
import { FaInfo } from "react-icons/fa";
import CloseIcon from '@mui/icons-material/Close';

import { useTranslation } from "../../display_languages/UseTranslation";

const Chart = ({ t, data }) => {
    <style>
        {`
            .custom-tooltip {
                background: rgba(97, 97, 97, 0.9);
                color: #fff;
                padding: 8px 12px;
                border-radius: 4px;
                font-size: 0.875rem;
                line-height: 1.4;
                box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);'
            }
        `}
    </style>

    return (
        <ResponsiveLine
            data={data['line']}
            margin={{ top: 10, right: 10, bottom: 20, left: 35 }}
            xScale={{
                type: 'time',
                format: "%Y-%m-%d",
                precision: 'day',
            }}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: false,
                reverse: false
            }}
            xFormat="time:%Y-%m-%d"
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 2,
                tickPadding: 0,
                format: "%d %b",
                legend: '',
                legendOffset: 24,
                legendPosition: 'middle'
            }}
            axisLeft={{
                tickSize: 0,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Scorelex',
                legendOffset: -30,
                legendPosition: 'middle',
                truncateTickAt: 0
            }}
            colors={{ datum: 'color' }}
            pointSize={10}
            pointBorderWidth={2}
            pointLabelYOffset={-12}
            enableTouchCrosshair={true}
            useMesh={true}
            tooltip={({ point }) => <Paper elevation={2} sx={{ padding: 1, background: '#ffffff', color: '#000000', fontSize: '0.8rem', boxShadow: 2 }}>
                <span>{point.id.split('.')[0]}</span><br />
                {t.progression?.wordstatistics.date}<b>{point.data.xFormatted}</b><br />
                Scorelex: <b>{point.data.yFormatted}</b>
            </Paper>}
        />
    )
}



const StatTable = ({ t, data, displayWords, setDisplayWords }) => {
    const removeWord = (wordToRemove) => {
        setDisplayWords(displayWords.filter(word => word !== wordToRemove));
    };

    const cellDisplay = {
        "Date Learned": t.progression?.wordstatistics.datelearned,
        "Times Seen": t.progression?.wordstatistics.timesseen,
        "Times Incorrect": t.progression?.wordstatistics.timesincorrect
    }

    const rows = [
        [cellDisplay[data['date_learned'][0]], ...data['date_learned'].slice(1)],
        [cellDisplay[data['times_seen'][0]], ...data['times_seen'].slice(1)],
        [cellDisplay[data['times_incorrect'][0]], ...data['times_incorrect'].slice(1)]
    ];



    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 200 }} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="center" />
                        {displayWords.map((word) => (
                            <TableCell key={word} align="right">
                                <Button
                                    onClick={() => removeWord(word)}
                                    startIcon={<CloseIcon />}
                                    size="small"
                                    variant="contained"
                                    sx={{ textTransform: 'none', backgroundColor: data.line.find(item => item.id === word)?.color || 'rgb(0,0,0)' }}
                                >
                                    {word}
                                </Button>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            {row.map((cell, cellIndex) => (
                                <TableCell key={cellIndex} align={cellIndex === 0 ? 'left' : 'right'}>
                                    {cell}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}



const WordStatistics = ({ data, displayWords, setDisplayWords }) => {
    const t = useTranslation();

    return (
        <Container sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={11}>
                    <Typography sx={{ ml: 6, mt: 0.5 }} component="h1" variant="h5">{t.progression?.wordstatistics.title}</Typography>
                </Grid>
                <Grid item xs={1}>
                    <Tooltip title={t.progression?.wordstatistics.info}>
                        <IconButton sx={{ "&:hover": { color: "#1976d2" } }}>
                            <FaInfo />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            {
                displayWords.length > 0 ? (
                    <>
                        <Grid container sx={{ height: '100%' }}> {/* Make the container fill the remaining height */}
                            <Grid item xs={12} sx={{ height: '67%' }}> {/* Chart container */}
                                <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                                    <Chart t={t} data={data} />
                                </div>
                            </Grid>

                            <Grid item xs={12} sx={{ height: '33%' }}> {/* Table container */}
                                <StatTable t={t} data={data} displayWords={displayWords} setDisplayWords={setDisplayWords} />
                            </Grid>
                        </Grid>
                    </>
                ) : (<Typography variant="p" sx={{ textAlign: 'center', mt: 5 }}>
                    {t.progression?.wordstatistics.none}
                </Typography>)
            }
        </Container >
    );
}

export default WordStatistics;