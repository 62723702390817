import * as React from "react";
import {
  styled,
  createTheme,
  ThemeProvider,
  withTheme,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { mainListItems, secondaryListItems } from "./listItems";
import Chart from "./Chart";
import Deposits from "./Deposits";
import Orders from "./Orders";

// Custom components

import VerticalToolBar from "../c_Navigation/ToolBar";
import HomePanel from "../../pages/p_Home/HomePanel";
import StudyPanel from "../../pages/p_Study/StudyPanel";
import LearnPanel from "../../pages/p_Learn/LearnPanel";

//import UserWords from "../dynamoUserWord/UsersWords";
//import Words from "../dynamoWord/Words";
//import Inference from "../inference/Inference";
import {
  blue,
  green,
  grey,
  orange,
  purple,
  red,
  yellow,
} from "@mui/material/colors";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    //backgroundColor: white,
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Dashboard({ user, type }) {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      {/* <Toolbar /> */}
      <Box display="absolute" justifyContent="center" alignItems="center" sx={{border:0}}>
        <Box
          //display="absolute"
          //justifyContent="center"
          //alignItems="center"
          //minHeight="100vh"
          //sx={{ border: 1, borderColor: blue[400], borderRadius: 3 }}
          maxWidth="lg"
        >
          <Grid
            container
            direction="row"
            spacing={0}
            alignItems="center"
            justifyContent="center"
            //sx={{border:1}}
          >
            <Grid
              item
              xs={1}
              //sx={{ border: 5, borderColor: red[400], borderRadius: 3 }}
              sx={{
                //border: 4,
                //borderColor: grey[400],
                borderRadius: 3,
                p: 3,
                boxShadow: 3,
                backgroundColor: "#ffffff"
              }}
            >
              <VerticalToolBar />
            </Grid>

            {/* 
            SPACING BETWEEN THE SIDE TOOLBAR AND THE MAIN DASHBOARD COMPONENT
            */}

            <Grid
              item
              xs={0.5}
              //sx={{ borderColor: orange[100], border: 5 }}
            ></Grid>
            <Grid
              Container
              xs={10.5}
              spacing={0}
              sx={{
                //border: 2,
                //borderColor: green[400],
                borderRadius: 3,
                p: 4,
                boxShadow: 5,
                backgroundColor: "#ffffff",
              }}
            >
              {type === "home" ? (
                <>
                  <HomePanel />
                </>
              ) : type === "study" ? (
                <StudyPanel />
              ) : type === "learn" ? (
                <LearnPanel />
              ) : (
                <h1>Error</h1>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

/*
<Grid item xs={12} md={4} lg={3}>
                  <Paper
                    sx={{
                      p: 0,
                      display: "flex",
                      flexDirection: "column",
                      height: 1,
                    }}
                  >
                    <Deposits />
                  </Paper>
                </Grid>
                {/* Chart *}
                <Grid item xs={12} md={8} lg={6}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      height: 1,
                      //border:5
                    }}
                  >
                    <Inference />
                  </Paper>
                </Grid>
                {/* Recent Deposits *}
                <Grid item xs={12} md={4} lg={3}>
                  <Paper
                    sx={{
                      p: 0,
                      display: "flex",
                      flexDirection: "column",
                      height: 1,
                    }}
                  >
                    <Deposits />
                  </Paper>
                </Grid>
                {/* Recent Orders *}
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      p: 0,
                      display: "flex",
                      flexDirection: "column",
                      height: 1,
                    }}
                  >
                    <Deposits />
                  </Paper>
                </Grid>


-- put in afer CSS baseline

<Chart />
<Orders />

<Drawer variant="permanent" open={open} >
<Toolbar
sx={{
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  px: [1],
  //border:10
}}
>
<IconButton onClick={toggleDrawer}>
  <ChevronLeftIcon />
</IconButton>
</Toolbar>
<Divider/>
<List component="nav">
{mainListItems}
<Divider sx={{ my: 1 }} />
{secondaryListItems}
</List>
</Drawer>

*/

/*

        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Dashboard
            </Typography>
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
*/
