import React from "react";
import { API, graphqlOperation } from "aws-amplify";
//import createWord from "../graphql/mutations";

import { deleteWord } from "../../graphql/mutations";
import { useState } from "react";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

// Styling

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Container } from "@mui/material";
import { CssBaseline } from "@mui/material";
import { TextField, Button, Box, Stack, Typography } from "@mui/material";

const defaultTheme = createTheme();

const DeleteWord = ({ wordObj }) => {

  // Primary Key
  // > partition key
  const wordId = wordObj.id;
  // > sort key
  const wordStr = wordObj.word;

  ////console.log(wordId);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      //console.log("BEFORE API CALL");
      await API.graphql(
        graphqlOperation(deleteWord, { input: { id: wordId, word: wordStr } })
      );
      //console.log("AFTER API CALL");
      alert("Word deleted successfully!");
    } catch (error) {
      //console.log("RECIEVED ERROR > ", error);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
 
        <Popup
          trigger={<Button variant="outlined" fullWidth>Delete</Button>}
          modal
          nested
        >
          <Container
            component="main"
            maxWidth="xs"
            sx={{ border: 2, borderRadius: 3, p: 2 }}
          >
            <CssBaseline />
            <Box component="form" onSubmit={handleSubmit}>
              <Stack spacing={2} direction="column">
                <Typography variant="body1">
                  Delete This Word: "{wordObj.word}" from the DB
                </Typography>

                <Button type="submit" variant="contained" color="warning">
                  Delete Word
                </Button>
              </Stack>
            </Box>
          </Container>
        </Popup>

    </ThemeProvider>
  );
};

export default DeleteWord;
