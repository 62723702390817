import React from "react";
import { Tooltip, IconButton, ListItemIcon, AppBar, Toolbar, Typography, Radio, RadioGroup, FormControlLabel, FormControl, Checkbox, FormGroup, Grid, Paper, Box, Slider } from '@mui/material';
import { format } from 'date-fns';

import { FaInfo } from "react-icons/fa";
import { IoBookOutline } from "react-icons/io5";
import { useTranslation } from "../../display_languages/UseTranslation";

const StudyToolbar = ({ group, handleGroupChange, sort, handleSortChange }) => {
    const t = useTranslation();

    return (
        <Box sx={{ flexGrow: 1, mb: 11.25 }}>
            <AppBar position="fixed" sx={{ "backgroundColor": "#ffffff", "boxShadow": 2, pl: '300px' }}>
                <Toolbar>
                    <Grid container direction="column" sx={{ width: '100%' }}>
                        {/* Title */}
                        <Grid item>
                            <Grid container alignItems="center" justifyContent="space-between" sx={{ mb: -3 }}>
                                {/* Left empty grid item for balance (optional) */}
                                <Grid item xs={false} sm={3} />

                                {/* Center content: Icon and Typography */}
                                <Grid item xs={6} sm={6} container alignItems="center" justifyContent="center">
                                    <ListItemIcon sx={{ fontSize: 30, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <IoBookOutline />
                                    </ListItemIcon>
                                    <Typography variant="h5" sx={{ color: 'black' }}>
                                        {t.study?.title}
                                    </Typography>
                                </Grid>

                                {/* Right content: Info Icon */}
                                <Grid item xs={6} sm={3} container justifyContent="flex-end">
                                    <Tooltip title={t.study?.info}>
                                        <IconButton sx={{ "&:hover": { color: "#1976d2" } }}>
                                            <FaInfo />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Control */}
                        <Grid item>
                            <Grid container spacing={0} justifyContent="center" direction="column" sx={{ width: '100%', py: 0, px: 2, pl: 'calc(50vw - 570px)' }}>
                                {/* Group by */}
                                <Grid item container alignItems="center" sx={{mb:-1}}>
                                    <Box sx={{ minWidth: 100, textAlign: 'left' }}>
                                        <Typography variant="h6" sx={{ mr: 2, color: 'black', fontSize:18 }}>{t.study?.group}</Typography>
                                    </Box>

                                    <Grid item xs>
                                        <FormControl component="fieldset" fullWidth>
                                            <RadioGroup row value={group} onChange={handleGroupChange}>
                                                <FormControlLabel sx={{ color: 'black' }} value="session" control={<Radio size="small"/>} label={t.study?.group_session} />
                                                <FormControlLabel sx={{ color: 'black' }} value="word" control={<Radio size="small"/>} label={t.study?.group_word} />
                                                <FormControlLabel sx={{ color: 'black' }} value="topic" control={<Radio size="small"/>} label={t.study?.group_topic} />
                                                <FormControlLabel sx={{ color: 'black' }} value="none" control={<Radio size="small"/>} label={t.study?.group_none} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                {/* Sort by */}
                                <Grid item container alignItems="center">
                                    <Box sx={{ minWidth: 100, textAlign: 'left' }}>
                                        <Typography variant="h6" sx={{ mr: 2, color: 'black', fontSize:18 }}>{t.study?.sort}</Typography>
                                    </Box>

                                    <Grid item xs>
                                        <FormControl component="fieldset" fullWidth disabled={group=="none"}>
                                            <RadioGroup row value={sort} onChange={handleSortChange}>
                                                <FormControlLabel sx={{ color: 'black' }} value="date" control={<Radio size="small"/>} label={t.study?.sort_date} />
                                                <FormControlLabel sx={{ color: 'black' }} value="count" control={<Radio size="small"/>} label={t.study?.sort_count} />
                                                <FormControlLabel sx={{ color: 'black' }} value="alphabet" control={<Radio size="small"/>} label={t.study?.sort_alphabet} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
export default StudyToolbar;