import React from "react";
import { Button, Typography, Grid, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "../../display_languages/UseTranslation";
import { useAuth } from "../../AuthContext";

const apiKey = process.env.REACT_APP_APIGATEWAY_KEY;
const gateway = process.env.REACT_APP_APIGATEWAY_DEV;

const SessionAlert = ({ sessionData, handleClose }) => {
  const navigate = useNavigate();
  const t = useTranslation();
  const {jwtToken} = useAuth();

  const handleCompleteSession = () => {
    navigate(`/session/${sessionData.id.S}`, {
      state: {
        userId: sessionData.userId.S,
        expectedResources: sessionData.totalResources.N,
        topicId: null,
      },
    });
  };

  const handleStartNewSession = async () => {

    // delete current session from DB
    const sessionId = sessionData.id.S;
    const userId = sessionData.userId.S;
    //console.log("sessionId",sessionId)
    //console.log("userId", userId)
    try {
      const response = await fetch(
        `${gateway}dynamo-delete-user-session`,
        {
          method: "POST",
          headers: { 
            "Content-Type": "application/json",
            "x-api-key": apiKey,
            "Authorization": jwtToken
           },
          body: JSON.stringify({ sessionId: sessionId, userId: userId}),
        }
      );
      const data = await response.json();
      //console.log(data)
      handleClose();
    } catch (error) {
      console.error("Failed to fetch session status:", error);
    }

  };

  return (
    <Box sx={{textAlign:'center'}}>
      <Typography variant="body">{t.session?.unfinished}</Typography>
      <Typography variant="h6" sx={{fontWeight:'bold', mb:4}}>{sessionData.name.S}</Typography>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Button
            onClick={handleCompleteSession}
            variant="contained"
            sx={{ backgroundColor: '#1E88E5', textTransform: 'none' }}
          >
            {t.session?.unfinished_resume}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={handleStartNewSession}
            variant="contained"
            sx={{ backgroundColor: '#FB696A', textTransform: 'none' }}
          >
            {t.session?.unfinished_new}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SessionAlert;
