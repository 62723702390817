import React, { useState, useEffect } from 'react';
import { useTranslation } from "../../display_languages/UseTranslation";
import { Grid, Typography, Card, Slider } from "@mui/material";

export default function SessionTotalResources({ updateSliderTotalGen }) {

  const [sliderValue, setSliderValue] = useState(1);
  const t = useTranslation();

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
    updateSliderTotalGen(newValue);
  }

  const marks = [
    { value: 1, label: '1' },
    { value: 10, label: '10' },
    { value: sliderValue, label: `${sliderValue}` },
  ].filter((mark, index, self) =>
    index === self.findIndex((t) => t.value === mark.value) // Remove duplicates
  );

  return (
    <Card variant="outlnied" sx={{ px: 3, mt: 0, mb: 0.5, overflow: 'hidden', border: 1, borderColor: '#e0e0e0' }}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: "medium" }}>
            {t.learn?.total}
          </Typography>
        </Grid>
      </Grid>

      <div className="btn-slider">
        <Slider
          sx={{ mt: -1, mb: 2 }}
          aria-label="Total Resources"
          defaultValue={1}
          step={1}
          marks={marks}
          min={1}
          max={10}
          onChange={handleSliderChange}
        />
      </div>
    </Card>
  );
};