import React from "react";
import "reactjs-popup/dist/index.css";
import "../../styling/btn_gradient.css"

import { Paper, Button, Grid, Box, Typography, Card, CardActionArea, Stack, CircularProgress } from "@mui/material";

import { useTranslation } from "../../display_languages/UseTranslation";

export default function Resource_List({ selectedResource, resources, updateSelectedResource, updateSessionStatus, finishedLoading }) {
  const t = useTranslation();

  const handleResourceSelect = (resource) => {
    updateSelectedResource(resource);
    updateSessionStatus(false)
  };

  const allViewed = resources.every(resource => resource.viewed === true);

  const ResourceCard = ({ resource, index, selectedResource }) => {
    let backgroundColor = '#ffffff';
    if (index % 2 != 0) {
      backgroundColor = '#f5f8fa';
    }
    if (resource.id == selectedResource.id) {
      backgroundColor = '#e7ecf3';
    }

    let boxShadow = '0px';
    if (resource.viewed) {
      boxShadow = '0px 0px 4px 1px #6CCC8D inset';
    }


    return (
      <Card key={index} variant="outlined" sx={{ m: 0.5, mt: 0, overflow: 'hidden', border: 1, borderColor: '#e4e7ec', backgroundColor: backgroundColor, boxShadow: boxShadow, position: 'relative' }}>
        <CardActionArea onClick={() => handleResourceSelect(resource)}>
          {/* Index */}
          <Typography
            variant="caption"
            sx={{
              position: 'absolute',
              top: 2,
              left: 5,
              color: '#b4cad9'
            }}
          >
            {index+1}
          </Typography>

          {/* Title */}
          <Typography
            variant="subtitle1"
            component="h2"
            sx={{
              my: 2,
              mx: 0.5,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {resource.title}
          </Typography>
        </CardActionArea>
      </Card>
    );
  }

  return (
    <Box>
      {/* List of Resource Buttons */}
      <Paper elevation={2}>
        <Stack spacing={0} sx={{ pt: 0.5, mb: 1 }} direction="column">
          {resources.map((resource, index) => (
            <ResourceCard resource={resource} index={index} selectedResource={selectedResource} />
          ))}

          {!finishedLoading &&
            <Box>
              <CircularProgress size={20} sx={{ mt: 0.5 }} />
            </Box>
          }
        </Stack>
      </Paper>

      {/* Finish Session Button */}
      {finishedLoading &&
        <Button variant="contained" disabled={!allViewed} className={allViewed ? "btn" : "btn-disabled"} onClick={() => updateSessionStatus(true)} sx={{ mt: 0, py: 2.5, width: '100%', textTransform: 'none', fontWeight: 'bold' }} >
          {t.session?.finish}
        </Button>
      }
    </Box>
  );
};