import React, { useState, useCallback, useEffect} from "react";
import { Grid, Container } from "@mui/material";
import SessionSettings from "./SessionSettings";
import TopicList from "../../components/c_Topic/TopicList";

import SimpleModal from "../../components/c_Navigation/SimpleModal";
import SessionAlert from "../p_Session/Session_Alert";
import BasicToolbar from "../../components/c_Navigation/BasicToolbar";
import { HiOutlineAcademicCap } from "react-icons/hi2";

import { useAuth } from "../../AuthContext";
import { useTranslation } from "../../display_languages/UseTranslation";
import { useNavigate } from "react-router-dom";

const apiKey = process.env.REACT_APP_APIGATEWAY_KEY;
const gateway = process.env.REACT_APP_APIGATEWAY_DEV;

export default function LearnPanel({ topicData, user }) {
  const t = useTranslation();
  const {jwtToken} = useAuth();

  const [userSessionTopic, setUserSessionTopic] = useState({});
  const navigate = useNavigate();
  const [showLearnModal, setShowLearnModal] = useState(false);
  const [sessionData, setSessionData] = useState({});

  const checkSessionAndToggleModal = async () => {
    try {
      const response = await fetch(
        `${gateway}confirm-previous-session-complete`,
        {
          method: "POST",
          headers: { 
            "Content-Type": "application/json",
            "x-api-key": apiKey,
            "Authorization": jwtToken
           },
          body: JSON.stringify({ userId: user.username }),
        }
      );
      const data = await response.json();
      const previousSessionIsComplete = data[0]["complete"]['BOOL']; //|| [];

      //console.log("Setting SessionData to be:", data)
      //console.log("Previous session complete:", previousSessionIsComplete)
      setSessionData(data[0])

      if (!previousSessionIsComplete) {
        // Check the response, adjust this condition based on actual response structure
        setShowLearnModal(true);
      }
    } catch (error) {
      console.error("Failed to fetch session status:", error);
    }
  };

  const toggleLearnModal = () => {
    setShowLearnModal((prev) => !prev);
  };

  useEffect(() => {
      checkSessionAndToggleModal(user);
      //console.log("Sesion Data:", sessionData)
  }, []);

  return (
    <Container>
      <BasicToolbar
        icon={<HiOutlineAcademicCap />}
        title={t.learn?.title}
        info={t.learn?.info}
      />
      <Grid
        container
        direction="row"
        spacing={1}
        sx={{ flexGrow: 1, width: "auto" }}
      >
        {/* User Topics */}
        <Grid item xs={6}>
          <TopicList
            topics={topicData["user_topics"]}
            setUserSessionTopic={setUserSessionTopic}
            added={true}
            title={t.learn?.topics}
            emptyMessage={t.learn?.topics_none}
            moreTopics={topicData["available_topics"].length != 0}
          />
        </Grid>

        {/* Session Settings */}
        <Grid item xs={6}>
          <SessionSettings
            topic={userSessionTopic}
            setUserSessionTopic={setUserSessionTopic}
          />
        </Grid>
        <SimpleModal
          open={showLearnModal}
          handleClose={toggleLearnModal}
          title="Learning Module"
        >
          <SessionAlert sessionData={sessionData} handleClose={toggleLearnModal}/>
        </SimpleModal>
      </Grid>
    </Container>
  );
}
