import React, { useEffect, useState, useRef } from "react";
import { Container, Box, Typography, Paper } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import SessionPanel from "./SessionPanel";

import { RiZzzFill } from "react-icons/ri";

import { useTranslation } from "../../display_languages/UseTranslation";
import { useAuth } from "../../AuthContext";

const apiKey = process.env.REACT_APP_APIGATEWAY_KEY;
const gateway = process.env.REACT_APP_APIGATEWAY_DEV;

const NoEndpointMessage = () => {
  const t = useTranslation();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', }}>
      <Paper sx={{ p: 2, maxWidth: 600 }}>
        <Typography variant="h4">{t.session?.noendpoint1}</Typography>
        <RiZzzFill style={{ fontSize: '64px', color: '#888888', marginTop: '10px', marginBottom: '10px' }} />
        <Typography variant="body1">{t.session?.noendpoint2}</Typography>
        <Typography variant="body1">{t.session?.noendpoint3}</Typography>
      </Paper>
    </Box>
  );
}

const LoadingMessage = () => {
  const t = useTranslation();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', }}>
      <Paper sx={{ p: 2, maxWidth: 600 }}>
        <Typography variant="body1" sx={{ mb: 2 }}>{t.session?.generating}</Typography>
        <CircularProgress />
      </Paper>
    </Box>
  );
}

const Session = () => {
  const t = useTranslation();
  const {jwtToken} = useAuth();

  const [sessionData, setSessionData] = useState({});
  const [userWordData, setUserWordData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [finishedLoading, setFinishedLoading] = useState(false);

  const sessionDataRef = useRef(sessionData);
  useEffect(() => {
    sessionDataRef.current = sessionData;
  }, [sessionData]);

  const { sessionId } = useParams();
  const location = useLocation();
  const { userId, expectedResources } = location.state;

  useEffect(() => {

    let isMounted = true; // Track if component is mounted

    const fetchData = async () => {
      if (!isMounted) return; // Exit if component has been unmounted

      try {
        const paramObj = {
          userId: userId,
          id: sessionId,
          priorResources: sessionDataRef.current.resources || [],
        };
        //console.log("data sent", paramObj)

        const response = await fetch(
          `${gateway}dynamo-get-session-data`,
          {
            method: "POST",
            headers: { 
              "Content-Type": "application/json",
              "x-api-key": apiKey,
              "Authorization": jwtToken
             },
            body: JSON.stringify(paramObj),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        if (isMounted) {
          setSessionData(data.session);
          setUserWordData(data.user_words)

          const itemCount = data.session.resources.length

          //console.log(`Received ${itemCount} of ${expectedResources} resources.`);
          //console.log(data.session.resources);

          if (itemCount > 0) {
            setIsLoading(false); // Hide loading once at least one resource is received
          }

          if (itemCount < expectedResources) {
            // Continue polling if not all resources have been loaded
            setTimeout(fetchData, 3000); // Schedule next attempt
          } else {
            setFinishedLoading(true);
          }
        }
      } catch (error) {
        console.error(error);
        if (isMounted) setIsLoading(false); // Hide loading on error
      }
    };

    fetchData();

    return () => {
      isMounted = false; // Indicate component has been unmounted
    };
  }, [sessionId, userId, expectedResources]);

  return (
    <Container>
      {sessionId == "offline" ? (
        <NoEndpointMessage />
      ) : isLoading ? (
        // Session is loading- show loading screen
        <LoadingMessage />
      ) : sessionData ? (
        // Session loaded- show session panel
        <SessionPanel userId={userId} sessionData={sessionData} userWordData={userWordData} finishedLoading={finishedLoading} />
      ) : (
        // Could not load the session- provide error message
        <div>{t.session?.no_resource}</div>
      )}
    </Container>
  );
};

export default Session;
