import React, { useEffect, useState } from "react";

import { useLanguage } from '../../display_languages/LanguageContext';
import { useTranslation } from "../../display_languages/UseTranslation";

import { Button, Grid, TextField, Alert, Snackbar, Container, Box, CssBaseline } from '@mui/material';

import SnackbarComponent from "../c_Utils/Snackbar";

// Amplify
import { Amplify } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { LanguageList } from "../../display_languages/LanguageDisplay"

const apiKey = process.env.REACT_APP_APIGATEWAY_KEY;
const gateway = process.env.REACT_APP_APIGATEWAY_DEV;

export default function SignUp() {
  const languageContext = useLanguage(); // Use the hook to get the context object
  const t = useTranslation();
  const {jwtToken} = useAuth();

  const navigate = useNavigate();

  const [snackbarInfo, setSnackbarInfo] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleDisplayLanguageChange = (event) => {
    languageContext.setLanguage(event.target.value);
  }

  const handleLearningLanguageChange = (event) => {
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const email = data.get("email"); //.toString;
    const password = data.get("password"); //.toString;
    const username = data.get("username");
    let errorMessage = "";


    // test username validity
    const endpoint = `${gateway}dynamo-check-username-validity`
    //console.log(JSON.stringify({ "username": username }));

    const response = await fetch(endpoint, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "x-api-key": apiKey,
        "Authorization": jwtToken
       },
      body: JSON.stringify({ "username": username }),
    });
    const validResponse = await response.json();
    if (validResponse.body == false) {
      setSnackbarInfo({
        open: true,
        message: "Username already exists.",
        severity: "error"
      });
      return;
    }


    try {
      const user = await Amplify.Auth.signUp(email, password); // create a user account

      // create user profile table item
      const userProfile = {
        userId: user.userSub,
        username: username,
        displayLanguage: languageContext.language,
      };

      try {
        const endpoint = `${gateway}dynamo-create-user-profile`

        const response = await fetch(endpoint, {
          method: "POST",
          headers: { 
            "Content-Type": "application/json",
            "x-api-key": apiKey,
            //"Authorization": jwtToken
           },
          body: JSON.stringify(userProfile),
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }
      } catch (error) {
        setSnackbarInfo({
          open: true,
          message: t.register?.register_failure,
          severity: "error"
        });
      }

      navigate("/authentication", { // go to authentication screen
        state: {
          userEmail: email,
        },
      });

    } catch (error) {
      //console.log(error)
      if (error.message === "Username should be an email.") {
        errorMessage = t.register?.register_failure_email;
      } else if (error.message === "Password did not conform with policy: Password not long enough") {
        errorMessage = t.register?.register_failure_password;
      } else {
        errorMessage = t.register?.register_failure;
      }
      setSnackbarInfo({
        open: true,
        message: errorMessage,
        severity: "error"
      });
    }
  };


  return (
    <>
      <Container component="main" maxWidth="xs">

        <Box
          sx={{
            //marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}> {/* sm={6} allocates half the width for larger screens */}
                <LanguageList handleLanguageChange={handleDisplayLanguageChange} title="I speak" value={languageContext.language} />
              </Grid>
              <Grid item xs={12} sm={6}> {/* sm={6} ensures the second list also takes up half the width */}
                <LanguageList handleLanguageChange={handleLearningLanguageChange} title="I am learning" value="en" disabled />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label={t.profile?.email}
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="username"
                  label={t.profile?.username}
                  name="username"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label={t.profile?.password}
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t.register?.register}
            </Button>
          </Box>
        </Box>

      </Container>

      <SnackbarComponent snackbarInfo={snackbarInfo} setSnackbarInfo={setSnackbarInfo} />
    </>
  );
}