import { createClient } from "contentful";


const useContentful = () => {
  const client = createClient({
    // GET THESE ENVIRONMENT VARIABLES WORKING
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken:process.env.REACT_APP_CONTENTFUL_DELIVERY_TOKEN,
    host:"cdn.contentful.com"
  });

  ////console.log("Contentful ID:", process.env.REACT_APP_CONTENTFUL_SPACE_ID)

  const getAllArticles = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "article",
        select: "fields",
      });
      const sanitizedEntries = entries.items.map((item) => ({
        ...item.fields,
        id: item.sys.id,
      }));
      return sanitizedEntries;
    } catch (error) {
      //console.log("Error within 'getAllArticles':", error);
    }
  };

  const getArticle = async ({ articleId }) => {
    try {
      const entry = await client.getEntry(articleId);
      const sanitizedEntry = entry.fields;
      return sanitizedEntry;
    } catch (error) {
      //console.log("Error within 'getAllArticle':", error);
    }
  };

  const getArticlesLimit = async ({ limit }) => {
    try {
      const entries = await client.getEntries({
        content_type: "article",
        select: "fields",
        order: "-sys.createdAt",
        limit: limit,
      });

      const sanitizedEntries = entries.items.map((item) => ({
        ...item.fields,
        id: item.sys.id,
      }));

      return sanitizedEntries;
    } catch (error) {
      //console.log("Error within 'getArticlesLimit':", error);
    }
  };

  return { getAllArticles, getArticle, getArticlesLimit };
};

export default useContentful;
