import React, {useState} from "react";

import { useTranslation } from "../../display_languages/UseTranslation";

import {
  Box,
  Tooltip,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { useAuth } from "../../AuthContext";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import VocabuaGradient2 from "../c_Styling/VocabuaLogo_Gradient2";
import VocabuaLogo from "../c_Styling/VocabuaLogo";

import { GrHomeRounded } from "react-icons/gr";
import { MdAutoGraph } from "react-icons/md";
import { BiWorld } from "react-icons/bi";
import { IoBookOutline } from "react-icons/io5";
import { HiOutlineAcademicCap } from "react-icons/hi2";
import { BsInfoCircle } from "react-icons/bs";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import { PiSignOutBold } from "react-icons/pi";

import SimpleModal from "./SimpleModal";
import SessionAlert from "../../pages/p_Session/Session_Alert";




const PanelItem = ({ text, icon, path, minimise, onClickFunction }) => {
  const panelContent = (
    <ListItem sx={{ px: 0 }}>
      <ListItemButton onClick={onClickFunction}>
        <ListItemIcon sx={{ ml: 1, mr: -2, my: 1 }}>{icon}</ListItemIcon>
        {!minimise && <ListItemText primary={text} sx={{ ml: 0 }} />}
      </ListItemButton>
    </ListItem>
  );

  return onClickFunction ? (
    <div>{panelContent}</div>
  ) : (
    <Link to={path} style={{ textDecoration: "none", color: "black" }}>
      {minimise ? (
        <Tooltip title={text} arrow placement="left">
          {panelContent}
        </Tooltip>
      ) : (
        <>{panelContent}</>
      )}
    </Link>
  );
};

export default function SideNavPanel({ signOut }) {

  const { user, username, admin } = useAuth();
  const t = useTranslation();

  const [minimise, setMinimise] = React.useState(false);
  const [showLearnModal, setShowLearnModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState();

  const navigate = useNavigate()


  
  const minimiseClick = ({}) => {
    setMinimise(!minimise);
  };

  const toggleLearnModal = () => {

    setShowLearnModal((prev) => !prev);
  };

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };


  const onClickWebsiteLink = () => {
    navigate("/")
  }

  return (
    <Drawer
      sx={{
        width: minimise ? 64 : 220,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        "& .MuiDrawer-paper": {
          width: minimise ? 64 : 220,
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          overflowX: "hidden",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <List sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
        {!minimise ? (
          <>
            <ListItem>
              <Link to="/app">
                <VocabuaGradient2 />
              </Link>
            </ListItem>
            <br />
            <br />
          </>
        ) : (
          <>
            <ListItem>
              <VocabuaLogo />
            </ListItem>
          </>
        )}


        <PanelItem text={t.home?.title} icon={<GrHomeRounded />} path="/app" minimise={minimise} />
        <PanelItem text={t.learn?.title} icon={<HiOutlineAcademicCap />} path="/learn" minimise={minimise}/>
        <PanelItem text={t.study?.title} icon={<IoBookOutline />} path="/study" minimise={minimise} />
        <PanelItem text={t.explore?.title} icon={<BiWorld />} path="/explore" minimise={minimise} />
        <PanelItem text={t.progression?.title} icon={<MdAutoGraph />} path="/progression" minimise={minimise} />

        <Divider />

        <PanelItem text={t.profile?.title} icon={<CgProfile />} path="/UserProfile" minimise={minimise} />
        {admin && <PanelItem text="Admin" icon={<CgProfile />} path="/Admin" minimise={minimise} />}
        <PanelItem text={t.register?.signout} icon={<PiSignOutBold />} path="/" minimise={minimise} onClickFunction={signOut}/>

        <Divider />

        <PanelItem text={t.toolbar?.backtowebsite} icon={<IoMdArrowBack />} path="/" minimise={minimise} />


        <div style={{ marginTop: "auto" }}>
          <Box sx={{ mb: -1 }}>
            <PanelItem text={minimise ? t.toolbar?.maximise : t.toolbar?.minimise} icon={minimise ? <IoMdArrowForward /> : <IoMdArrowBack />} minimise={minimise} onClickFunction={minimiseClick} />
          </Box>
        </div>
      </List>

      {/* <SimpleModal
        open={showLearnModal}
        handleClose={toggleLearnModal}
        title="Learning Module"
      >
        <SessionAlert />
      </SimpleModal> */}

    </Drawer>
  );
}
