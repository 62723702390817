import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Typography, Stack, Box, LinearProgress, Tooltip, IconButton, Grid } from "@mui/material";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { IoBookOutline } from "react-icons/io5";
import { MdAutoGraph } from "react-icons/md";

import { useTranslation } from "../../display_languages/UseTranslation";


const WordCard = ({ t, word, index, totalIndex, navigate, scorelex }) => {
    let backgroundColor = '#ffffff';
    if (index % 2 != 0) {
        backgroundColor = '#f5f8fa';
    }

    return (
        <Card variant="outlined" sx={{ m: 0.5, mt: 0, overflow: 'hidden', border: 1, borderColor: '#e4e7ec', backgroundColor: backgroundColor }}>
            <Grid container alignItems="center" justifyContent="left" sx={{ py: 0 }}>
                {/* Item index */}
                <Grid item xs={1} sx={{ textAlign: 'left', pl: 1 }}>
                    <Typography variant='caption' sx={{ color: '#b4cad9' }} >{totalIndex}</Typography>
                </Grid>

                {/* Word */}
                <Grid item xs={10}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography variant="h6" component="h3">{word.wordStr}</Typography>
                    </Box>

                    {scorelex &&
                        <Box sx={{ width: '100%', mt: 2, px: 2, mb:-0.5 }}>
                            <Tooltip title={<Typography color="inherit" sx={{ fontSize: '0.8rem' }}><b>Scorelex:</b> {word.scorelex.scorelex}</Typography>} followCursor arrow placement="top">
                                <LinearProgress
                                    variant="determinate"
                                    value={word.scorelex_normalised}
                                    sx={{
                                        mt: -1.5,
                                        mb: 1,
                                        height: '10px',
                                        borderRadius: 5,
                                        backgroundColor: 'rgba(30, 136, 229, 0.1)',
                                        '& .MuiLinearProgress-bar': {
                                            borderRadius: 5,
                                            backgroundColor: 'rgba(30, 136, 229, 1)' }
                                    }}
                                />
                            </Tooltip>
                        </Box>
                    }
                </Grid>

                {/* Buttons for study and progression */}
                <Grid item xs={1}>
                    <Stack>
                        <Tooltip title={t.home?.wordlist_study} arrow placement="right">
                            <IconButton size="small" sx={{my:-0.125}} onClick={() => { navigate(`/study`, { state: {selectedWordState: word.wordId} }) }}>
                                <IoBookOutline />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t.home?.wordlist_progression} arrow placement="right">
                            <IconButton size="small" sx={{my:-0.125}} onClick={() => { navigate(`/progression`, { state: {selectedWordState: word.wordStr[0].toUpperCase() + word.wordStr.slice(1)} }) }}>
                                <MdAutoGraph />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Grid>
            </Grid>
        </Card>
    );
}

export default function HomeWordList({ words, containerHeight, scorelex }) {
    const t = useTranslation();
    const navigate = useNavigate();

    const itemHeight = 59;
    const count = Math.floor(containerHeight / itemHeight) - 1;
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentItems, setCurrentItems] = useState([]);
    useEffect(() => {
        if (words != null) {
            setPageCount(Math.ceil(words.length / count));
            const newCurrentItems = words.slice(currentPage * count, (currentPage + 1) * count);
            setCurrentItems(newCurrentItems);
        }
    }, [words, currentPage, count]);

    const handlePageChange = (newPage) => {
        if (newPage >= 0 && newPage < pageCount) {
            setCurrentPage(newPage);
        }
    };

    return (
        <>
            <Box sx={{ flexGrow: 1, overflowY: 'auto', mx: -3 }}>
                <Stack spacing={0} direction="column">
                    {currentItems.map((word, index) => (
                        <WordCard t={t} word={word} index={index} totalIndex={(currentPage * count) + (index + 1)} navigate={navigate} scorelex={scorelex} />
                    ))}
                </Stack>
            </Box>

            {/* Pagination controls anchored to the bottom */}
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <IconButton onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0}>
                    <MdChevronLeft />
                </IconButton>
                <Typography sx={{ margin: 'auto 10px' }}>{t.misc?.page}{currentPage + 1} / {pageCount}</Typography>
                <IconButton onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === pageCount - 1}>
                    <MdChevronRight />
                </IconButton>
            </Box>
        </>
    );
}
