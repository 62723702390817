import React, { useEffect, useState } from "react";

import { useTranslation } from "../../display_languages/UseTranslation";

//Accordian
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";

import SignIn from "./UserSignIn";
import SignUp from "./UserRegister";

import Container from "@mui/material/Container";

const SignInRegister = () => {

  const t = useTranslation();

  return (
    <Container component="main" maxWidth="md" >
      <Accordion sx={{mb:2}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ mx: 'auto' }} component="h1" variant="h5">
            {t.register?.signin}
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <SignIn />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ mx: 'auto' }} component="h1" variant="h5">
            {t.register?.register}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SignUp />
        </AccordionDetails>
      </Accordion>

    </Container>
  );
};

export default SignInRegister;