import * as React from "react";

import { Container, Grid } from "@mui/material";
import BasicToolbar from "../../components/c_Navigation/BasicToolbar";
import { BiWorld } from "react-icons/bi";
import TopicList from "../../components/c_Topic/TopicList";

import { useTranslation } from "../../display_languages/UseTranslation";

export default function ExplorePanel({ exploreData, addUserTopic }) {
  const t = useTranslation();

  return (
    <Container>
      <BasicToolbar icon={<BiWorld />} title={t.explore?.title} info={t.explore?.info} />
      <Grid container direction="row" spacing={1} sx={{ flexGrow: 1, width: "auto" }}>
        {/* User Topics */}
        <Grid item xs={6}>
          <TopicList
            topics={exploreData['user_topics']}
            addUserTopic={addUserTopic}
            added={true}
            title={t.explore?.topics}
            emptyMessage={t.explore?.topics_empty} />
        </Grid>

        {/* Available Topics */}
        <Grid item xs={6}>
          <TopicList
            topics={exploreData['available_topics']}
            addUserTopic={addUserTopic}
            added={false}
            title={t.explore?.availabletopics}
            emptyMessage={t.explore?.availabletopics_empty} />
        </Grid>
      </Grid>
    </Container>
  );
}