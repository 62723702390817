import React from "react";
import { useTranslation } from "../../display_languages/UseTranslation";
import { Grid, Typography, Card, Slider } from "@mui/material";

export default function SessionNewWords ({ updateSliderNueDuelex }) {

  const t = useTranslation();

  const handleSliderChange = (event, newValue) => {
    updateSliderNueDuelex(newValue)
  }

  const marks = [
    {
      value: 0,
      label: t.learn?.newword_0,
    },
    {
      value: 1,
      label: t.learn?.newword_1,
    },
    {
      value: 2,
      label: t.learn?.newword_2,
    },
  ];

  return (
    <Card variant="outlined" sx={{ px: 3, mt: 0, mb:0.5, overflow: 'hidden', border: 1, borderColor: '#e0e0e0' }}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: "medium" }}>
            {t.learn?.newword}
          </Typography>
        </Grid>
      </Grid>

      <div className="btn-slider">
        <Slider
          sx={{ mt: -1, mb: 2 }}
          aria-label="New Word Discovery"
          defaultValue={1}
          step={1}
          marks={marks}
          min={0}
          max={2}
          onChange={handleSliderChange}
        />
      </div>
    </Card>
  );
};