import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';

const LanguageContext = createContext();

export function LanguageProvider({ children }) {
  const { displayLanguage } = useAuth();

  const [language, setLanguage] = useState('en');  // Default to 'en'
  useEffect(() => {
    if (displayLanguage) {
      setLanguage(displayLanguage);
    }
  }, [displayLanguage]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}

export function useLanguage() {
  return useContext(LanguageContext);
}