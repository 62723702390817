import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Box,
  Typography,
} from "@mui/material";

import VocabuaLogo from "../../media/images/vocabua-black.svg";
import VocabuaWhite from "../../media/images/vocabua-white.svg";

import { useAuth } from "../../AuthContext";

const apiKey = process.env.REACT_APP_APIGATEWAY_KEY;
const gateway = process.env.REACT_APP_APIGATEWAY_DEV;

export default function Onboarding({ onboarded, setOnboarded, setLoading }) {
  const [display, setDisplay] = useState("main");
  const [proficiency, setProficiency] = useState("Novice");
  const [onboardingWords, setOnboardingWords] = useState([]);

  const { user, jwtToken } = useAuth();

  const toggleWordEnabled = (id) => {
    const updatedWords = onboardingWords.map((word) => {
      if (word.id === id) {
        return { ...word, enabled: !word.enabled }; // Toggle the 'enabled' state
      }
      return word;
    });
    setOnboardingWords(updatedWords);
  };

  useEffect(() => {
    const fetchOnboardingWords = async () => {
      setOnboardingWords([]);

      const parameters = {
        proficiency: proficiency,
      };

      const endpoint = `${gateway}onboarding-words`;
      const requestBody = {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          "x-api-key": apiKey,
          "Authorization": jwtToken
         },
        body: JSON.stringify(parameters),
      };

      try {
        const response = await fetch(endpoint, requestBody);
        const data = await response.json();
        setOnboardingWords(data.body);
      } catch (err) {
        //console.log("error:", err.message);
      }
    };

    fetchOnboardingWords();
  }, [proficiency]);

  const proficiencyWords = (level) => {
    setProficiency(level);
    setDisplay("proficiencyWords");
  };

  const submitLambda = async () => {
    const parameters = {
      onboardingWords: onboardingWords,
      username: user.username,
    };
    //console.log(parameters)

    const endpoint = `${gateway}onboarding-submit-proficiency`;
    const requestBody = {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "x-api-key": apiKey,
        "Authorization": jwtToken
       },
      body: JSON.stringify(parameters),
    };

    try {
      const response = await fetch(endpoint, requestBody);
      const data = await response.json();
      //console.log("done!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
      setLoading(false);
      setOnboarded(true);
    } catch (err) {
      //console.log("error:", err.message);
    }
  };

  const submitProficiency = () => {
    setLoading(true);
    submitLambda();
    setDisplay("complete");
    const timer = setTimeout(() => {
      setDisplay("");
    }, 1000);
    return () => clearTimeout(timer);
  };

  return (
    <>
      {/* Main Onboarding Screen */}
      <Dialog open={!onboarded && display == "main"}>
        <DialogTitle sx={{ textAlign: "center" }}></DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            mb: 4,
          }}
        >
          <img
            src={VocabuaLogo}
            style={{ width: "80%", height: "auto", objectFit: "cover" }}
          />
        </Box>

        <DialogContent>
          <Stack>
            <Typography sx={{ textAlign: "center", mb: 4 }}>
              Before you can begin learning, we need to know your language
              level.
            </Typography>

            <Button
              variant="contained"
              onClick={() => setDisplay("proficiency")}
              sx={{ textTransform: "none", mb: 1, backgroundColor: "#1E88E5" }}
            >
              Select Known Words
            </Button>
            <Typography variant="caption" sx={{ textAlign: "center", mb: 3 }}>
              We will model your language level based on the words that you know
            </Typography>

            <Button
              variant="contained"
              sx={{ textTransform: "none", mb: 1, backgroundColor: "#1E88E5" }}
              disabled
            >
              Take Onboarding Test (Coming Soon)
            </Button>
            <Typography variant="caption" sx={{ textAlign: "center", mb: 3 }}>
              Let us assess your ability with Vocabua and accurately model your
              language level
            </Typography>

            <Button
              variant="contained"
              sx={{ textTransform: "none", mb: 1, backgroundColor: "#1E88E5" }}
              disabled
            >
              Import External Knowledge (Coming Soon)
            </Button>
            <Typography variant="caption" sx={{ textAlign: "center" }}>
              Migrate from other learning apps
            </Typography>
          </Stack>
        </DialogContent>
      </Dialog>

      {/* Select Proficiency Level Screen */}
      <Dialog open={!onboarded && display == "proficiency"}>
        <DialogTitle sx={{ textAlign: "center", mb: 4 }}>
          Select your proficiency level:
        </DialogTitle>

        <DialogContent>
          <Stack>
            <Button
              variant="contained"
              onClick={() => proficiencyWords("Beginner")}
              sx={{ textTransform: "none", mb: 2, backgroundColor: "#72DABD" }}
            >
              Beginner
            </Button>

            <Button
              variant="contained"
              onClick={() => proficiencyWords("Novice")}
              sx={{ textTransform: "none", mb: 2, backgroundColor: "#26C6DA" }}
            >
              Novice
            </Button>

            <Button
              variant="contained"
              onClick={() => proficiencyWords("Intermediate")}
              sx={{ textTransform: "none", mb: 2, backgroundColor: "#03A9F4" }}
            >
              Intermediate
            </Button>

            <Button
              variant="contained"
              onClick={() => proficiencyWords("Advanced")}
              sx={{ textTransform: "none", mb: 2, backgroundColor: "#1E88E5" }}
            >
              Advanced
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setDisplay("main")}>
            Back
          </Button>
        </DialogActions>
      </Dialog>

      {/* Proficiency Words Screen */}
      <Dialog open={!onboarded && display == "proficiencyWords"}>
        <DialogTitle sx={{ textAlign: "center", mb: 2 }}>
          {proficiency}
        </DialogTitle>

        <DialogContent>
          <Stack>
            <Typography variant="body1" sx={{ textAlign: "center", mb: 1 }}>
              Set every word which you know:
            </Typography>

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {onboardingWords.length > 0 &&
                onboardingWords.map((word, index) => (
                  <Button
                    key={index}
                    variant={word.enabled ? "contained" : "outlined"}
                    style={{
                      textTransform: "none",
                      margin: "5px",
                    }}
                    onClick={() => toggleWordEnabled(word.id)}
                  >
                    {word.word}
                  </Button>
                ))}
            </div>
          </Stack>

          <Button
            variant="contained"
            fullWidth
            onClick={() => submitProficiency()}
            sx={{
              backgroundColor: "#1E88E5",
              textTransform: "none",
              mt: 4,
              mb: -2,
            }}
          >
            Complete
          </Button>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setDisplay("proficiency")}>
            Back
          </Button>
        </DialogActions>
      </Dialog>

      {/* Complete Screen */}
      <Dialog
        open={!onboarded && display === "complete"}
        PaperProps={{
          sx: { backgroundColor: "transparent", boxShadow: "none" },
        }}
      >
        <DialogContent sx={{ padding: 0, overflow: "hidden" }}>
          <img
            src={VocabuaWhite}
            style={{
              width: "100%",
              height: "auto",
              objectFit: "cover",
              display: "block",
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
