import React, { useState, useEffect, useRef } from "react";

import { Button, Grid, Paper, Container, Box, Typography, Icon, Stack } from "@mui/material";
import { Link } from "react-router-dom";

import { HiOutlineAcademicCap } from "react-icons/hi2";
import { IoBookOutline } from "react-icons/io5";
import { MdAutoGraph } from "react-icons/md";
import { BiWorld } from "react-icons/bi";

import Onboarding from "./Onboarding";
import NewWords from "./NewWords";
import DueWords from "./DueWords";

import { useTranslation } from "../../display_languages/UseTranslation";

import HomeToolbar from "./HomeToolbar";

import { environment } from "../../.config";
import TestEnvironment from "../../components/c_Testing/TestEnvironment";
import TestAmpFunction from "../../components/c_Testing/TestAmpFunc";

const HomeButton = ({ icon, title, subtitle, colour, path }) => {
  return (
    <Link to={path} style={{ textDecoration: "none", color: "black" }}>
      <Button variant="contained" sx={{
        width: '100%',
        height: '100%',
        backgroundColor: colour,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        padding: 2,
        overflow: 'hidden'
      }}>
        <Box sx={{ mr: 2 }}>
          {icon}
        </Box>
        <Stack sx={{ alignItems: 'flex-start', justifyContent: 'center', width: '100%', mt: -1 }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', textTransform: 'none' }}>
            {title}
          </Typography>
          <Typography variant="body2" sx={{ textTransform: 'none' }}>
            {subtitle}
          </Typography>
        </Stack>
      </Button>
    </Link>
  );
};

export default function HomePanel({ homepageData, username, onboarded, setOnboarded }) {
  const t = useTranslation();

  const [loading, setLoading] = useState(false);

  const [containerHeight, setContainerHeight] = useState(0);
  const containerRef = useRef(null);
  useEffect(() => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.clientHeight);
    }
  }, []);

  return (
    <Container>
      <HomeToolbar username={username} />

      {onboarded == false && <Onboarding onboarded={onboarded} setOnboarded={setOnboarded} setLoading={setLoading} />}

      <Grid container spacing={0} sx={{ height: '95vh' }}>
      
      {/* <TestEnvironment/> */}
      {/* <TestAmpFunction/> */}
        {/* <Grid item xs={12} sx={{ height: '60px' }}>
          <Typography variant="h2" sx={{ fontSize: '40px' }}>Hello World Test:</Typography>
        </Grid> */}

        {/* navigation buttons*/}
        <Grid item xs={12} sx={{ height: 'calc(40%)'}}>
          <Grid container spacing={1} sx={{ height: '100%' }}>
            <Grid item xs={6}>
              <HomeButton icon={<HiOutlineAcademicCap style={{ fontSize: '10vh' }} />} title={t.learn?.title} subtitle={t.home?.learn_subtitle} colour="#03A9F4" path="/learn" />
            </Grid>
            <Grid item xs={6}>
              <HomeButton icon={<IoBookOutline style={{ fontSize: '10vh' }} />} title={t.study?.title} subtitle={t.home?.study_subtitle} colour="#72DABD" path="/study" />
            </Grid>
            <Grid item xs={6}>
              <HomeButton icon={<BiWorld style={{ fontSize: '10vh' }} />} title={t.explore?.title} subtitle={t.home?.explore_subtitle} colour="#1E88E5" path="/explore" />
            </Grid>
            <Grid item xs={6}>
              <HomeButton icon={<MdAutoGraph style={{ fontSize: '10vh' }} />} title={t.progression?.title} subtitle={t.home?.progression_subtitle} colour="#26C6DA" path="/progression" />
            </Grid>
          </Grid>
        </Grid>


        {/* new words and due words */}
        <Grid item xs={12} sx={{ height: 'calc(60%)' }}>
          <Grid container spacing={1} sx={{ height: '100%' }}>
            <Grid item xs={6}>
              <Paper ref={containerRef} elevation={2} sx={{ height: `100%` }}>
                <DueWords homepageData={homepageData} containerHeight={containerHeight} loading={loading} />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={2} sx={{ height: `100%` }}>
                <NewWords homepageData={homepageData} containerHeight={containerHeight} loading={loading} />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
