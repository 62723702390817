import React, { useEffect, useState } from "react";
import Dashboard from "../../components/c_Dashboard/Dashboard";
import { Container } from "@mui/material";
import HomePanel from "./HomePanel";
import SplashPanel from "./SplashPanel";
import SignInRegister from "../../components/c_UserAuthentication/SignIn_Register";
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import Navbar from "../../frontend_website/components/Navbar";
import { Auth } from 'aws-amplify';

const apiKey = process.env.REACT_APP_APIGATEWAY_KEY;
const gateway = process.env.REACT_APP_APIGATEWAY_DEV;

// Function to fetch homepage data
const fetchHomepageData = async ({ user, jwtToken }) => {
  //await helloWorld();
  //await getJwtToken();

  try {
    const endpoint = `${gateway}dynamo-get-homepage-data`;
    const input = { userId: user.username };

    const response = await fetch(endpoint, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "x-api-key": apiKey,
        "Authorization": jwtToken
      },
      body: JSON.stringify(input),
      //credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch homepage data');
    }

    const data = await response.json();
    return JSON.parse(data.body);

  } catch (error) {
    console.error("Error in fetchHomepageData:", error);
    return {};
  }
};

// Function to perform daily update
const dailyUpdate = async ({ user, jwtToken }) => {
  try {
    const endpoint = `${gateway}scorelex-daily-update`;
    const input = { userId: user.username };

    const response = await fetch(endpoint, {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "x-api-key": apiKey,
        "Authorization": jwtToken
      },
      body: JSON.stringify(input),
    });
    const data = await response.json();
    //console.log(data);
    return JSON.parse(data.body);

  } catch (error) {
    console.error("Error in dailyUpdate:", error);
    return {};
  }
};

const Home = () => {
  const { user, username, onboarded, setOnboarded, jwtToken } = useAuth();

  const [homepageData, setHomepageData] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      if (user) {
        await dailyUpdate({ user, jwtToken });
        const data = await fetchHomepageData({ user, jwtToken });
        setHomepageData(data);
      }
    };
    loadData();
  }, [user, onboarded]);

  return (
    <Container sx={{ height: '90vh' }}>
      {user ? (
        <HomePanel homepageData={homepageData} username={username} onboarded={onboarded} setOnboarded={setOnboarded} />
      ) : (
        <>
          <Navbar />
          <br />
          <br />
          <br />
          <br />
          <SignInRegister />
        </>
      )}
    </Container>
  );
};

export default Home;



//
// // Function to get JWT token and set the cookie
// const getJwtToken = async () => {
//   try {
//     const session = await Auth.currentSession();
//     const token = session.getIdToken().getJwtToken();

//     //console.log("JWT Token:", token);

//     // Send the token to the API Gateway endpoint to set the cookie
//     const response = await fetch('https://iih7s4w0g9.execute-api.eu-west-2.amazonaws.com/DEV/auth-create-jwt-cookie', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'x-api-key': apiKey,
//       },
//       body: JSON.stringify({ token }),
//       credentials: 'include'
//     });

//     //console.log("Response from endpoint:", response);

//     if (!response.ok) {
//       throw new Error('Failed to set JWT cookie');
//     }

//     // Log the response headers
//     const responseHeaders = {};
//     response.headers.forEach((value, key) => {
//       responseHeaders[key] = value;
//     });
//     //console.log('Response Headers:', responseHeaders);

//     return token;
//   } catch (error) {
//     console.error("Error in getJwtToken:", error);
//     throw error;
//   }
// };